/**
 * Created by danie on 5/17/2016.
 */

RCAdminBooksController.$inject = ["$stateParams"];

export default function RCAdminBooksController($stateParams) {
  var vm = this;

  vm.searchText = $stateParams.q;
}
