RCMappedOrderEditorModal.$inject = ["$mdDialog", "ResellerService", "NotificationService"];
const angular = require("angular");
angular
  .module("readCloudWebConsole")
  .controller("RCMappedOrderEditorModalController", RCMappedOrderEditorModal);

/* @ngInject */
function RCMappedOrderEditorModal($mdDialog, ResellerService, NotificationService) {
  var vm = this;

  vm.fields = [
    {
      label: "Name",
      id: "name",
      editable: true
    }
  ];

  vm.submit = function(data) {
    if (vm.mappedOrder) {
      var n = NotificationService.toastIt("Update", "Mapped Order");
      delete data.id;
      data.orders = vm.mappedOrder.orders;
      return ResellerService.updateMappedOrder(vm.mappedOrder.resellerId, vm.mappedOrder.id, data)
        .then(n, n)
        .finally(vm.close);
    }
  };

  vm.close = $mdDialog.hide;
}
