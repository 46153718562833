/**
 * Created by danie on 4/5/2016.
 */

AddBookDialog.$inject = ["$mdDialog"];
const angular = require("angular");
angular.module("readCloudWebConsole").controller("addBookDialogController", AddBookDialog);

/* @ngInject */

function AddBookDialog($mdDialog) {
  var vm = this;

  vm.close = $mdDialog.cancel();
}
