import React from "react";
import { Flex } from "../index";
import { defaultProps } from "recompose";
import { Span } from "glamorous";

const ThumbnailText = defaultProps({
  fontSize: 12,
  color: "#444",
  textAlign: "center"
})(Span);

export const NoThumbnailPreview = ({
  subtext,
  children = "No Thumbnail Preview Available",
  ...other
}) => (
  <Flex
    column
    alignItems="center"
    padding="32px 8px"
    backgroundColor="#ddd"
    {...other}
  >
    <ThumbnailText>{children}</ThumbnailText>
    <Flex flex />
    <ThumbnailText fontStyle="bold">{subtext}</ThumbnailText>
  </Flex>
);
