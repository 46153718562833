"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.createStudentNewApi = function (user) {
  return index_1.getNewapi().post("/api/DBUsers/registerStudent", user);
};

exports.createTeacherNewApi = function (user) {
  return index_1.getNewapi().post("/api/DBUsers/registerTeacher", user);
};

exports.findUsers = function (query) {
  return index_1.getNewapi().get("/api/DBUsers", {
    params: {
      filter: query
    }
  });
};

exports.countUsers = function (where) {
  return index_1.getNewapi().get("/api/DBUsers/count", {
    params: {
      where: where
    }
  });
};

exports.me = function () {
  return index_1.getNewapi().get("/api/DBUsers/me");
};

exports.disableUser = function (userId) {
  return index_1.getNewapi().post("/api/DBUsers/" + userId + "/disable");
};

exports.enableUser = function (userId) {
  return index_1.getNewapi().post("/api/DBUsers/" + userId + "/enable");
};

exports.bookStatistics = function (bookId, userId, params) {
  return index_1.getNewapi().get("/api/DBUsers/" + userId + "/bookStatistics/" + bookId, {
    params: params
  });
};

exports.getAnnotations = function (bookId, cloudId, limit, privateOnly, personalOnly) {
  return index_1.getNewapi().get("/api/DBUsers/annotations", {
    params: {
      limit: limit,
      cloudId: cloudId,
      bookId: bookId,
      privateOnly: privateOnly,
      personalOnly: personalOnly
    }
  });
};