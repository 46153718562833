import React from "react";
import { Flex } from "new/shared";
import { ListItem, List, FontIcon } from "material-ui";
import { compose, withProps } from "recompose";
import { ExpandIcon } from "new/shared";
import glamorous, { Span } from "glamorous";
import { defaultStyle } from "new/shared/hocs";
import { toggle, selectOpenNodes } from "./reducer";
import { connect } from "react-redux";
import { omitProps } from "new/shared/hocs";
const styles = {
  expandableIcon: {
    height: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
  }
};

const ListNodeItem = compose(
  defaultStyle({ color: "white", fontSize: 14, height: 45 }),
  withProps({
    innerDivStyle: {
      paddingTop: 15
    }
  })
)(
  glamorous(ListItem)(({ theme }) => ({
    transition: "opacity 300ms",
    ...theme.listItem
  }))
);

const ListNodeIcon = defaultStyle({ color: "white" })(FontIcon);

export const ListNodeComponent = ({
  label,
  boldLabel = false,
  icon,
  stateOrName,
  children,
  toggle,
  onClick,
  isOpen,
  nodeId,
  ...props
}) => (
  <Flex column {...props}>
    <ListNodeItem
      leftIcon={
        icon && <ListNodeIcon className="material-icons">{icon}</ListNodeIcon>
      }
      onClick={() => {
        nodeId && toggle && toggle();
        onClick && onClick();
      }}
    >
      <Flex centerBoth>
        <Span {...(boldLabel ? { fontWeight: 600 } : {})}>{label}</Span>
        <Flex flex />
        {children ? (
          <ExpandIcon style={styles.expandableIcon} expanded={isOpen} />
        ) : null}
      </Flex>
    </ListNodeItem>
    {children && isOpen ? children : null}
  </Flex>
);

export const ListNode = compose(
  connect(
    (state, { nodeId }) => ({
      isOpen: !!nodeId && selectOpenNodes(state)[nodeId]
    }),
    (dispatch, { nodeId }) => ({ toggle: () => dispatch(toggle(nodeId)) })
  )
)(ListNodeComponent);

export const ListTreeComponent = ({ children, noPadding, ...props }) => (
  <List style={{ ...(!noPadding ? { paddingLeft: 8 } : {}) }} {...props}>
    {children}
  </List>
);

export const ListTree = glamorous(omitProps(["isChild"])(ListTreeComponent))(
  {
    color: "white"
  },
  ({ isChild }) => ({
    ...(isChild ? { backgroundColor: "rgba(255, 255, 255, 0.1)" } : {})
  })
);
