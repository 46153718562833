import { compose, withPropsOnChange, defaultProps } from "recompose";
import glamorous, { Div, Span } from "glamorous";
import React from "react";
import { ListNode } from "new/Sidebar/ListTree";
import { Flex } from "new/shared";
import { connect } from "react-redux";
import { select, toggleLock } from "./reducer";
import LockOutlineIcon from "material-ui/svg-icons/action/lock-outline";
import LockOpenIcon from "material-ui/svg-icons/action/lock-open";
import IconButton from "material-ui/IconButton";

const HorizontalLine = props => (
  <Flex column centerBoth padding="0 8px" {...props}>
    <Div backgroundColor="white" height={2} width={12} />
  </Flex>
);

export const collapsedWidth = 57;
export const openDrawerWidth = 190;
export const widthTransition = { transition: "width 200ms" };
export const styles = {
  sidebarDrawer: {
    backgroundColor: "#373C47",
    overflow: "hidden",
    zIndex: 21,
    ...widthTransition
  },
  lockButton: {
    color: "white"
  }
};
export const defaultTheme = {
  listItem: {}
};

export const collapsedTheme = {
  listItem: {
    "& > div > div > div": {
      opacity: 0.2
    }
  }
};

export const RecentListNode = compose(
  withPropsOnChange(["label"], ({ label }) => ({
    label: (
      <Flex width="100%" alignItems="center">
        <HorizontalLine />
        <Span
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          width={110}
        >
          {label}
        </Span>
      </Flex>
    )
  }))
)(ListNode);
export const SidebarContainer = defaultProps({ column: true })(
  glamorous(Flex)({
    width: openDrawerWidth,
    minHeight: 300
  })
);

export const LockButton = compose(
  connect(
    state => ({
      locked: select(state).locked
    }),
    { onClick: toggleLock }
  )

  /*  withPropsOnChange(["locked"], ({ locked }) => ({
    iconStyle: styles.lockButton,
    tooltip: !locked ? "Lock sidebar open" : "Unlock sidebar",
    tooltipPosition: "top-right"
  })), */
  /*   withPropsOnChange(["locked"], ({ locked }) => ({
    children: locked ? <LockOutlineIcon /> : <LockOpenIcon />
  })), */
)(({ locked, onClick }) => (
  <IconButton
    iconStyle={styles.lockButton}
    tooltip={!locked ? "Lock sidebar open" : "Unlock sidebar"}
    tooltipPosition="top-right"
    onClick={onClick}
  >
    {locked ? <LockOutlineIcon /> : <LockOpenIcon />}
  </IconButton>
));
