"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.searchTransactionAdvanced = function (req, config) {
  return index_1.getApiv14().post("/search/advanced/transaction", req, config);
};