"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var client_1 = require("../client");

var jwtUtils_1 = require("@readcloud/common/build/lib/jwtUtils");

var Authentication_1 = require("@readcloud/common/build/types/models/api/Authentication");

var client_2 = require("../client");

var utils_1 = require("../utils");

var index_1 = require("./index");

exports.getToken = function (body) {
  return index_1.getApiv14().post("/token", body);
};

var handleAuthResponse = function handleAuthResponse(response) {
  //console.log(response);
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  var _a = response.data.result,
      accessToken = _a.accessToken,
      refreshToken = _a.refreshToken;
  client_1.setRefreshToken(refreshToken);
  client_1.setAccessToken(accessToken);
  var decoded = jwtUtils_1.decodeJwt(accessToken).payload;

  if (!Authentication_1.jwtPayload.is(decoded)) {
    throw new Error("JWT payload is invalid");
  }

  return {
    accessToken: accessToken,
    refreshToken: refreshToken,
    decoded: decoded
  };
};

exports.authenticate = function (email, password) {
  var clientId = client_2.getClientId();

  if (clientId) {
    return exports.getToken({
      clientId: clientId,
      grantType: "Password",
      email: email,
      password: password
    }).then(handleAuthResponse);
  } else {
    throw new Error("Client id is not defined. You must set this before authenticating");
  }
};

exports.reAuthenticate = function (refreshToken) {
  var clientId = client_2.getClientId();

  if (clientId) {
    return exports.getToken({
      clientId: clientId,
      grantType: "RefreshToken",
      refreshToken: refreshToken
    }).then(handleAuthResponse);
  } else {
    throw new Error("Client id is not defined. You must set this before authenticating");
  }
};

exports.assertAuthentication = function () {
  return tslib_1.__awaiter(void 0, void 0, void 0, function () {
    var accessToken, refreshToken;
    return tslib_1.__generator(this, function (_a) {
      accessToken = client_1.getAccessToken();
      refreshToken = client_1.getRefreshToken();

      if (!accessToken) {
        throw new Error("Unauthorized - No access token found");
      }

      if (utils_1.isJwtValid(accessToken)) {
        return [2
        /*return*/
        ];
      }

      if (!refreshToken) {
        throw new Error("Unauthorized - Invalid or expired access token, and no refresh token found in order to attempt reauth");
      }

      return [2
      /*return*/
      , exports.reAuthenticate(refreshToken)];
    });
  });
};

exports.isAuthenticated = function () {
  return !!client_1.getAccessToken();
};