"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _1 = require(".");

var common_1 = require("@readcloud/common");

exports.tts = function (params) {
  return new Promise(function (resolve, reject) {
    if (common_1.isIOS()) {
      /*
        IOS has a bug where it doesn't pass the bearer through a redirect (currently uses api.readcloud.com by default, gets redirected to apiv2 when required).
        To fix this we just pass in a base URL to skip the redirect and go straigh tto apiv2.
      */
      return _1.getNewapi().get("/api/v3/speech/tts", {
        params: params,
        responseType: "blob",
        baseURL: "https://apiv2.readcloud.com"
      }).then(function (res) {
        resolve(URL.createObjectURL(res.data));
      }).catch(function (e) {
        resolve(e);
      });
    } else {
      // Keep functionality standard for everything else.
      return _1.getNewapi().get("/api/v3/speech/tts", {
        params: params,
        responseType: "blob",
        baseURL: ""
      }).then(function (res) {
        resolve(URL.createObjectURL(res.data));
      }).catch(function (e) {
        resolve(e);
      });
    }
  });
};