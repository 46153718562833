"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clientSmallBrandToSchemeMap = {
  om: "omxebooks",
  rc: "readcloud"
};
exports.walkthroughBrandMessages = {
  om: "Welcome to the OfficeMax eBooks reader",
  rc: "Welcome to the ReadCloud reader"
};

exports.isAnnotationShared = function (annotation) {
  return !!(annotation.visibleInClouds.length || annotation.visibleToUsers.length);
};