/**
 * Created by danie on 8/03/2016.
 */

HttpService.$inject = ["$http", "ERROR_CODES", "AUTH_EVENTS", "$rootScope", "$log"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("HttpService", HttpService);

/* @ngInject */
function HttpService($http, ERROR_CODES, AUTH_EVENTS, $rootScope, $log) {
  var HttpService = {};

  var functionsToOverride = ["get", "post", "put", "delete"];

  //TODO: We need to fix this code to properly show and hide the loading spinners on each intercept
  functionsToOverride.forEach(function(i) {
    HttpService[i] = function() {
      var out = $http[i].apply(this, arguments);

      out.catch(function(reason) {
        var code;
        try {
          code = reason.data.error.code;
        } catch (e) {
          $log.info(e);
        }
        switch (code) {
          case ERROR_CODES.AuthorizationRequired:
            $rootScope.$emit(AUTH_EVENTS.unauthorized);
            break;
          default:
            break;
        }
      });
      //UtilitiesService.promiseLoader(out);

      return out;
    };
  });

  return HttpService;
}
