import { registerNg } from "utils";
import { BulkPurchaseButton } from "new/CloudAdmin/BulkPurchaseButton";

import { asyncComponent } from "new/shared/AsyncComponent";
import { Sidebar, SidebarDrawer } from "new/Sidebar";
import { MainSnackbar } from "new/Snackbar/MainSnackbar";

import { AnnotationsExportPage } from "new/Reports/AnnotationExport";
registerNg(BulkPurchaseButton, "bulkPurchaseButton");
registerNg(
  asyncComponent(
    () => import("new/InstitutionAdmin/AdminPage"),
    "InstitutionAdminPage"
  ),
  "institutionAdminPage"
);
registerNg(
  asyncComponent(
    () => import("new/CloudAdmin/CloudDetailsStats"),
    "CloudDetailsStats"
  ),
  "cloudDetailsStats"
);
registerNg(
  asyncComponent(() => import("new/CloudAdmin/Stats"), "CloudStats"),
  "cloudStats"
);
registerNg(
  asyncComponent(() => import("new/CloudAdmin/Stats"), "CloudStatsHeading"),
  "cloudStatsHeading"
);
registerNg(MainSnackbar, "mainSnackbar");
registerNg(
  asyncComponent(() => import("new/HomePage"), "HomePage"),
  "homePage"
);
registerNg(SidebarDrawer, "reactSidebarDrawer");
registerNg(Sidebar, "reactSidebar");
registerNg(
  asyncComponent(() => import("new/MainContainer"), "MainContainer"),
  "mainContainer"
);
registerNg(AnnotationsExportPage, "annotationsExportPage");

registerNg(
  asyncComponent(() => import("new/Books/Search/page"), "BookSearchPage"),
  "bookSearchPage"
);

registerNg(
  asyncComponent(() => import("new/Transactions/Search/page"), "TransactionSearchPage"),
  "transactionSearchPage"
);

registerNg(
  asyncComponent(() => import("new/Logs/page"), "LogsPage"),
  "logsPage"
);
