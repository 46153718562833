"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var bridge_1 = require("../types/models/bridge");

exports.getReaderExternalUrl = function (brand, bookId, cloudId, location) {
  var scheme = bridge_1.clientSmallBrandToSchemeMap[brand];

  if (!scheme) {
    throw new Error("Scheme not found for brand " + brand);
  }

  return "https://apiv2.readcloud.com/linker?bid=" + bookId + "&cid=" + cloudId + "&p=" + location + "&scheme=" + scheme;
};