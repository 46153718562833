/**
 * Created by danie on 5/03/2016.
 */

NotificationService.$inject = ["$mdDialog", "$mdToast", "$log", "$q"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("NotificationService", NotificationService);

/* @ngInject */
function NotificationService($mdDialog, $mdToast, $log, $q) {
  var NotificationService = {};

  function defaultToast(message) {
    $mdToast.showSimple(message);
  }

  function basicDialog(message) {
    return $mdDialog.show(
      $mdDialog.alert({
        textContent: message,
        ok: "Ok",
        escapeToClose: true
      })
    );
  }

  NotificationService.toastError = function(message) {
    return $mdToast.showSimple(message);
  };

  NotificationService.toastIt = function(action, modelName) {
    function defaultFailed() {
      defaultToast(
        "Failed to " + action.toString().toLowerCase() + " " + modelName.toString.toLowerCase()
      );
    }

    function defaultSuccess() {
      defaultToast(action + "d " + modelName + " successfully");
    }

    return function(messageOrObject) {
      if (messageOrObject) {
        if (messageOrObject.data) {
          if (messageOrObject.data.error) {
            if (messageOrObject.data.error.message) {
              defaultToast(messageOrObject.data.error.message);
              return;
            }
          }
        }
        if (messageOrObject.status) {
          if (messageOrObject.status >= 200 && messageOrObject.status < 300) {
            defaultSuccess();
          } else if (messageOrObject.status >= 400) {
            defaultFailed();
          }
        }
      }
    };
  };

  NotificationService.error = function(message) {
    $log.error(message);
  };

  NotificationService.info = function(message) {
    $log.info(message);
  };

  NotificationService.success = function(message) {
    return basicDialog(message);
  };

  NotificationService.failed = function(message) {
    return basicDialog(message);
  };

  NotificationService.areYouSure = function(title, message) {
    if (!message) return $q.reject();
    return $mdDialog.show(
      $mdDialog
        .confirm()
        .title(title || "Confirm Dialog")
        .textContent(message)
        .ariaLabel(title)
        .ok("Confirm")
        .cancel("Cancel")
    );
  };

  NotificationService.showComponentAsDialog = function(componentName, bindings) {
    return $mdDialog.show({
      clickOutsideToClose: true,
      bindToController: true,
      locals: bindings,
      template:
        '<md-dialog flex-gt-md="70" flex="100"> <' +
        componentName +
        "></" +
        componentName +
        "> </md-dialog>"
    });
  };

  return NotificationService;
}
