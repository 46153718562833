/**
 * Created by Daniel on 6/22/2016.
 */
RCResellerAppNotificationSettings.$inject = [
  "_",
  "ResellerService",
  "$scope",
  "UtilitiesService",
  "$element",
  "NotificationService",
  "DialogService",
  "ApplicationService"
];
const angular = require("angular");
angular
  .module("readCloudWebConsole")
  .component("rcResellerAppNotificationSettings", {
    templateUrl: () =>
      import("app/components/rc-reseller-app-notification-settings/template.html").then(m => m.default),
    controller: RCResellerAppNotificationSettings,
    controllerAs: "vm",
    bindings: {
      reseller: "<",
      applicationName: "<"
    }
  });

/* @ngInject */
function RCResellerAppNotificationSettings(
  _,
  ResellerService,
  $scope,
  UtilitiesService,
  $element,
  NotificationService,
  DialogService,
  ApplicationService
) {
  var vm = this;
  vm.data = {};
  vm.appConfig = {};

  vm.getClientApplication = function(id) {
    return ApplicationService.getById(id);
  };

  vm.selectedAppBuildChanged = function() {
    if (vm.appConfig.appVersion) {
      /*if(typeof (vm.selectedAppBuild) == 'string') {
          var build = angular.fromJson(vm.selectedAppBuild);
          vm.appConfig.appVersion = build.id;
          vm.appConfig.downloadLink = build.downloadURL;
        }*/
      var build = _.find(vm.buildOptions, { id: vm.appConfig.appVersion });

      vm.appConfig.downloadLink = build.downloadURL;
    }
  };

  $scope.$watch("vm.selectedUsers", function() {
    if (vm.selectedUsers && vm.selectedUsers instanceof Array) {
      vm.appConfig.testingLogins = vm.selectedUsers.map(function(u) {
        return u.email;
      });
    }
  });

  function hideElement() {
    $element.css("display", "none");
  }

  function showElement() {
    $element.css("display", "block");
  }

  vm.get = function() {
    return UtilitiesService.inlineLoader(
      ResellerService.getAppConfig(vm.reseller, vm.clientApplication.name).then(
        function(result) {
          if (result.data) {
            vm.appConfig = result.data;
            showElement();
          }
        },
        function() {
          NotificationService.failed(
            "Failed to get reseller application config"
          );

          hideElement();
        }
      ),
      $element
    );
  };

  vm.getBuildOptions = function() {
    UtilitiesService.inlineLoader(
      ApplicationService.getBuilds(vm.applicationName),
      $element
    ).then(
      function(result) {
        if (result) vm.buildOptions = result.data;
      },
      function() {
        NotificationService.failed(
          "No builds were found for " + vm.applicationName
        );
      }
    );
  };

  vm.save = function(data) {
    delete data._scopeMeta;

    return UtilitiesService.inlineLoader(
      ResellerService.updateAppConfig(
        vm.reseller,
        vm.clientApplication.name,
        data
      ).then(function() {
        NotificationService.success.bind(null, "Saved successfully")();
        vm.get().then(function() {
          $scope.appConfig.$setPristine();
        });
      }, NotificationService.failed.bind(null, "Failed saving. Check console")),
      $element
    );
  };

  vm.$scope = $scope;

  vm.updateComponent = function() {
    if (vm.reseller && vm.applicationName) {
      UtilitiesService.inlineLoader(
        vm.getClientApplication(vm.applicationName).then(function(result) {
          vm.clientApplication = result.data;
          vm.get();
          vm.getBuildOptions();
        }),
        $element
      );
    }
  };

  $scope.$watch("vm.reseller", vm.updateComponent);
  $scope.$watch("vm.applicationName", vm.updateComponent);

  vm.$onInit = function() {};
}
