ctrl.$inject = ["BookService", "UtilitiesService", "$element", "$scope"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcBookSearch", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-book-search/template.html").then(m => m.default),
  bindings: {
    selected: "="
  }
});

/*@ngInject*/
function ctrl(BookService, UtilitiesService, $element, $scope) {
  var vm = this;
  vm.$onInit = () => {
    vm.selected = [];

    vm.hasMore = false;

    vm.fields = [
      {
        label: "ISBN",
        id: "isbn"
      },
      {
        label: "Title",
        id: "title"
      }
    ];
  };

  vm.updateLoadMoreAvailability = function() {
    vm.loadMoreEnabled = vm.hasMore;
  };

  vm.get = function(query) {
    return BookService.search(query).then(function(result) {
      if (result && result.data && result.data.data) {
        vm.hasMore = result.data.more;
        return result.data.data;
      }
    });
  };

  vm.search = function(query) {
    vm.query = query;
    vm.query.limit = 50;
    vm.query.skip = 0;
    vm.query.fields = ["isbn", "title", "id"];
    UtilitiesService.inlineLoader(
      vm.get(query).then(function(data) {
        vm.data = data;
        vm.updateLoadMoreAvailability();
      }),
      $element
    );
  };

  vm.loadMore = function() {
    vm.query.skip += 50;
    UtilitiesService.inlineLoader(
      vm.get(vm.query).then(function(data) {
        vm.data = vm.data.concat(data);
        vm.updateLoadMoreAvailability();
      }),
      $element
    );
  };
}
