"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var client_1 = require("./client");

var shouldIntercept = function shouldIntercept(url) {
  return !/\/token$/.test(url);
};

exports.makeAuthAssertionInterceptor = function (reAuthenticate) {
  /** Validation Functions */
  var currentState = {
    oldBaseURL: "",
    failedToken: false,
    stopRetry: false,
    refreshPromise: undefined,
    failQueue: [],
    newAccessToken: null
  };
  return function (client) {
    client.interceptors.request.use(function (config) {
      return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!!shouldIntercept(config.url || "")) return [3
              /*break*/
              , 1];
              return [2
              /*return*/
              , config];

            case 1:
              if (!(currentState.refreshPromise !== undefined)) return [3
              /*break*/
              , 3];
              return [4
              /*yield*/
              , currentState.refreshPromise];

            case 2:
              _a.sent();

              return [2
              /*return*/
              , config];

            case 3:
              return [2
              /*return*/
              , config];
          }
        });
      });
    });
    client.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var ignoreRefresh, testWait, e_1;
        return tslib_1.__generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              ignoreRefresh = false;
              currentState.oldBaseURL = currentState.oldBaseURL == "" ? error.config.baseURL : currentState.oldBaseURL;

              if (error.response && error.response.status == 400 && shouldIntercept(error.config.url) || error.response && error.response.status == 401 && error.response.statusText == "Unauthorized" && shouldIntercept(error.config.url)) {
                //console.log("AuthInt", error.response.data.result.error);
                switch (error.response.data.result.error) {
                  case "Token is absent":
                    ignoreRefresh = true;
                    break;

                  case "Invalid token":
                    //ignoreRefresh = true;
                    console.log("Invalid Token");
                    break;

                  case "Invalid request":
                    ignoreRefresh = true;
                    break;

                  case "Operation is not permitted":
                    ignoreRefresh = true;
                    break;

                  case "Invalid username or password":
                    ignoreRefresh = true;
                    break;

                  case "Your refresh token is either invalid or expired":
                    ignoreRefresh = true;
                    break;
                }

                if (error.response.data == "Invalid token") {
                  ignoreRefresh = true;
                }
              }

              if (error.response.data.error.message && error.response.data.error.message == "Your refresh token is either invalid or expired") {
                ignoreRefresh = true;
              }

              if (!(!ignoreRefresh || ignoreRefresh === undefined)) return [3
              /*break*/
              , 4];
              if (!(client_1.getRefreshToken() !== undefined && currentState.stopRetry === false)) return [3
              /*break*/
              , 4];
              _a.label = 1;

            case 1:
              _a.trys.push([1, 3,, 4]);

              testWait = function testWait() {
                return tslib_1.__awaiter(void 0, void 0, void 0, function () {
                  return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                      case 0:
                        return [4
                        /*yield*/
                        , reAuthenticate(client_1.getRefreshToken()).then(function (res) {
                          currentState.newAccessToken = res.accessToken;
                          currentState.stopRetry = true; //console.log("RetrievedToken", res.accessToken);

                          return res.accessToken;
                        }).catch(function (err) {
                          console.log(err);
                          ignoreRefresh = true;
                          currentState.stopRetry = true;
                          setTimeout(function () {
                            currentState.stopRetry = false;
                          }, 10000);
                        })];

                      case 1:
                        return [2
                        /*return*/
                        , _a.sent()];
                    }
                  });
                });
              };

              return [4
              /*yield*/
              , testWait()];

            case 2:
              _a.sent(); //currentState.refreshPromise = undefined;


              error.config.headers.Authorization = "Bearer " + currentState.newAccessToken;
              error.config.url.replace(error.config.url, error.config.baseURL);
              currentState.stopRetry === false;
              return [2
              /*return*/
              , client(error.config)];

            case 3:
              e_1 = _a.sent();

              if (!e_1.response) {
                console.log(e_1);
                return [2
                /*return*/
                , e_1];
              } else {
                currentState.failedToken = true;
                client_1.refreshTokenFailure$.next();
              }

              return [3
              /*break*/
              , 4];

            case 4:
              // Reject promise if usual error
              return [2
              /*return*/
              , Promise.reject(error)];
          }
        });
      });
    });
    return client;
  };
};