/**
 * Created by danie on 4/22/2016.
 */
import angular from "angular";
angular.module("readCloudWebConsole").component("rcUserDetails", {
  controller: RCUserDetails,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-user-details/rc-user-details.html").then(m => m.default),
  bindings: {
    email: "<"
  }
});

RCUserDetails.$inject = [
  "$mdDialog",
  "$scope",
  "UserService",
  "UtilitiesService",
  "$element",
  "RouteService",
  "NotificationService",
  "DialogService",
  "HistoryService"
];
function RCUserDetails(
  $mdDialog,
  $scope,
  UserService,
  UtilitiesService,
  $element,
  RouteService,
  NotificationService,
  DialogService,
  HistoryService
) {
  var vm = this;

  vm.openMenu = function($mdOpenMenu, $event) {
    $mdOpenMenu($event);
  };

  vm.hide = function hide() {
    $mdDialog.hide();
  };

  function updateFields() {
    vm.detailFields = vm.getDetailFields();
  }

  vm.getUserDetails = function(email) {
    return UtilitiesService.inlineLoader(
      UserService.getByEmail(email).then(
        function(result) {
          var user = result.data[0];

          if (!angular.isArray(user)) user = [user];
          user = user.map(function(i) {
            i.created = i.created
              ? new Date(i.created).toLocaleString()
              : undefined;
            i.updated = i.updated
              ? new Date(i.updated).toLocaleString()
              : undefined;
            i.loginDateTime = i.loginDateTime
              ? new Date(i.loginDateTime).toLocaleString()
              : undefined;
            i.pollingDateTime = i.pollingDateTime
              ? new Date(i.pollingDateTime).toLocaleString()
              : undefined;
            return i;
          });
          vm.data = user[0];

          updateFields();
          HistoryService.hitUser(vm.data.email, vm.data.email);
        },
        function() {
          NotificationService.failed("User does not exist").then(function() {
            RouteService.back();
          });
        }
      ),
      $element
    );
  };

  $scope.$watch("vm.email", function() {
    if (vm.email) {
      vm.getUserDetails(vm.email);
    }
  });

  vm.changePasswordDialog = function(/*$event*/) {
    import("../../modals/rc-user-change-password.html").then(m => m.default).then(template =>
      DialogService.fromTemplate(template, {
        userId: vm.data.id,
        email: vm.data.email
      })
    );
  };

  vm.getDetailFields = function() {
    return UserService.getDetailFields(vm.data);
  };

  vm.updateUser = function(data) {
    return UtilitiesService.inlineLoader(
      UserService.updateUser(vm.data.id, data).then(function(result) {
        if (result.data) {
          var user = result.data;
          if (!angular.isArray(user)) user = [user];
          user = user.map(function(i) {
            i.created = i.created
              ? new Date(i.created).toLocaleString()
              : undefined;
            i.updated = i.updated
              ? new Date(i.updated).toLocaleString()
              : undefined;
            i.loginDateTime = i.loginDateTime
              ? new Date(i.loginDateTime).toLocaleString()
              : undefined;
            i.pollingDateTime = i.pollingDateTime
              ? new Date(i.pollingDateTime).toLocaleString()
              : undefined;
            return i;
          });
          vm.data = user[0];
        }
      }),
      $element
    );
  };

  vm.modelUpdated = function modelUpdated() {
    vm.detailFields = vm.getDetailFields();
  };

  vm.deletedChanged = function() {
    if (vm.data.deleted === true) deleteUser();
    if (vm.data.deleted === false) reEnableUser();
  };

  function deleteUser() {
    UtilitiesService.inlineLoader(
      UserService.delete(vm.data.id).then(
        function(result) {
          vm.modelUpdated(result.data);
        },
        function() {
          vm.data.deleted = false;
        }
      ),
      $element
    );
  }

  function reEnableUser() {
    UtilitiesService.inlineLoader(
      UserService.enable(vm.data.id).then(
        function(result) {
          vm.modelUpdated(result.data);
        },
        function() {
          vm.data.deleted = true;
        }
      ),
      $element
    );
  }

  vm.userClouds = function() {
    RouteService.admin.userClouds(vm.data.id);
  };
}
