import { compose, branch, renderNothing, setPropTypes } from "recompose";
import { connect } from "react-redux";
import pt from "prop-types";
import { selectSessionData } from "new/redux/reducers/session";
import React from "react";
import { omitProps } from "new/shared/hocs";
export const withRoleWhitelist = compose(
  setPropTypes({
    roles: pt.arrayOf(pt.string)
  }),
  connect(
    state => ({
      role: selectSessionData(state).role
    }),
    null
  ),
  branch(({ roles = [], role }) => roles.indexOf(role) === -1, renderNothing),
  omitProps(["roles", "role", "dispatch"])
);

export const withRoleBlacklist = compose(
  setPropTypes({
    roles: pt.arrayOf(pt.string)
  }),
  connect(
    state => ({
      role: selectSessionData(state).role
    }),
    null
  ),
  branch(({ roles = [], role }) => roles.indexOf(role) === 1, renderNothing),
  omitProps(["roles", "role", "dispatch"])
);

export const OnlyForRoles = compose(withRoleWhitelist)(React.Fragment);
