"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = __importStar(require("io-ts"));

var Common_1 = require("../Common");

var jwtMeta = t.intersection([t.type({
  exp: t.number,
  iat: t.number
}), t.partial({
  aud: t.union([t.string, t.array(t.string)])
})]);
var userScopeParams = t.partial({
  U_Publisher_Author: t.type({
    publishers: t.array(t.string)
  }),
  U_PopulateSchoolClouds: t.unknown
});
var userScopeKey = t.keyof(userScopeParams.props);
exports.jwtPayload = t.intersection([t.type({
  userId: t.string,
  institution: t.string,
  email: t.string,
  role: Common_1.userRole,
  clientId: t.string
}), t.partial({
  userScope: t.string,
  userScopeParams: userScopeParams
}), jwtMeta]);

exports.getUserScopes = function (jwtPayload) {
  return !jwtPayload.userScope ? [] : jwtPayload.userScope.split(" ").filter(userScopeKey.is);
};