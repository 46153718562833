import React from "react";

import { compose, setPropTypes } from "recompose";
import { connect } from "react-redux";
import { AnnotationsExportPageComponent } from "./shared";
import { select, getData, selectSortedAnnotations } from "./reducer";
import { APICallStateContainer } from "new/shared";
import pt from "prop-types";
import { onlyRenderWhen } from "new/shared";
import { Flex } from "new/shared";
import { saveJsonAsCsv } from "new/utils";

import moment from "moment";
import { selectSessionData } from "new/redux/reducers/session";
const styles = {
  container: {
    height: "100%",
    width: "100%"
  },
  page: {
    width: "100%",
    padding: 16
  }
};

export const AnnotationsExportPage = compose(
  setPropTypes({
    bookId: pt.string.isRequired,
    cloudId: pt.string.isRequired
  }),
  onlyRenderWhen(({ bookId, cloudId }) => bookId && cloudId),
  connect(
    compose(state => ({
      ...select(state),
      email: selectSessionData(state).email,
      annotations: selectSortedAnnotations(state)
    })),
    { getData }
  )
)(
  class Component extends React.PureComponent {
    state = {
      printMode: false
    };
    componentDidUpdate(prevProps) {
      if (prevProps.bookId !== this.props.bookId) {
        this.props.refresh();
      }
    }

    componentDidMount() {
      this.refresh();
    }
    refresh = () => {
      this.props.getData({
        bookId: this.props.bookId,
        cloudId: this.props.cloudId
      });
    };
    onPrint = () => window.print();
    setPrintMode = printMode => this.setState({ printMode });
    saveAnnotations = () => {
      if (!this.props.annotations) return;
      const filteredAnnotations = this.props.annotations.filter(anno => {
        if (anno.version == "2") {
          return anno;
        }
      });
      saveJsonAsCsv(
        filteredAnnotations,
        `MyAnnotations ${moment().format("YYYY-MM-DD HHmm")}`,
        {
          fields: [
            { label: "Page", value: "pageID_num" },
            {
              label: "Date",
              value: row => moment(row.updated).format("YYYY-MM-DD HH:mm")
            },
            { label: "Annotated Text", value: "selectedText" },
            { label: "Annotation", value: "text" }
          ]
        }
      );
    };

    render() {
      const {
        loading,
        error,
        annotations,
        data,
        filter,
        ...other
      } = this.props;
      return (
        <APICallStateContainer {...{ loading, error }} style={styles.container}>
          {data && data.cloudDetails && annotations ? (
            <AnnotationsExportPageComponent
              {...other}
              saveAnnotations={this.saveAnnotations}
              onPrint={this.onPrint}
              printMode={this.state.printMode}
              setPrintMode={this.setPrintMode}
              bookTitle={data.title}
              bookIsbn={data.isbn}
              bookThumbnailUrl={data.imageThumbnailURI}
              cloudName={data.cloudDetails.name}
              cloudInstitution={data.cloudDetails.institution}
              annotations={annotations}
              privateOnly={filter.privateOnly}
              personalOnly={filter.personalOnly}
              limit={filter.limit}
              onRefresh={this.refresh}
            />
          ) : !loading && !error ? (
            <Flex centerBoth flex width="100%">
              <h2>No Annotations Found</h2>
            </Flex>
          ) : null}
        </APICallStateContainer>
      );
    }
  }
);
