"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.search = function (searchText, limit, skip, sort) {
  return index_1.getNewapi().get("/api/DRMBooks/search/" + encodeURIComponent(searchText), {
    params: {
      limit: limit,
      skip: skip,
      sort: sort
    }
  });
};

exports.advancedSearch = function (filter, limit, skip, sort) {
  return index_1.getNewapi().get("/api/DRMBooks/search", {
    params: {
      filter: filter,
      limit: limit,
      skip: skip,
      sort: sort
    }
  });
};