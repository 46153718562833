import React from "react";
import {
  compose,
  setDisplayName,
  withPropsOnChange,
  setPropTypes
} from "recompose";
import { Flex } from "new/shared";
import { withServices } from "new/ngReactBridge";
import { onlyRenderWhen } from "new/shared";
import Drawer from "material-ui/Drawer";
import { LogoInverse } from "new/shared/Logos";
import { withHoverState } from "new/shared";
import { Padding } from "new/shared";
import { connect } from "react-redux";
import {
  select as selectHistory,
  backdoorForLegacy
} from "../redux/reducers/history";
import { SidebarComponent } from "./Sidebar";
import { Div } from "glamorous";
import { select } from "./reducer";
import {
  collapsedTheme,
  defaultTheme,
  collapsedWidth,
  openDrawerWidth,
  styles,
  LockButton
} from "./shared";
import { SecretButton } from "new/Config/Secret";
import pt from "prop-types";
import { css } from "glamor";

//This is a hack because history.service doesn't initialize at the right time. We need the store and history.service to bind once when this component mounts.
const updateStoreWithHistory = (HistoryService, dispatch) => {
  dispatch(backdoorForLegacy(HistoryService.getAllRecents()));
};

export const Sidebar = compose(
  setDisplayName("Sidebar"),

  withServices(["DialogService", "RouteService", "HistoryService"]),
  onlyRenderWhen(
    ({ DialogService, RouteService }) => !!DialogService && !!RouteService
  ),
  withPropsOnChange(["collapsed"], ({ collapsed }) => ({
    theme: !collapsed ? defaultTheme : collapsedTheme
  })),
  connect(selectHistory),
  withPropsOnChange([], ({ HistoryService, dispatch }) => {
    updateStoreWithHistory(HistoryService, dispatch);
    return {};
  }),
  Component => ({ HistoryService, dispatch, ...other }) => (
    <Component {...other} />
  )
)(SidebarComponent);

const hideOnPrint = css({
  "@media print": {
    display: "none"
  }
});

export const SidebarDrawerComponent = ({
  hovered,
  locked = false,
  ...props
}) => (
  <Flex
    className={hideOnPrint}
    column
    height="100%"
    width={!locked ? collapsedWidth : openDrawerWidth}
    {...props}
  >
    <Drawer
      open={true}
      docked={true}
      width={!hovered && !locked ? collapsedWidth : openDrawerWidth}
      containerStyle={styles.sidebarDrawer}
      {...props}
    >
      <Flex column height="100%">
        <Flex alignItems="center" height={70} paddingLeft={10} flexShrink={0}>
          <LogoInverse style={{ height: 40, width: 40, flexShrink: 0 }} />
          <Padding />
          <Flex
            column
            color="white"
            overflowX="hidden"
            flexShrink={0}
            flexWrap="wrap"
            centerBoth
            fontSize={14}
            fontWeight="bold"
          >
            <span>ReadCloud</span> <span>Admin Console</span>
          </Flex>
        </Flex>

        <Div overflowY="overlay" overflowX="hidden" flex={1} flexShrink={1}>
          <Sidebar collapsed={!hovered && !locked} />
        </Div>
        <Flex column flexShrink={0} padding={8} alignItems="flex-start">
          <SecretButton />
          <LockButton />
        </Flex>
      </Flex>
    </Drawer>
  </Flex>
);

export const SidebarDrawer = compose(
  setPropTypes({
    reactHide: pt.bool
  }),
  onlyRenderWhen(({ reactHide }) => !reactHide),
  withHoverState,
  connect(state => ({ locked: select(state).locked }), null),
  Component => ({ dispatch, ...other }) => <Component {...other} />
)(SidebarDrawerComponent);
