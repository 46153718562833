ctrl.$inject = [
  "ActionService",
  "DialogService",
  "UtilitiesService",
  "$element"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcActionsManager", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-actions-manager/template.html").then(m => m.default)
});
/* @ngInject */
function ctrl(ActionService, DialogService, UtilitiesService, $element) {
  var vm = this;
  vm.refresh = function() {
    var promise = ActionService.getAll().then(function(data) {
      vm.actions = data;
    });
    UtilitiesService.inlineLoader(promise, $element);
    return promise;
  };
  vm.refresh();

  vm.create = function() {
    DialogService.ActionCreator().then(vm.refresh);
  };
}
