ctrl.$inject = [
  "UserService",
  "UtilitiesService",
  "$element",
  "NotificationService",
  "$scope",
  "moment"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserActivityLogs", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-user-activity-logs/template.html").then(m => m.default),
  bindings: {
    userId: "<"
  }
});

/* @ngInject */
function ctrl(
  UserService,
  UtilitiesService,
  $element,
  NotificationService,
  $scope,
  moment
) {
  var vm = this;
  var dateParser = function(value) {
    var d = moment(value);
    return d.format("YYYY-MM-DD h:mm a");
  };

  vm.fields = [
    {
      label: "Date",
      id: "date",
      parse: dateParser
    },
    {
      label: "OS",
      computed: function(row) {
        var windowsPrefix = "Microsoft Windows ";
        var osName = row.osName;
        var osVersion = row.osVersion;
        if (osName === "Windows") {
          osVersion = osVersion.replace(windowsPrefix, "");
        }
        return osName + " " + osVersion;
      }
    },
    {
      label: "App Version",
      id: "appVersion"
    },
    {
      label: "Type",
      id: "type"
    },
    {
      label: "Description",
      id: "description"
    },
    {
      label: "Result",
      id: "result"
    },
    {
      label: "Client Date",
      id: "clientDate",
      parse: dateParser
    },
    {
      label: "Session ID",
      id: "sessionId"
    },
    {
      label: "Device ID",
      id: "deviceId"
    }
  ];

  vm.query = {
    order: "date DESC",
    where: {},
    limit: 50,
    skip: 0,
    page: 1
  };

  vm.get = function() {
    if (!vm.userId) return;
    vm.getTotal(vm.userId).then(function(result) {
      vm.total = result;
    });
    var promise = UserService.getActivityLogs(vm.userId, vm.query);
    UtilitiesService.inlineLoader(promise, $element);
    promise
      .then(function(result) {
        vm.data = result.data;
      })
      .catch(function(result) {
        NotificationService.toastError(
          "Could not get activity log: " + result.data.error.message
        );
      });
  };

  vm.getTotal = function(id) {
    return UserService.getActivityLogsCount(id).then(function(result) {
      return result.data.count;
    });
  };

  $scope.$watch("vm.userId", function() {
    if (vm.userId) vm.get();
  });
}
