import React from "react";
import { Card, CardMedia } from "material-ui/Card";
import pt from "prop-types";
import { compose, setPropTypes } from "recompose";
import { NoThumbnailPreview } from "./NoThumbnailPreview";
import { LoadingOverlay } from "new/shared";
import { Img } from "glamorous";

const aspect = 0.65;
const defaultHeight = 200;

const Component = ({
  url,
  loading,
  error,
  style,
  height = defaultHeight,
  width = height * aspect,
  image,
  noThumbnailText,
  alt = "book thumbnail",
  ImgComponent = Img,
  ...other
}) => (
  <Card
    {...other}
    style={{
      width,
      height,
      ...style
    }}
  >
    <CardMedia style={{ height, width }}>
      {url ? (
        <ImgComponent style={{ height, width }} src={url} alt={alt} />
      ) : !loading ? (
        <NoThumbnailPreview height={height} width={width}>
          {noThumbnailText}
        </NoThumbnailPreview>
      ) : (
        <LoadingOverlay height={height} width={width} />
      )}
    </CardMedia>
  </Card>
);

export const BookThumbnailCard = compose(
  setPropTypes({
    url: pt.string
  })
)(Component);
