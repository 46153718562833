"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.cloudBulkPurchase = function (cloudId, body) {
  return index_1.getNewapi().post("/api/Clouds/" + cloudId + "/bulkPurchase", body);
};

exports.findClouds = function (filter) {
  return index_1.getNewapi().get("/api/Clouds", {
    params: {
      filter: filter
    }
  });
};

exports.findById = function (cloudId, filter) {
  return index_1.getNewapi().get("/api/Clouds/" + cloudId, {
    params: {
      filter: filter
    }
  });
};

exports.addBooks = function (cloudId, books) {
  return index_1.getNewapi().post("/api/Clouds/" + cloudId + "/addBooks", {
    books: books
  });
};

exports.removeBooks = function (cloudId, books) {
  return index_1.getNewapi().post("/api/Clouds/" + cloudId + "/removeBooks", {
    books: books
  });
};

exports.userBookStatistics = function (cloudId, body) {
  return index_1.getNewapi().post("/api/Clouds/" + cloudId + "/userBookStatistics", body);
};

exports.patchCloud = function (cloudId, body) {
  return index_1.getNewapi().patch("/api/Clouds/" + cloudId, body);
};

exports.institutionDetails = function (cloudId) {
  return index_1.getNewapi().get("/api/Clouds/" + cloudId + "/institutionDetails");
};