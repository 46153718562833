"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var index_1 = require("./index");

exports.resellerUpdateUser = function (user, apiKey, config) {
  return index_1.getApiv14().post("/reseller/update/user", user, tslib_1.__assign(tslib_1.__assign({}, config), {
    params: tslib_1.__assign(tslib_1.__assign({}, config ? config.params : {}), {
      apiKey: apiKey
    })
  }));
};

exports.resellerCreateUser = function (user, apiKey, config) {
  return exports.resellerUpdateUser(tslib_1.__assign(tslib_1.__assign({}, user), {
    contentAction: "add"
  }), apiKey, config);
};

exports.resellerPermDeleteUser = function (params, config) {
  return index_1.getApiv14().post("/reseller/update/user", {
    email: params.email,
    institution: params.institution,
    deleted: "permanent"
  }, tslib_1.__assign(tslib_1.__assign({}, config), {
    params: tslib_1.__assign(tslib_1.__assign({}, config ? config.params : {}), {
      apiKey: params.apiKey
    })
  }));
};

exports.addUser = function (req, config) {
  return index_1.getApiv14().post("/add/user", req, config);
};

exports.listUsers = function (config) {
  return index_1.getApiv14().get("/list/user.json", config);
};

exports.deleteUser = function (email, permanent, config) {
  if (permanent === void 0) {
    permanent = false;
  }

  return index_1.getApiv14().delete("/delete/user", tslib_1.__assign({
    data: {
      email: email,
      permanent: permanent
    }
  }, config));
};

exports.searchUser = function (institution, searchKey, searchString, config) {
  return index_1.getApiv14().get("/search/user", tslib_1.__assign({
    params: {
      institution: institution,
      searchKey: searchKey,
      searchString: searchString
    }
  }, config));
};