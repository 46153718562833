/**
 * Created by danie on 5/10/2016.
 */

RouteService.$inject = ["$state", "$window", "$document", "$location"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("RouteService", RouteService);

/* @ngInject */
function RouteService($state, $window, $document, $location) {
  var RouteService = {};

  RouteService.hasPrevious = function hasPrevious() {
    return new RegExp(".*" + $document[0].referrer + ".*").test(
      $location.absUrl().toString()
    );
  };

  RouteService.back = function back() {
    $window.history.back();
  };

  /**
     *
     * @type {{institutions, institutionEdit, users, userDetails, userClouds, clouds}}
       */
  RouteService.admin = (function() {
    function wrap(route) {
      var base = "main.admin.";
      return base + route;
    }

    return {
      home: () => $state.go("main.admin"),
      institutions: function() {
        $state.go(wrap("institutions.find"));
      },
      institutionEdit: function(id, name) {
        $state.go(wrap("institutions.edit"), { id: id, name: name});
      },
      users: function(institution) {
        $state.go(wrap("users.find"), { institution: institution });
      },
      userDetails: function(email) {
        $state.go(wrap("users.details"), { email: email });
      },
      userClouds: function(userId) {
        $state.go(wrap("users.clouds"), { userId: userId });
      },
      clouds: function(institution) {
        $state.go(wrap("clouds.find"), { institution: institution });
      },
      cloudDetails: function(cloudId) {
        $state.go(wrap("clouds.details"), { cloudId: cloudId });
      },
      bookSearch: function() {
        $state.go(wrap("books"));
      },
      bookSearchAll: function() {
        $state.go(wrap("booksAll"));
      },
      transactionSearchAll: function() {
        $state.go(wrap("transactionsAll"));
      }
    };
  })();

  /**
     *
     * @type {{me}}
       */
  RouteService.user = (function() {
    function wrap(route) {
      var base = "main.";
      return base + route;
    }

    return {
      me: function() {
        $state.go(wrap("me"));
      }
    };
  })();

  RouteService.settings = (function() {
    var prefix = "main.admin.settings.";
    return {
      publisherOptions: function() {
        $state.go(prefix + "publisherOptions");
      },
      resellerOptions: function() {
        $state.go(prefix + "resellerOptions");
      },
      mappedOrdersManager: $state.go.bind(
        null,
        prefix + "mapped-orders.manager"
      ),
      applicationOptions: $state.go.bind(null, prefix + "applicationOptions"),
      actionsManager: $state.go.bind(null, prefix + "actionsManager")
    };
  })();

  RouteService.reporting = (function() {
    var prefix = "main.admin.reporting.";
    return {
      cloudStatistics: function(id) {
        $state.go(prefix + "cloudStatistics", { id: id });
      },
      transactionsReport: function() {
        $state.go(prefix + "transactionsReport");
      }
    };
  })();

  RouteService.help = function(id) {
    return $state.go("main.help", { id: id });
  };

  return RouteService;
}
