/**
 * Created by Daniel on 6/22/2016.
 */
RCResellersSelector.$inject = ["InstitutionService", "UtilitiesService", "_"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcInstitutionsSelector", {
  controller: RCResellersSelector,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-institutions-selector/template.html").then(m => m.default),
  bindings: {
    initial: "<",
    onSelectedChanged: "<",
    required: "<"
  }
});

/*@ngInject*/
function RCResellersSelector(InstitutionService, UtilitiesService, _) {
  var vm = this;

  vm.selected = vm.initial;
  vm.get = InstitutionService.get.bind(null);

  vm.toggleAll = function() {
    var institutionIds = vm.institutions.map(function(i) {
      return i.id;
    });
    if (
      _.intersection(vm.selected, institutionIds).length ===
      institutionIds.length
    ) {
      vm.selected = _.pullAll(vm.selected, institutionIds);
    } else {
      vm.selected = _.concat(vm.selected, institutionIds);
    }
    vm.changed();
  };

  vm.get().then(function(result) {
    if (result.data) {
      vm.institutions = result.data;
    }
  });

  vm.changed = function() {
    if (vm.onSelectedChanged) vm.onSelectedChanged(vm.selected);
  };

  vm.getScopeSelected = function() {
    return _.intersection(
      vm.selected,
      vm.institutions.map(function(i) {
        return i.id;
      })
    );
  };
}
