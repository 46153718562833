/**
 * Created by danie on 7/21/2016.
 */

RCMappedOrderManagerController.$inject = [
  "_",
  "ResellerService",
  "UtilitiesService",
  "$element",
  "DialogService",
  "$scope",
  "NotificationService",
  "SelectedBookListService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcMappedOrderManager", {
  controller: RCMappedOrderManagerController,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-mapped-order-manager/template.html").then(m => m.default)
});

/* @ngInject */

function RCMappedOrderManagerController(
  _,
  ResellerService,
  UtilitiesService,
  $element,
  DialogService,
  $scope,
  NotificationService,
  SelectedBookListService
) {
  var vm = this;
  var updater = NotificationService.toastIt("Update", "Mapped Order");
  var deleter = NotificationService.toastIt("Delete", "Mapped Order");

  var currentOrder = "created DESC";
  vm.onReorder = function(order) {
    currentOrder = UtilitiesService.getLoopbackOrder(order);
    vm.refreshData();
  };

  vm.refreshData = function() {
    UtilitiesService.inlineLoader(
      ResellerService.getMappedOrders(vm.selectedReseller, {
        order: currentOrder
      }).then(function(result) {
        vm.data = result.data;
      }),
      $element
    );
  };
  vm.add = function() {
    return DialogService.MappedOrderCreator(vm.selectedReseller).then(
      vm.refreshData
    );
  };

  vm.fields = [
    {
      label: "Name",
      id: "name"
    },
    {
      label: "Orders",
      id: "orders"
    }
  ];

  vm.editRow = function(row) {
    console.log(row);
    DialogService.MappedOrderEditor(row).finally(vm.refreshData);
  };

  vm.showOptions = function($mdOpenMenu, $event) {
    $mdOpenMenu($event);
  };

  vm.deleteRow = function(row) {
    NotificationService.areYouSure(
      "Delete Mapped Order",
      "Are you sure you want to delete this mapped order?"
    ).then(function() {
      ResellerService.deleteMappedOrder(vm.selectedReseller, row.id)
        .then(deleter, deleter)
        .finally(vm.refreshData);
    });
  };

  $scope.$watch("vm.selectedReseller", function() {
    if (vm.selectedReseller) vm.refreshData();
  });

  vm.updateRow = function(row) {
    row.orders = SelectedBookListService.current;
    var id = row.id;
    delete row.id;
    ResellerService.updateMappedOrder(vm.selectedReseller, id, row)
      .then(updater, updater)
      .finally(vm.refreshData);
  };
}
