"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.getRangeTextContent = function (range) {
  var contents = range.cloneContents();

  if (contents.textContent) {
    return contents.textContent.replace("\n", " ");
  }

  return "";
};