//For some reason redux-persist have decided to use a separate store...this is to synchronise them
import { composeReducer, scopeReducers, createFactory } from "recrux";

const namespace = "customPersist";

const defaultState = {
  bootstrapped: false
};

export const bootstrap = createFactory({
  namespace,
  actionName: "bootstrap",
  reducer: state => ({ ...state, bootstrapped: true })
});

export const persistReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    bootstrap.reducer
  )
});

export const selectPersist = state => state[namespace];
