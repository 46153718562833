/**
 * Created by danie on 5/9/2016.
 */

const angular = require("angular");
angular.module("readCloudWebConsole").component("rcSpinner", {
  controller: RCSpinner,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-spinner/template.html").then(m => m.default)
});

/* @ngInject */

function RCSpinner() {}
