import "./initRecompose";
import React from "react";
import { Provider, connect } from "react-redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { store } from "new/redux/store";
import { muiTheme } from "./muiTheme";
import { Div, P } from "glamorous";
import { branch, compose, defaultProps, renderComponent } from "recompose";
import Loadr from "randloadr/dist/loadr";
import { selectSessionData } from "new/redux/reducers/session";
import { injectServices } from "./ngReactBridge";
import { Flex } from "new/shared";
import CircularProgress from "material-ui/CircularProgress";
import { selectPersist } from "new/redux/reducers/persist";
import { DialogController } from "./Dialog/DialogController";
import { ApolloProvider } from "./ApolloProvider";
import {
  v14,
  setAccessToken,
  getAccessToken,
  getRefreshToken,
  isJwtValid,
} from "@readcloud/api-client/build/client";
import("./react-table.css");

const instance = new (window.Loadr || Loadr)();

const loaderElement = (
  <Flex column centerBoth height="100%" width="100%">
    <CircularProgress />
    <P color="#aaa">{instance.get()}...</P>
  </Flex>
);

const waitForState = compose(
  connect((state) => ({
    sessionData: selectSessionData(state),
    bootstrapped: selectPersist(state).bootstrapped,
  })),
  branch(
    (props) => {
      return (
        !props.sessionData || !props.sessionData.userId || !props.bootstrapped
      );
    },
    renderComponent(({ rehydrated }) => loaderElement)
  )
);

const ContentContainer = compose(
  defaultProps({ height: "100%", width: "100%" }),
  waitForState
)(Div);

export const RootComponent = ({ children, services, ...props }) => {
  React.useEffect(() => {
    if (v14) {
      /********** Validate Token *******/
      const currentAccessToken = getAccessToken();
      //INVALID TOKEN example
      /*const currentAccessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1ODAyNjk1ODMsImV4cCI6MTU4MDI3Njc4MywiZW1haWwiOiJkYXJyZW5AcmVhZGNsb3VkLmNvbSIsInJvbGUiOiJUZWFjaGVyIiwiaW5zdGl0dXRpb24iOiI1NThhOGE3Yzg3Mjk0MzFlNzhlZDc4ZmUiLCJ1c2VySWQiOiI1NTZkMzhlYWU0YjA5MjdmOWFlMThhMTAiLCJjbGllbnRJZCI6InJlYWRDbG91ZFdlYlJlYWRlciIsInVzZXJTY29wZSI6IiJ9.jPsurLL8gU-URo2CkxlgZIrSh8wzrSPJmvkvGyxc6HE";
    setAccessToken(currentAccessToken);
    console.log("AccessToken", currentAccessToken);*/
      console.log("JWT", isJwtValid(currentAccessToken));

      const setNewTokenProcess = async () => {
        v14.setApiv14Url("/api/v14"); // TODO put to another place
        return v14.Auth.reAuthenticate(getRefreshToken())
          .then((res) => {
            //console.log("Set New Access Token", res.accessToken);
            setAccessToken(res.accessToken);
          })
          .catch((err) => {
            console.log(err);
            window.location.hash = "!/login";
          });
      };

      if (
        currentAccessToken &&
        !isJwtValid(currentAccessToken) &&
        getRefreshToken()
      ) {
        setNewTokenProcess();

        console.log("NewAccess Token", getAccessToken());
      } else if (!isJwtValid(currentAccessToken)) {
        window.location.hash = "!/login";
      }
    } else {
      //window.location.hash = "!/login";
    }
  }, [props, children]);

  /********** End of Validate Token *******/

  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <Provider store={store}>
        <ApolloProvider>
          <ContentContainer>
            <DialogController />
            {children}
          </ContentContainer>
        </ApolloProvider>
      </Provider>
    </MuiThemeProvider>
  );
};

export const Root = compose(injectServices)(RootComponent);
