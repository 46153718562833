"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var axios_1 = require("axios");

var ramda_1 = require("ramda");

var client_1 = require("../client");

var _Annotations = require("./annotations");

var _App = require("./app");

var _Audit = require("./audit");

var _Auth = require("./auth");

var _Bookmarks = require("./bookmarks");

var _Books = require("./books");

var _Clouds = require("./clouds");

var _Comments = require("./comments");

var _Institutions = require("./institutions");

var _Logs = require("./logs");

var _MultiOrders = require("./multiOrders");

var _Resellers = require("./resellers");

var _Statistics = require("./statistics");

var _Stream = require("./stream");

var _UserActivity = require("./userActivity");

var _Users = require("./users");

var _ResetPassword = require("./resetpassword");

var _UserPreferences = require("./userPreferences");

var _ViewState = require("./viewState");

var _Transactions = require("./transactions");

var makeAuthInterceptor_1 = require("../makeAuthInterceptor");

var apiv14Url;

var addApiv14UrlInterceptor = function addApiv14UrlInterceptor(client) {
  client.interceptors.request.use(function (config) {
    return tslib_1.__assign({
      baseURL: apiv14Url
    }, config);
  });
  return client;
};

var addApiKeyInterceptor = function addApiKeyInterceptor(client) {
  client.interceptors.request.use(function (config) {
    return tslib_1.__assign(tslib_1.__assign({}, config), {
      params: tslib_1.__assign({
        apiKey: "bed91270-fd9f-11e1-bb4b-84c9b273558d"
      }, config.params)
    });
  });
  return client;
};

var makeApiv14Client = ramda_1.compose(addApiv14UrlInterceptor, addApiKeyInterceptor, makeAuthInterceptor_1.makeAuthAssertionInterceptor(_Auth.reAuthenticate), client_1.addCommonAxiosConfig, axios_1.default.create);
var apiv14Client = makeApiv14Client({});

var ensureApiV14ClientReady = function ensureApiV14ClientReady() {
  if (!!apiv14Url) {
    return true;
  } else {
    throw new Error("API V14 client was accessed before the base URL was set. Please configure this before accessing the client.");
  }
};

exports.getApiv14 = function () {
  return ensureApiV14ClientReady(), apiv14Client;
};

exports.setApiv14Url = function (url) {
  return apiv14Url = url;
};

exports.getStatus = function () {
  return exports.getApiv14().get("/status");
};

exports.Users = _Users;
exports.Clouds = _Clouds;
exports.Annotations = _Annotations;
exports.App = _App;
exports.Audit = _Audit;
exports.Auth = _Auth;
exports.Bookmarks = _Bookmarks;
exports.Books = _Books;
exports.Institutions = _Institutions;
exports.Logs = _Logs;
exports.MultiOrders = _MultiOrders;
exports.Resellers = _Resellers;
exports.Statistics = _Statistics;
exports.Comments = _Comments;
exports.ResetPassword = _ResetPassword;
exports.UserActivity = _UserActivity;
exports.Stream = _Stream;
exports.UserPreferences = _UserPreferences;
exports.ViewState = _ViewState;
exports.Transactions = _Transactions;