import React from "react";
import { connect } from "react-redux";
import { Snackbar } from "material-ui";
import { compose, setDisplayName } from "recompose";
import { select } from "new/Snackbar/reducer";

export const MainSnackbarComponent = ({ message, open = false }) => (
  <Snackbar open={open} message={message || ""} />
);
export const MainSnackbar = compose(
  setDisplayName("MainSnackbar"),
  connect(select)
)(MainSnackbarComponent);
