ctrl.$inject = [
  "ActionService",
  "_",
  "$element",
  "NotificationService",
  "UtilitiesService",
  "AuthenticationService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcActionCard", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-actions-manager/rc-action-card/template.html").then(m => m.default),
  bindings: {
    data: "<",
    onSave: "<",
    onDelete: "<"
  }
});
/* @ngInject */
function ctrl(
  ActionService,
  _,
  $element,
  NotificationService,
  UtilitiesService,
  AuthenticationService
) {
  var vm = this;

  var submission = (vm.submission = _.omit(_.cloneDeep(vm.data), "id"));

  var notifier = NotificationService.toastIt("Update", "Action");

  var deletionNotifier = NotificationService.toastIt("Delete", "Action");

  vm.isResellerAdmin = AuthenticationService.getRole() === "ResellerAdmin";

  vm.canSave = function() {
    return true;
  };

  vm.entityChanged = function(entity) {
    if (entity !== submission.entity) {
      submission.entity = entity;
    }
  };

  vm.rolesChanged = function(roles) {
    if (roles !== submission.allowedRoles) {
      submission.allowedRoles = roles;
    }
  };

  vm.resellersChanged = function(resellers) {
    if (resellers !== submission.allowedResellers) {
      submission.allowedResellers = resellers;
    }
  };

  vm.institutionsChanged = function(institutions) {
    if (institutions !== submission.allowedInstitutions) {
      submission.allowedInstitutions = institutions;
    }
  };

  vm.deleteAction = function(id) {
    return NotificationService.areYouSure(
      "Action Deletion",
      "Are you sure you want to delete this action?"
    ).then(function() {
      var promise = ActionService.deleteAction(id);
      promise.then(deletionNotifier, deletionNotifier);
      UtilitiesService.inlineLoader(promise, $element);
      promise.then(function() {
        if (vm.onDelete) {
          vm.onDelete(id);
        }
      });
      return promise;
    });
  };

  vm.actionTypeChanged = function(action) {
    if (action !== submission.type) {
      submission.type = action;
    }
  };

  vm.save = function() {
    var promise = ActionService.update(vm.data.id, submission);
    promise.then(notifier, notifier);
    UtilitiesService.inlineLoader(promise, $element);
    return promise;
  };

  vm.allowedToEditUrl = vm.allowedToEditName = function() {
    return AuthenticationService.testRolesAgainstCurrent(["^Admin$"]);
  };
}
