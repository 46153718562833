/**
 * Created by danie on 4/18/2016.
 */

UserActions.$inject = ["$state", "RouteService"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("UserActions", UserActions);

/*@ngInject*/
function UserActions($state, RouteService) {
  var UserActions = this;
  UserActions.logout = function() {
    $state.go("login");
  };

  UserActions.myProfile = function() {
    RouteService.user.me();
  };
}
