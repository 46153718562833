"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var app_1 = require("./app");

var app_2 = require("./app");

var handleResetPasswordGenResponse = function handleResetPasswordGenResponse(response) {
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data.result;
};

exports.performResetPasswordGen = function (email, config) {
  return app_1.resetPasswordGen(email, config).then(handleResetPasswordGenResponse);
};

var handleResetPasswordResponse = function handleResetPasswordResponse(response) {
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data.result;
};

exports.performResetPassword = function (email, tmpPwd, newPwd, config) {
  return app_2.resetPassword(email, tmpPwd, newPwd, config).then(handleResetPasswordResponse);
};