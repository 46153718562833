"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = __importStar(require("io-ts"));

var bson_1 = require("bson");

var withFallback_1 = require("io-ts-types/lib/withFallback");

var OverlayAnnotations;

(function (OverlayAnnotations) {
  // Updating this will cause all new annotations to have this as the version field
  OverlayAnnotations.CURRENT_VERSION = "3";
  OverlayAnnotations.annotationRect = t.type({
    width: t.number,
    height: t.number,
    x: t.number,
    y: t.number
  });
  OverlayAnnotations.annotationOptionsType = t.union([t.undefined, t.nullType, t.literal("Publisher")]);
  OverlayAnnotations.annotationOptionsStatus = t.union([t.undefined, t.nullType, t.literal("Draft"), t.literal("Published")]);
  OverlayAnnotations.annotationType = t.union([t.literal("Path"), t.literal("Rectangle"), t.literal("OverlayText"), t.literal("Circle"), t.literal("HTML"), t.literal("Media"), t.literal("Image"), t.literal("Text")]);
  OverlayAnnotations.annotationVersion = t.union([t.undefined, t.null, t.literal("2"), t.literal("3")]);
  OverlayAnnotations.annotationBookType = t.union([t.literal("EPUB"), t.literal("PDF"), t.literal("MEDIA")]);
  OverlayAnnotations.annotationCommon = t.intersection([t.type({
    id: t.string,
    created: t.string,
    updated: t.string,
    clientCreated: withFallback_1.withFallback(t.union([t.string, t.undefined]), undefined),
    clientUpdated: withFallback_1.withFallback(t.union([t.string, t.undefined]), undefined),
    deleted: t.boolean,
    visibleToUsers: t.array(t.string),
    visibleInClouds: t.array(t.string),
    author: t.string,
    color: t.string,
    book: t.string,
    bookType: OverlayAnnotations.annotationBookType,
    location: t.type({
      pageNumber: t.number
    }),
    version: OverlayAnnotations.annotationVersion,
    selectedText: t.string
  }), t.partial({
    tags: withFallback_1.withFallback(t.union([t.array(t.string), t.null, t.undefined]), []),
    options: withFallback_1.withFallback(t.type({
      type: OverlayAnnotations.annotationOptionsType,
      status: OverlayAnnotations.annotationOptionsStatus
    }), {
      type: undefined,
      status: undefined
    })
  })]);
  OverlayAnnotations.commonData = t.type({
    data: t.partial({
      description: t.string,
      scaleWithPage: t.boolean,
      belowContent: t.boolean
    })
  });
  OverlayAnnotations.boundingRectData = t.type({
    rect: OverlayAnnotations.annotationRect
  });
  OverlayAnnotations.textHighlightAnnotation = t.intersection([OverlayAnnotations.annotationCommon, t.type({
    location: t.type({
      range: t.string
    }),
    type: t.literal("Text")
  }), t.partial({
    text: t.union([t.string, t.null]),
    data: t.undefined
  })]);
  OverlayAnnotations.mediaAnnotation = t.intersection([OverlayAnnotations.annotationCommon, OverlayAnnotations.commonData, t.type({
    type: t.literal("Media"),
    data: t.intersection([OverlayAnnotations.boundingRectData, t.type({
      url: t.string,
      variant: t.union([t.literal("Embedded"), t.literal("Icon")])
    })])
  })]);
  OverlayAnnotations.htmlAnnotation = t.intersection([OverlayAnnotations.annotationCommon, OverlayAnnotations.commonData, t.type({
    type: t.literal("HTML"),
    data: t.intersection([OverlayAnnotations.boundingRectData, t.type({
      html: t.string
    })])
  })]);
  OverlayAnnotations.imageAnnotation = t.intersection([OverlayAnnotations.annotationCommon, OverlayAnnotations.commonData, t.type({
    type: t.literal("Image"),
    data: t.intersection([OverlayAnnotations.boundingRectData, t.type({
      url: t.string
    })])
  })]);
  OverlayAnnotations.commonShapeData = t.type({
    fill: t.string
  });
  OverlayAnnotations.shapeAnnotationData = t.type({
    stroke: withFallback_1.withFallback(t.string, "#00000000"),
    strokeWidth: withFallback_1.withFallback(t.number, 0)
  });
  OverlayAnnotations.rectangleAnnotation = t.intersection([OverlayAnnotations.annotationCommon, OverlayAnnotations.commonData, t.type({
    type: t.literal("Rectangle"),
    data: t.intersection([OverlayAnnotations.boundingRectData, OverlayAnnotations.commonShapeData, OverlayAnnotations.shapeAnnotationData])
  })]);
  OverlayAnnotations.overlayTextAnnotation = t.intersection([OverlayAnnotations.annotationCommon, OverlayAnnotations.commonData, t.type({
    type: t.literal("OverlayText"),
    data: t.intersection([OverlayAnnotations.boundingRectData, OverlayAnnotations.commonShapeData, t.type({
      text: t.string,
      lineHeight: withFallback_1.withFallback(t.number, 1),
      overflowWrap: withFallback_1.withFallback(t.union([t.literal("break-word"), t.literal("normal")]), "normal"),
      wordBreak: withFallback_1.withFallback(t.union([t.literal("break-all"), t.literal("keep-all"), t.literal("normal")]), "normal"),
      textAlign: withFallback_1.withFallback(t.union([t.literal("center"), t.literal("left"), t.literal("right")]), "left"),
      fontFamily: withFallback_1.withFallback(t.string, "Arial"),
      fontSize: withFallback_1.withFallback(t.number, 24),
      fontWeight: withFallback_1.withFallback(t.number, 400)
    })])
  })]);
  OverlayAnnotations.circleAnnotation = t.intersection([OverlayAnnotations.annotationCommon, OverlayAnnotations.commonData, t.type({
    type: t.literal("Circle"),
    data: t.intersection([OverlayAnnotations.boundingRectData, OverlayAnnotations.commonShapeData, OverlayAnnotations.shapeAnnotationData])
  })]);
  OverlayAnnotations.point = t.type({
    x: t.number,
    y: t.number
  });
  OverlayAnnotations.path = t.type({
    points: t.array(OverlayAnnotations.point)
  });
  OverlayAnnotations.pathAnnotation = t.intersection([OverlayAnnotations.annotationCommon, OverlayAnnotations.commonData, t.type({
    type: t.literal("Path"),
    data: t.intersection([OverlayAnnotations.boundingRectData, t.type({
      stroke: t.string,
      strokeWidth: t.number,
      paths: t.array(OverlayAnnotations.path)
    })])
  })]);
  OverlayAnnotations.annotation = t.union([OverlayAnnotations.mediaAnnotation, OverlayAnnotations.htmlAnnotation, OverlayAnnotations.imageAnnotation, OverlayAnnotations.rectangleAnnotation, OverlayAnnotations.circleAnnotation, OverlayAnnotations.overlayTextAnnotation, OverlayAnnotations.pathAnnotation]);
  OverlayAnnotations.annotationOrHighlight = t.union([OverlayAnnotations.annotation, OverlayAnnotations.textHighlightAnnotation]);
  OverlayAnnotations.mediaVariants = ["Embedded", "Icon"];

  OverlayAnnotations.createEmptyAnnotation = function (_a) {
    var author = _a.author,
        book = _a.book,
        color = _a.color,
        pageNumber = _a.pageNumber,
        rect = _a.rect,
        type = _a.type,
        annoPrefs = _a.annoPrefs;
    var common = {
      location: {
        pageNumber: pageNumber
      },
      author: author,
      book: book,
      color: color,
      version: "3",
      selectedText: ""
    };
    var preferences = annoPrefs && annoPrefs[type];

    switch (type) {
      case "Media":
        return OverlayAnnotations.annotationContainers[type].create(__assign(__assign({}, common), {
          data: __assign(__assign({
            variant: "Embedded"
          }, preferences), {
            description: "",
            rect: rect,
            url: "",
            scaleWithPage: true
          })
        }));

      case "HTML":
        return OverlayAnnotations.annotationContainers[type].create(__assign(__assign({}, common), {
          data: __assign(__assign({}, preferences), {
            description: "",
            html: "",
            rect: rect,
            scaleWithPage: true
          })
        }));

      case "Image":
        return OverlayAnnotations.annotationContainers[type].create(__assign(__assign({}, common), {
          data: __assign(__assign({}, preferences), {
            description: "",
            rect: rect,
            url: "",
            scaleWithPage: true
          })
        }));

      case "Circle":
        return OverlayAnnotations.annotationContainers[type].create(__assign(__assign({}, common), {
          data: __assign(__assign({
            fill: color,
            stroke: "#00000000",
            strokeWidth: 0
          }, preferences), {
            // anything above this will get replaced if a preference exists for it.
            rect: rect,
            scaleWithPage: true,
            belowContent: true
          })
        }));

      case "Rectangle":
        return OverlayAnnotations.annotationContainers[type].create(__assign(__assign({}, common), {
          data: __assign(__assign({
            fill: color,
            stroke: "#00000000",
            strokeWidth: 0
          }, preferences), {
            // anything above this will get replaced if a preference exists for it.
            rect: rect,
            scaleWithPage: true,
            belowContent: true
          })
        }));

      case "OverlayText":
        return OverlayAnnotations.annotationContainers[type].create(__assign(__assign({}, common), {
          data: __assign(__assign({
            fill: "#000000",
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1,
            overflowWrap: "normal",
            wordBreak: "normal"
          }, preferences), {
            text: "Enter Text",
            rect: rect,
            scaleWithPage: true,
            belowContent: true
          })
        }));

      case "Path":
        return OverlayAnnotations.annotationContainers[type].create(__assign(__assign({}, common), {
          data: __assign(__assign({
            strokeWidth: 4,
            stroke: "#000000"
          }, preferences), {
            rect: rect,
            paths: [],
            scaleWithPage: true,
            belowContent: true
          })
        }));
    }
  };

  var AnnotationContainer =
  /** @class */
  function () {
    function AnnotationContainer(type, ioType, config, defaultData) {
      var _this = this;

      this.type = type;
      this.ioType = ioType;
      this.config = config;
      this.defaultData = defaultData;

      this.create = function (params) {
        return {
          created: new Date().toISOString(),
          updated: new Date().toISOString(),
          clientCreated: new Date().toISOString(),
          clientUpdated: new Date().toISOString(),
          id: new bson_1.ObjectId().valueOf().toString(),
          visibleInClouds: [],
          visibleToUsers: [],
          deleted: false,
          type: _this.type,
          options: params.options,
          author: params.author,
          book: params.book,
          tags: params.tags || [],
          data: params.data,
          color: params.color,
          location: params.location,
          version: params.version || OverlayAnnotations.CURRENT_VERSION,
          selectedText: params.selectedText || "",
          bookType: "PDF"
        };
      };
    }

    return AnnotationContainer;
  }();

  OverlayAnnotations.annotationContainers = {
    Path: new AnnotationContainer("Path", OverlayAnnotations.pathAnnotation, {
      requiresCreationForm: false,
      minHeight: 50,
      minWidth: 50
    }),
    Circle: new AnnotationContainer("Circle", OverlayAnnotations.circleAnnotation, {
      requiresCreationForm: false,
      minHeight: 50,
      minWidth: 50
    }),
    OverlayText: new AnnotationContainer("OverlayText", OverlayAnnotations.overlayTextAnnotation, {
      requiresCreationForm: false,
      minHeight: 30,
      minWidth: 100
    }),
    Rectangle: new AnnotationContainer("Rectangle", OverlayAnnotations.rectangleAnnotation, {
      requiresCreationForm: false,
      minHeight: 10,
      minWidth: 10
    }),
    HTML: new AnnotationContainer("HTML", OverlayAnnotations.htmlAnnotation, {
      requiresCreationForm: true,
      minHeight: 100,
      minWidth: 100
    }),
    Image: new AnnotationContainer("Image", OverlayAnnotations.imageAnnotation, {
      requiresCreationForm: true,
      minHeight: 100,
      minWidth: 100
    }),
    Media: new AnnotationContainer("Media", OverlayAnnotations.mediaAnnotation, {
      requiresCreationForm: true,
      minHeight: 100,
      minWidth: 100
    })
  };
  /*   export const match = <V extends t.Mixed, CS extends [V, V, ...V[]], R>(
    patterns: t.UnionC<CS>
  ) => (matchers: { [k in Extract<CS, number>]: (pattern: CS[k]) => R }) => (
    value: V
  ) => {
    const index = patterns.types.findIndex((t, i) => t.is(value));
    if (index < 0) {
      throw new Error("Incorrect pattern error");
    } else {
      return (matchers as any)[index](value);
    }
  }; */
})(OverlayAnnotations = exports.OverlayAnnotations || (exports.OverlayAnnotations = {}));