import { combineEpics } from "redux-observable";
import { institutionAdminEpic } from "new/InstitutionAdmin/reducer";
import { cloudAdminEpic } from "new/CloudAdmin/reducer";
import { annotationExportEpic } from "new/Reports/AnnotationExport/reducer";
import { bookSearchEpic } from "new/Books/Search/reducer";
import { logsEpic } from "../Logs/reducer";

export const rootEpic = combineEpics(
  institutionAdminEpic,
  cloudAdminEpic,
  annotationExportEpic,
  bookSearchEpic,
  logsEpic
);
