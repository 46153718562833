const angular = require("angular");
angular.module("readCloudWebConsole").component("rcOrderListItem", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-mapped-order-manager/order-list-item/template.html").then(m => m.default),
  bindings: {
    data: "<"
  }
});

/*@ngInject*/
function ctrl() {}
