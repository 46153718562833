/**
 * Created by Daniel on 6/22/2016.
 */
const angular = require("angular");
angular.module("readCloudWebConsole").service("ClientApplicationService", ClientApplicationService);

/*@ngInject*/
function ClientApplicationService() {
  var ClientApplicationService = {};

  ClientApplicationService.model = function ClientApplication(/*id, name, allowedOrigin, refreshTokenExpireTimeMinutes,active, accessTokenExpireTimeMinutes*/) {};

  return ClientApplicationService;
}
