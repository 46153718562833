"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.addMultiOrder = function (req) {
  return index_1.getApiv14().post("/add/multiorder", req);
};

exports.deleteMultiOrder = function (name, resellerId) {
  return index_1.getApiv14().delete("/delete/multiorder", {
    data: {
      name: name,
      resellerId: resellerId
    }
  });
};

exports.resellerUpdateMappedOrder = function (req, apiKey) {
  return index_1.getApiv14().post("/reseller/update/mappedOrder", req, {
    params: {
      apiKey: apiKey
    }
  });
};

exports.searchMultiOrder = function (searchKey, searchString) {
  return index_1.getApiv14().get("/search/multiorder", {
    params: {
      searchKey: searchString
    }
  });
};