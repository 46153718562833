/**
 * Created by danie on 5/5/2016.
 */

RCUserPasswordChanger.$inject = [
  "UserService",
  "$scope",
  "UtilitiesService",
  "$element",
  "NotificationService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserPasswordChanger", {
  controller: RCUserPasswordChanger,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-user-password-changer/template.html").then(m => m.default),
  bindings: {
    userId: "<",
    email: "<"
  }
});

/* @ngInject */

function RCUserPasswordChanger(
  UserService,
  $scope,
  UtilitiesService,
  $element,
  NotificationService
) {
  var vm = this;

  $scope.submission = {};

  $scope.submit = function submit(password, confirm) {
    if ($scope.password.length < 6)
      return NotificationService.toastError("Passwords must be at least 6 characters long");
    if ($scope.password.length > 20)
      return NotificationService.toastError("Passwords must be less than 20 characters long");  
    if ($scope.password !== $scope.confirm)
      return NotificationService.toastError("Passwords do not match");
    UtilitiesService.inlineLoader(
      UserService.changePassword(
        vm.userId,
        $scope.password,
        $scope.confirm
      ).then(function() {
        delete $scope.password;
        delete $scope.confirm;
        $scope.passwordChanger.$setUntouched();
      }),
      $element
    );
  };
}
