import { store } from "new/redux/store";
import { uniqBy, prop, slice, compose } from "ramda";
import { backdoorForLegacy } from "new/redux/reducers/history";

const queueFilter = compose(slice(0, 5), uniqBy(prop("routeParam")));

HistoryService.$inject = [
  "$localStorage",
  "RouteHandler",
  "AuthenticationService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").service("HistoryService", HistoryService);

/* @ngInject */
function HistoryService($localStorage, RouteHandler, AuthenticationService) {
  var HistoryService = {};
  $localStorage.HistoryService = $localStorage.HistoryService || {};

  var subscribers = [];

  HistoryService.hitInstitution = function hitInstitution(routeParam, label) {
    $localStorage.HistoryService[
      AuthenticationService.getUserId()
    ].recentInstitutions = queueFilter([
      { routeParam, label },
      ...$localStorage.HistoryService[AuthenticationService.getUserId()]
        .recentInstitutions
    ]);

    notifySubscribers();
  };

  HistoryService.hitUser = function hitUser(routeParam, label) {
    $localStorage.HistoryService[
      AuthenticationService.getUserId()
    ].recentUsers = queueFilter([
      { routeParam, label },
      ...$localStorage.HistoryService[AuthenticationService.getUserId()]
        .recentUsers
    ]);

    notifySubscribers();
  };

  HistoryService.hitCloud = function hitCloud(routeParam, label) {
    $localStorage.HistoryService[
      AuthenticationService.getUserId()
    ].recentClouds = queueFilter([
      { routeParam, label },
      ...$localStorage.HistoryService[AuthenticationService.getUserId()]
        .recentClouds
    ]);

    notifySubscribers();
  };

  function notifySubscribers() {
    subscribers.forEach(function(s) {
      s(HistoryService.getAllRecents());
    });
    store.dispatch(backdoorForLegacy(HistoryService.getAllRecents()));
  }

  HistoryService.getRecentInstitutions = function getRecentInstitutions() {
    $localStorage.HistoryService[AuthenticationService.getUserId()] =
      $localStorage.HistoryService[AuthenticationService.getUserId()] || {};
    $localStorage.HistoryService[
      AuthenticationService.getUserId()
    ].recentInstitutions =
      $localStorage.HistoryService[AuthenticationService.getUserId()]
        .recentInstitutions || [];
    return $localStorage.HistoryService[AuthenticationService.getUserId()]
      .recentInstitutions;
  };

  HistoryService.subscribe = function subscribe(subscriber) {
    subscribers.push(subscriber);
  };

  HistoryService.getRecentUsers = function getRecentUsers() {
    $localStorage.HistoryService[AuthenticationService.getUserId()] =
      $localStorage.HistoryService[AuthenticationService.getUserId()] || {};
    $localStorage.HistoryService[
      AuthenticationService.getUserId()
    ].recentUsers =
      $localStorage.HistoryService[AuthenticationService.getUserId()]
        .recentUsers || [];
    return $localStorage.HistoryService[AuthenticationService.getUserId()]
      .recentUsers;
  };

  HistoryService.getRecentClouds = function getRecentClouds() {
    $localStorage.HistoryService[AuthenticationService.getUserId()] =
      $localStorage.HistoryService[AuthenticationService.getUserId()] || {};
    $localStorage.HistoryService[
      AuthenticationService.getUserId()
    ].recentClouds =
      $localStorage.HistoryService[AuthenticationService.getUserId()]
        .recentClouds || [];
    return $localStorage.HistoryService[AuthenticationService.getUserId()]
      .recentClouds;
  };

  HistoryService.getAllRecents = () => ({
    recentClouds: HistoryService.getRecentClouds() || [],
    recentUsers: HistoryService.getRecentUsers() || [],
    recentInstitutions: HistoryService.getRecentInstitutions() || []
  });

  return HistoryService;
}
