import angular from "angular";

import { store } from "new/redux/store";
import { login } from "new/redux/reducers/session";
import {
  v14,
  setAccessToken,
  getAccessToken,
  getRefreshToken,
  setRefreshToken,
  isJwtValid,
} from "@readcloud/api-client/build/client";
import { assertAuthentication } from "@readcloud/api-client/build/v2/auth";
AuthenticationService.$inject = [
  "$rootScope",
  "$state",
  "$log",
  "ApiService",
  "Analytics",
  "$localStorage",
  "$sessionStorage",
  "$q",
  "UtilitiesService",
  "_",
  "NotificationService",
  "USER_ROLE_DEFAULT_ROUTES",
  "USER_ROLES",
  "AUTH_EVENTS",
];

angular
  .module("readCloudWebConsole")
  .service("AuthenticationService", AuthenticationService);

/* @ngInject */
function AuthenticationService(
  $rootScope,
  $state,
  $log,
  ApiService,
  Analytics,
  $localStorage,
  $sessionStorage,
  $q,
  UtilitiesService,
  _,
  NotificationService,
  USER_ROLE_DEFAULT_ROUTES,
  USER_ROLES,
  AUTH_EVENTS
) {
  var AuthenticationService = {};

  var clientStorageLocation = $localStorage;

  clientStorageLocation.auth = clientStorageLocation.auth || {};

  AuthenticationService.auth = clientStorageLocation.auth;

  //Setup event functions
  AuthenticationService.successfulLogin = function(e, user) {
    Analytics.set("userId", user.email);
  };

  AuthenticationService.accessTokenOverride = async function(accessToken) {
    try {
      AuthenticationService.receivedJWT(null, accessToken);
      await AuthenticationService.updateCurrentUserData();
      AuthenticationService.removeRefreshToken();
    } catch (e) {
      AuthenticationService.invalidAccessToken();
      throw e;
    }
  };

  AuthenticationService.removeRefreshToken = function() {
    clientStorageLocation.auth.refresh_token = null;
  };

  AuthenticationService.receivedJWT = function(e, access_token) {
    clientStorageLocation.auth.access_token = access_token;
    clientStorageLocation.auth.payload = angular.fromJson(
      atob(access_token.split(".")[1])
    );
    setAccessToken(access_token);
    AuthenticationService.updateCurrentUserData();
    Analytics.set("&uid", clientStorageLocation.auth.payload.email);
  };

  AuthenticationService.getAccessToken = () =>
    clientStorageLocation.auth.access_token || getAccessToken();

  AuthenticationService.getRefreshToken = () =>
    clientStorageLocation.auth.refresh_token || getRefreshToken();

  AuthenticationService.incorrectUsernamePassword = function() {
    NotificationService.failed(
      "Invalid username or password. Please try again."
    );
  };

  AuthenticationService.receivedRefreshToken = function(e, refresh_token) {
    clientStorageLocation.auth.refresh_token = refresh_token;
    setRefreshToken(refresh_token);
  };

  AuthenticationService.renewingJWT = function(/*e*/) {
    NotificationService.info("Renewing JWT");
  };

  AuthenticationService.invalidRefreshToken = function() {
    $state.go("login").then(function() {
      NotificationService.failed(
        "Your session has expired, please login again."
      );
    });
  };
  AuthenticationService.invalidAccessToken = function() {
    $state.go("login").then(function() {
      NotificationService.failed("Invalid Session");
    });
  };

  AuthenticationService.corsError = function(e, rejection) {
    NotificationService.failed(
      "There was a problem accessing the server. See console"
    );
    NotificationService.error(rejection);
  };

  AuthenticationService.unknownAuthenticationError = function(e, reason) {
    NotificationService.failed(
      "An unknown error occured! Please contact ReadCloud or see console for details"
    );
    NotificationService.error(reason);
  };

  AuthenticationService.unauthorizedRequest = function(e, reason) {
    NotificationService.failed(
      "An authorization error occured. Please contact ReadCloud or see console for details"
    );
    NotificationService.error(reason);
  };

  AuthenticationService.testRolesAgainstCurrent = function(roles) {
    return (
      (roles || []).filter(function(role) {
        return new RegExp(role).test(
          USER_ROLES[AuthenticationService.getRole()]
        );
      }).length > 0
    );
  };

  AuthenticationService.cantAccess = function cantAccess(stateName) {
    return !(
      USER_ROLES[AuthenticationService.getRole()] &&
      $state.get(stateName).data.auth.roles.filter(function(role) {
        return new RegExp(role).test(
          USER_ROLES[AuthenticationService.getRole()]
        );
      }).length > 0
    );
  };

  AuthenticationService.stateFilter = function stateFilter(e, toState) {
    if (v14) {
      /********** Validate Token *******/
      var currentAccessToken = getAccessToken();
      //INVALID TOKEN example
      /*currentAccessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1ODAyNjk1ODMsImV4cCI6MTU4MDI3Njc4MywiZW1haWwiOiJkYXJyZW5AcmVhZGNsb3VkLmNvbSIsInJvbGUiOiJUZWFjaGVyIiwiaW5zdGl0dXRpb24iOiI1NThhOGE3Yzg3Mjk0MzFlNzhlZDc4ZmUiLCJ1c2VySWQiOiI1NTZkMzhlYWU0YjA5MjdmOWFlMThhMTAiLCJjbGllbnRJZCI6InJlYWRDbG91ZFdlYlJlYWRlciIsInVzZXJTY29wZSI6IiJ9.jPsurLL8gU-URo2CkxlgZIrSh8wzrSPJmvkvGyxc6HE";
    setAccessToken(currentAccessToken);
    /*console.log("AccessToken", currentAccessToken);*/
      console.log("isTokenValid", isJwtValid(currentAccessToken));

      if (
        currentAccessToken &&
        !isJwtValid(currentAccessToken) &&
        getRefreshToken()
      ) {
        v14.setApiv14Url("/api/v14"); // TODO put to another place
        v14.Auth.reAuthenticate(getRefreshToken())
          .then((res) => {
            //console.log("Set New Access Token", res.accessToken);
            setAccessToken(res.accessToken);
          })
          .catch((err) => {
            console.log(err);
            window.location.hash = "!/login";
          });

        console.log("NewAccess Token", getAccessToken());
      } else if (!isJwtValid(currentAccessToken)) {
        window.location.hash = "!/login";
      }
    }

    var deferred = $q.defer();

    toState.data.auth = toState.data.auth || {};
    if (typeof toState.data.auth.required == "undefined") {
      toState.data.auth.required = true;
    }

    //TODO: Handle user roles in this block
    if (toState.data.auth.required) {
      assertAuthentication()
        .catch((e) => {
          reject("login");
        })
        .then(() => {
          if (!AuthenticationService.cantAccess(toState.name))
            deferred.resolve();
          else reject(AuthenticationService.getDefaultRoute());
        });

      const accessToken = getAccessToken();
      if (accessToken) {
        console.log(accessToken);
      } else {
        window.location.hash = "!/login";
      }
      /*  if (clientStorageLocation.auth.access_token) {
        //If current user role exists in USER_ROLES and the toState allowed roles contains a matching role.
        //TODO: Put this logic in a function
       
        
      } else {
        reject("login");
      } */
    } else {
      deferred.resolve();
    }

    function reject(redirect) {
      deferred.reject({
        message: "You do not have rights to access " + toState.url,
        redirect: redirect,
      });
    }

    return deferred.promise;
  };

  AuthenticationService.updateCurrentUserData = async function() {
    return ApiService.Auth.me(clientStorageLocation.auth.payload.userId).then(
      function(result) {
        store.dispatch(
          login.fulfill({
            ...result.data,
            ...clientStorageLocation.auth.payload,
          })
        );
        return (clientStorageLocation.auth.user = result.data);
      }
    );
  };

  AuthenticationService.login = function(email, password) {
    return ApiService.Auth.loginJWT(email, password).then(function() {
      Analytics.set("&uid", email);
      return AuthenticationService.updateCurrentUserData();
    });
  };

  AuthenticationService.logout = function() {
    for (var i in AuthenticationService.auth) {
      delete AuthenticationService.auth[i];
    }
    // Delete unwanted Session Storage
    delete $sessionStorage.searchQuery;
    delete $sessionStorage.searchUserQuery;
    delete $sessionStorage.searchCloudQuery;

    $state.go("login");
  };

  AuthenticationService.renewJWT = function() {
    /* var d = $q.defer();
    d.resolve(
      ApiService.Auth.renewJWT(AuthenticationService.auth.refresh_token)
    );
    $rootScope.$emit(AUTH_EVENTS.renewingJWT);
    return d.promise; */
    return assertAuthentication().catch(() => {
      AuthenticationService.invalidRefreshToken();
    });
  };

  AuthenticationService.getRole = function() {
    if (
      clientStorageLocation.auth &&
      clientStorageLocation.auth.user &&
      clientStorageLocation.auth.user.role
    )
      return clientStorageLocation.auth.user.role;
    else return undefined;
  };

  AuthenticationService.getUserId = function() {
    if (
      clientStorageLocation.auth &&
      clientStorageLocation.auth.user &&
      clientStorageLocation.auth.user.id
    )
      return clientStorageLocation.auth.user.id;
    else return undefined;
  };

  AuthenticationService.getCurrentUser = function() {
    if (clientStorageLocation.auth && clientStorageLocation.auth.user)
      return clientStorageLocation.auth.user;
    else return undefined;
  };

  AuthenticationService.notLoggedIn = function() {
    $state.go("login");
  };

  AuthenticationService.getDefaultRoute = function() {
    return USER_ROLE_DEFAULT_ROUTES[AuthenticationService.getRole()] || "login";
  };

  return AuthenticationService;
}
