"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var index_1 = require("./index");

var cloudUpdatePath = "/reseller/update/cloud";

exports.resellerUpdateCloud = function (cloud, apiKey, config) {
  return index_1.getApiv14().post(cloudUpdatePath, cloud, tslib_1.__assign(tslib_1.__assign({}, config), {
    params: tslib_1.__assign(tslib_1.__assign({}, config ? config.params : {}), {
      apiKey: apiKey
    })
  }));
};

exports.resellerDeleteCloudBooks = function (name, institution, isbns, apiKey, config) {
  return index_1.getApiv14().post(cloudUpdatePath, {
    name: name,
    institution: institution,
    isbns: isbns,
    contentAction: "delete"
  }, tslib_1.__assign(tslib_1.__assign({}, config), {
    params: tslib_1.__assign(tslib_1.__assign({}, config ? config.params : {}), {
      apiKey: apiKey
    })
  }));
};

exports.resellerDeleteCloud = function (_a, apiKey, config) {
  var name = _a.name,
      institution = _a.institution;
  return index_1.getApiv14().post(cloudUpdatePath, {
    name: name,
    institution: institution,
    deleted: true
  }, tslib_1.__assign(tslib_1.__assign({}, config), {
    params: tslib_1.__assign(tslib_1.__assign({}, config ? config.params : {}), {
      apiKey: apiKey
    })
  }));
};

exports.addCloud = function (req, config) {
  return index_1.getApiv14().post("/add/cloud", req, config);
};

exports.updateCloud = function (cloudId, req, config) {
  return index_1.getApiv14().put("/update/cloud", req, tslib_1.__assign({
    params: {
      cloudId: cloudId
    }
  }, config));
};

exports.addMember = function (req, config) {
  return index_1.getApiv14().post("/add/membercloud", req, config);
};

exports.archiveCloud = function (cloudId, unarchive, config) {
  if (unarchive === void 0) {
    unarchive = false;
  }

  return index_1.getApiv14().post("/archive/cloud", null, tslib_1.__assign({
    params: {
      cloudId: cloudId,
      unarchive: unarchive
    }
  }, config));
};

exports.listClouds = function (userEmail, config) {
  return index_1.getApiv14().get("/list/cloud.json", tslib_1.__assign({
    params: {
      userEmail: userEmail
    }
  }, config));
};

exports.deleteBook = function (bookId, cloudId, config) {
  return index_1.getApiv14().delete("/delete/book", tslib_1.__assign({
    params: {
      bookId: bookId,
      cloudId: cloudId
    }
  }, config));
};

exports.deleteCloud = function (cloudId, config) {
  return index_1.getApiv14().delete("/delete/cloud", tslib_1.__assign({
    params: {
      cloudId: cloudId
    }
  }, config));
};

exports.removeMember = function (cloudID, userID, config) {
  return index_1.getApiv14().delete("/delete/membercloud", tslib_1.__assign({
    data: {
      cloudID: cloudID,
      userID: userID
    }
  }, config));
};

exports.deltaClouds = function (millisec, config) {
  return index_1.getApiv14().get("/delta/clouds", tslib_1.__assign({
    params: {
      millisec: millisec
    }
  }, config));
};

exports.searchCloud = function (institution, searchKey, searchString, config) {
  return index_1.getApiv14().get("/search/cloud", tslib_1.__assign({
    params: {
      institution: institution,
      searchKey: searchKey,
      searchString: searchString
    }
  }, config));
};