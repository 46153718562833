import React, { Fragment } from "react";
import Card, { CardText, CardHeader } from "material-ui/Card";
import glamorous, { Div, H4, Small } from "glamorous";
import { compose } from "recompose";
import moment from "moment";
import { defaultStyle } from "new/shared/hocs";
import { Padding } from "new/shared";
import { Flex } from "new/shared";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import CloudIcon from "material-ui/svg-icons/file/cloud";
import BookIcon from "material-ui/svg-icons/action/book";
import SchoolIcon from "material-ui/svg-icons/social/school";
import DownloadIcon from "material-ui/svg-icons/file/file-download";
import PrintIcon from "material-ui/svg-icons/action/print";
import SwipeableViews from "react-swipeable-views";
import { ViewTools } from "./ViewTools";
import ReactTable from "react-table";
import { Layout } from "./layout";
import Toggle from "material-ui/Toggle";
import { BookThumbnailCard } from "new/shared/BookThumbnailCard";

const styles = {
  annotationCardContainer: {
    width: "100%"
  },
  detailLineIcon: {
    flexShrink: 0
  }
};

const DetailLine = ({ icon, text, subtext, ...other }) => (
  <Flex alignItems="center" {...other} padding={16}>
    {icon}
    <Padding size={0.75} />
    {text}
    <small>{subtext}</small>
  </Flex>
);

const AnnotationTitle = glamorous.span({
  fontWeight: "bold",
  display: "flex",
  width: "100%",
  alignItems: "center",
  flexShrink: 1
});
const AnnotatedText = glamorous.div({
  borderLeft: "solid 3px #00a9a9",
  paddingLeft: 8,
  marginTop: 16,
  width: "100%"
});
const AnnotationCardText = compose(
  defaultStyle({ marginTop: 0, paddingTop: 0 })
)(CardText);

const AnnotationDate = glamorous.small({ color: "#999", fontWeight: "normal" });

const AnnotationCard = ({
  date,
  annotation,
  annotatedText,
  pageNumber,
  author,
  ...other
}) => (
  <Card style={styles.annotationCardContainer} {...other}>
    <CardHeader
      textStyle={{
        width: "100%",
        paddingRight: 0
      }}
      subtitle={<AnnotatedText>{annotatedText}</AnnotatedText>}
      title={
        <AnnotationTitle>
          {author}
          {author ? <Padding /> : null}
          <AnnotationDate>
            {moment(date).format("YYYY-MM-DD [at] hh:mm A")}
          </AnnotationDate>
          <Div flex={1} />
          <small>Page {pageNumber}</small>
        </AnnotationTitle>
      }
    />
    <AnnotationCardText>{annotation}</AnnotationCardText>
  </Card>
);

const tableColumns = [
  { Header: "Page", accessor: "pageID_num", width: 70 },
  {
    Header: "Date",
    id: "updated",
    width: 155,
    accessor: row => moment(row.updated).format("YYYY-MM-DD HH:mm")
  },
  { Header: "Annotated Text", accessor: "selectedText" },
  { Header: "Annotation", accessor: "text" }
];

const AnnotationsTable = ({ annotations, ...other }) => {
  console.log("AnnoTable", annotations);
  return <ReactTable data={annotations} columns={tableColumns} {...other} />;
};

export const AnnotationsExportPageComponent = ({
  printMode,
  setPrintMode,
  onRefresh,
  onPrint,
  bookThumbnailUrl,
  bookTitle,
  bookIsbn,
  cloudName,
  cloudInstitution,
  viewMode,
  annotations,
  saveAnnotations,
  privateOnly,
  personalOnly,
  limit,
  email
}) => {
  //Filtering out annotations to support only Text Annotations
  let filteredAnnotations = annotations;
  if (annotations) {
    filteredAnnotations = annotations.filter(anno => {
      if (anno.version == "2") {
        return anno;
      }
    });
  }
  return (
    <Layout
      width="100%"
      containerStyle={{ height: printMode ? "auto" : "100%" }}
      forceColumn={printMode}
      renderHeader={({ mobile }) => (
        <Fragment>
          <h2>Annotations Export</h2>
          <Padding />

          <small>{moment().format("YYYY-MM-DD HH:mm")}</small>

          <Flex flex />
          <Flex alignItems="center">
            <Toggle
              style={{ width: "auto" }}
              label="Print Preview"
              toggled={printMode}
              onToggle={() => setPrintMode(!printMode)}
            />
            {!printMode && (
              <Fragment>
                <IconButton tooltip="Refresh" onClick={onRefresh}>
                  <RefreshIcon />
                </IconButton>
                <IconButton tooltip="Export to CSV" onClick={saveAnnotations}>
                  <DownloadIcon />
                </IconButton>
              </Fragment>
            )}
            {printMode && (
              <IconButton tooltip="Print" onClick={onPrint}>
                <PrintIcon />
              </IconButton>
            )}
          </Flex>
        </Fragment>
      )}
      renderLeft={({ mobile }) => (
        <Flex
          column={!mobile && !printMode}
          justifyContent="flex-start"
          flexShrink={0}
          width="100%"
        >
          <Flex column justifyContent="center">
            <DetailLine
              icon={<CloudIcon style={styles.detailLineIcon} />}
              text={cloudName}
            />
            <DetailLine
              icon={<SchoolIcon style={styles.detailLineIcon} />}
              text={cloudInstitution}
            />
            <DetailLine
              icon={<BookIcon style={styles.detailLineIcon} />}
              text={
                <Flex column flexShrink={1}>
                  {bookTitle}
                  {bookIsbn ? <Small fontSize={12}>({bookIsbn})</Small> : null}
                </Flex>
              }
            />
          </Flex>

          {bookThumbnailUrl ? (
            <Flex column centerBoth>
              <BookThumbnailCard
                {...(mobile || printMode ? { height: 200 } : {})}
                style={{ margin: 16, alignSelf: "center" }}
                url={bookThumbnailUrl}
              />
            </Flex>
          ) : null}
        </Flex>
      )}
      renderRight={() => (
        <Flex column flex width="100%" marginLeft={8}>
          {!printMode && (
            <Fragment>
              <Flex flexShrink={0} flexWrap="wrap" alignItems="center">
                <Padding />
                <ViewTools
                  flex
                  annotations={filteredAnnotations}
                  onFilterChanged={onRefresh}
                />
              </Flex>
              <Flex
                width="100%"
                justifyContent="flex-end"
                alignItems="center"
                flexShrink={0}
              >
                <small>Total: {filteredAnnotations.length}</small>
                <Padding size={2} />
              </Flex>
            </Fragment>
          )}
          {printMode ? (
            <Flex column width="100%" centerBoth padding={16}>
              <H4 fontStyle="italic">{`Last ${limit}${
                privateOnly ? " private" : ""
              } annotations in ${cloudName} ${
                privateOnly || personalOnly ? `by ${email}` : ""
              }`}</H4>
              <small>Total: {filteredAnnotations.length}</small>
            </Flex>
          ) : null}
          <Padding />
          <SwipeableViews index={viewMode}>
            <Flex
              flex
              overflowY="overlay"
              column
              padding="0 16px"
              alignItems="flex-start"
              {...(viewMode === 0 ? {} : { height: 0 })}
            >
              {filteredAnnotations.map(
                ({
                  _id,
                  updated,
                  text,
                  selectedText,
                  author,
                  pageID_num,
                  location
                }) => (
                  <Fragment key={_id}>
                    <AnnotationCard
                      date={updated}
                      annotation={text}
                      annotatedText={selectedText}
                      pageNumber={
                        location
                          ? location.pageNumber || pageID_num
                          : pageID_num
                      }
                      author={
                        !privateOnly && !personalOnly
                          ? `${author.firstName} ${author.lastName}`
                          : null
                      }
                    />
                    <Padding size={0.5} />
                  </Fragment>
                )
              )}
            </Flex>
            <Flex
              flex
              width="100%"
              maxWidth="100%"
              overflowY="overlay"
              padding="0 16px"
            >
              <AnnotationsTable
                sortable={false}
                resizable={false}
                showPagination={false}
                style={{ flex: 1, width: "100%", maxWidth: "100%" }}
                pageSize={filteredAnnotations.length}
                annotations={filteredAnnotations}
              />
            </Flex>
          </SwipeableViews>
          {printMode ? (
            <Flex centerBoth padding={32}>
              {" "}
              Produced from ReadCloud
            </Flex>
          ) : null}
        </Flex>
      )}
    />
  );
};
