import React from "react";
import { Dialog, Divider, IconButton } from "material-ui";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import { Flex } from "new/shared";
import { Padding } from "new/shared";
import { compose, setPropTypes } from "recompose";

import { func, object, string } from "prop-types";

export const BasicDialogComponent = ({
  render,
  renderProps,
  onRequestClose,
  title,
  ...props
}) => (
  <Dialog onRequestClose={onRequestClose} {...props}>
    <Flex column>
      <Flex>
        {title ? <h3>{title}</h3> : null}
        <Flex flex />
        <IconButton onClick={onRequestClose}>
          <CloseIcon />
        </IconButton>
      </Flex>
      <Padding />
      <Divider />
      <Padding />
      {render && render({ onRequestClose, ...renderProps })}
    </Flex>
  </Dialog>
);

export const BasicDialog = compose(
  setPropTypes({
    render: func.isRequired,
    renderProps: object,
    title: string
  })
)(BasicDialogComponent);
