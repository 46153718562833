/**
 * Created by Daniel on 4/5/2016.
 */

RCUserDetails.$inject = ["$state", "$mdDialog", "DialogService"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserDetailsListItem", {
  controller: RCUserDetails,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-user-details-list-item/rc-user-details-list-item.html").then(m => m.default),
  bindings: {
    data: "=",
    selectionMode: "=",
    selectedChanged: "="
  }
});

/* @ngInject*/
function RCUserDetails($state, $mdDialog, DialogService) {
  var vm = this;

  vm.gotoClouds = function() {
    $mdDialog.hide();
    $state.go("users", {
      id: vm.data.id
    });
  };

  vm.gotoDetails = function() {
    DialogService.UserDetails(vm.data.id);
  };
}
