import { composeReducer, createAsyncFactory, scopeReducers } from "recrux";
import { combineEpics } from "redux-observable";
import { timer } from "rxjs";
import { debounce, map } from "rxjs/operators";
const namespace = "logs";
const defaultState = {
  options: {
    page: 0,
    pageSize: 20,
    filtered: [],
    expanded: [],
    sorted: [
      {
        id: "date",
        desc: true
      }
    ]
  }
};

export const select = state => state[namespace];

export const updateOptions = createAsyncFactory({
  namespace,
  actionName: "updateOptions",
  requestReducer: state => state,
  fulfillReducer: (state, { payload }) => ({
    ...state,
    options: { ...state.options, ...payload }
  })
});

export const logsReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    updateOptions.reducer
  )
});

export const logsEpic = combineEpics(action$ =>
  action$
    .ofType(updateOptions.requestType)
    .pipe(
      debounce(
        ({ payload: { filtered } }) => (filtered ? timer(100) : timer(0))
      ),
      map(({ payload }) => updateOptions.fulfill(payload))
    )
);
