/**
 * Created by danie on 6/15/2016.
 */
const _ = require("lodash");

RCUserCreatorController.$inject = [
  "UserService",
  "InstitutionService",
  "UtilitiesService",
  "$element"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserCreator", {
  controller: RCUserCreatorController,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-user-creator/rc-user-creator.html").then(m => m.default),
  bindings: {
    onSuccess: "<",
    cancel: "=?",
    data: "<"
  }
});

/* @ngInject */
function RCUserCreatorController(UserService, InstitutionService, UtilitiesService, $element) {
  var vm = this;

  vm.fields = UserService.getCreatorFields();

  vm.submit = function(data) {
    if (!data) return;
    return UtilitiesService.inlineLoader(

      InstitutionService.getByName(data.institution).then(function(result) {
        _.assign(data, {institutions: [result[0].id]});
        var data1 = data
        UserService.register(data1).then(function() {
          if (vm.onSuccess) vm.onSuccess(data1);
        })
      }),
      $element
    );
  };
}
