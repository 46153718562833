/**
 * Created by danie on 5/2/2016.
 */

RCUserEditor.$inject = [
  "_",
  "$scope",
  "UserService",
  "UtilitiesService",
  "$element"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserEditor", {
  controller: RCUserEditor,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-user-editor/template.html").then(m => m.default),
  bindings: {
    data: "=",
    onUpdated: "="
  }
});

/* @ngInject */

function RCUserEditor(_, $scope, UserService, UtilitiesService, $element) {
  var vm = this;

  vm.fields = [
    {
      label: "First Name",
      id: "firstName"
    },
    {
      label: "Last Name",
      id: "lastName"
    },
    {
      label: "Year Level",
      id: "yearLevel"
    },
    {
      label: "Parent Email",
      id: "parentEmail"
    }
  ];

  $scope.$watch("vm.data", function() {
    if (vm.data) {
      vm.output = _.reduce(
        vm.fields,
        function(result, value) {
          result[value.id] = vm.data[value.id];
          return result;
        },
        {}
      );
    }
  });

  vm.submit = function(data) {
    UtilitiesService.inlineLoader(
      UserService.updateUser(vm.data.id, data).then(function(result) {
        if (vm.onUpdated) vm.onUpdated(result.data);
      }),
      $element
    );
  };

  /*function updateUser(userId, data) {


      }*/

  /*function changeUserPassword(userId, password, passwordConfirm) {




      }*/
}
