RCBookListController.$inject = [
  "$scope",
  "_",
  "DialogService",
  "SelectedBookListService",
  "NotificationService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcBookList", {
  controller: RCBookListController,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-book-list/template.html").then(m => m.default),
  bindings: {
    selected: "=",
    onSelectedChanged: "&",
    verifyDelete: "<"
  }
});

/*@ngInject*/
function RCBookListController(
  $scope,
  _,
  DialogService,
  SelectedBookListService,
  NotificationService
) {
  var vm = this;

  vm.addBook = function() {
    DialogService.BookSelection().then(function(books) {
      vm.selected = _.union(vm.selected, _.map(books, "isbn"));
      SelectedBookListService.current = vm.selected;
      vm.onSelectedChanged();
    });
  };

  vm.removeBook = function(obj) {
    function execute() {
      vm.selected = vm.selected.filter(function(b) {
        return b !== obj;
      });
      $scope.selected = vm.selected;
      SelectedBookListService.current = vm.selected;
      vm.onSelectedChanged();
    }

    if (vm.verifyDelete) {
      NotificationService.areYouSure(
        "Remove Book",
        "Are you sure you want to remove this from the list?"
      ).then(execute);
    } else {
      execute();
    }
  };
}
