/**
 * Created by Daniel on 6/22/2016.
 */
RCResellerSelector.$inject = ["ResellerService", "UtilitiesService"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcResellerSelector", {
  controller: RCResellerSelector,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-reseller-selector/template.html").then(m => m.default),
  bindings: {
    selected: "=?"
  }
});

/*@ngInject*/
function RCResellerSelector(ResellerService, UtilitiesService) {
  var vm = this;

  vm.get = ResellerService.get.bind(null);

  vm.get().then(function(result) {
    if (result.data) {
      vm.resellers = result.data;
      vm.selected = vm.resellers[0].id;
    }
  });
}
