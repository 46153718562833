/**
 * Created by danie on 4/21/2016.
 */
import angular from "angular";
RCUserSelectionModal.$inject = ["$mdDialog"];

angular
  .module("readCloudWebConsole")
  .controller("RCUserSelectionModalController", RCUserSelectionModal);

/* @ngInject */
function RCUserSelectionModal($mdDialog) {
  var vm = this;

  if (vm.multiple === undefined) {
    vm.multiple = true;
  }

  vm.selected = [];

  vm.cancel = function() {
    $mdDialog.cancel();
  };

  vm.ok = function() {
    $mdDialog.hide(vm.selected);
  };
}
