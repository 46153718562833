"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var jwtUtils_1 = require("@readcloud/common/build/lib/jwtUtils");

var client_1 = require("../client");

var utils_1 = require("../utils");

var index_1 = require("./index");

exports.getToken = function (body) {
  return index_1.getNewapi().post("/token", body);
};

var handleAuthResponse = function handleAuthResponse(response) {
  if ("error" in response.data) {
    throw new Error(response.data.error.message);
  }

  var _a = response.data,
      access_token = _a.access_token,
      refresh_token = _a.refresh_token;
  client_1.setRefreshToken(refresh_token);
  client_1.setAccessToken(access_token);
  return {
    accessToken: access_token,
    refreshToken: refresh_token,
    decoded: jwtUtils_1.decodeJwt(access_token).payload
  };
};

exports.authenticate = function (email, password) {
  var clientId = client_1.getClientId();

  if (clientId) {
    return exports.getToken({
      clientId: clientId,
      grant_type: "password",
      email: email,
      password: password
    }).then(handleAuthResponse);
  } else {
    throw new Error("Client id is not defined. You must set this before authenticating");
  }
};

exports.reAuthenticate = function (refreshToken) {
  var clientId = client_1.getClientId();

  if (clientId) {
    return exports.getToken({
      clientId: clientId,
      grant_type: "refresh_token",
      refresh_token: refreshToken
    }).then(handleAuthResponse);
  } else {
    throw new Error("Client id is not defined. You must set this before authenticating");
  }
};

exports.assertAuthentication = function () {
  return tslib_1.__awaiter(void 0, void 0, void 0, function () {
    var accessToken, refreshToken;
    return tslib_1.__generator(this, function (_a) {
      accessToken = client_1.getAccessToken();
      refreshToken = client_1.getRefreshToken();

      if (!accessToken) {
        throw new Error("Unauthorized - No access token found");
      }

      if (utils_1.isJwtValid(accessToken)) {
        return [2
        /*return*/
        ];
      }

      if (!refreshToken) {
        throw new Error("Unauthorized - Invalid or expired access token, and no refresh token found in order to attempt reauth");
      }

      return [2
      /*return*/
      , exports.reAuthenticate(refreshToken)];
    });
  });
};

exports.isAuthenticated = function () {
  return !!client_1.getAccessToken();
};