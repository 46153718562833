"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.addStatistics = function (req) {
  return index_1.getApiv14().post("/add/statistics", req);
};