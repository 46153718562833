/**
 * Created by danie on 29/03/2016.
 */
import { Clouds } from "@readcloud/api-client/build/v2";
RCCloud.$inject = [
  "$mdDialog",
  "$location",
  "$element",
  "ExportService",
  "NotificationService",
  "$scope",
  "$templateCache",
  "DialogService",
  "CloudService",
  "_",
  "RoleHandler",
  "AuthenticationService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcCloud", {
  controller: RCCloud,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-cloud/rc-cloud.html").then(m => m.default),
  bindings: {
    data: "=",
    isAdmin: "<",
    showGotoDetails: "<",
    onRefresh: "<"
  }
});

/* @ngInject */

function RCCloud(
  $mdDialog,
  $location,
  $element,
  ExportService,
  NotificationService,
  $scope,
  $templateCache,
  DialogService,
  CloudService,
  _,
  RoleHandler,
  AuthenticationService
) {
  var vm = this;
  $scope.$templateCache = $templateCache;

  vm.cloudBooks = [];

  $scope.$watch(
    function() {
      return vm.data;
    },
    function(newVal) {
      if (newVal) {
        vm.cloudBooks = newVal._books || newVal.books;
      }
    }
  );

  vm.getUserRole = AuthenticationService.getRole;

  vm.openMenu = function($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  };

  vm.showBookDetails = function(book) {
    DialogService.BookDetails(book);
  };

  vm.isIdSelected = function(bookId) {
    if ($location.hash() === bookId) return true;
    else return false;
  };

  vm.getId = function() {
    return $element[0].id;
  };

  vm.refreshIfPossible = () =>
    vm.onRefresh
      ? vm.onRefresh()
      : Promise.reject(
          new Error("Refresh function was not passed into the cloud component")
        );

  vm.deleteBook = ({ id, title }) => {
    var BookId = id;
    var CloudId = vm.data.id;
    NotificationService.areYouSure(
      "Delete Book",
      'Are you sure you want to remove "' +
        title +
        '" from "' +
        vm.data.name +
        '" ?'
    )
      .then(() => Clouds.removeBooks(CloudId, { bookId: BookId }))
      .then(() => vm.refreshIfPossible());
  }
  /*  vm.confirmDeleteBookFromCloud = function(book) {
    NotificationService.areYouSure(
      "Delete Book",
      'Are you sure you want to remove "' +
        book.title +
        '" from "' +
        vm.data.name +
        '" ?'
    ).then(function() {
      vm.deleteBookFromCloud(book._id, vm.data._id);
    });
  };

  vm.deleteBookFromCloud = function(bookId, cloudId) {
    CloudService.removeBookFromCloud(bookId, cloudId)
      .then(function() {
        _.remove(vm.cloudBooks, { id: bookId });
        NotificationService.success("Successfully Removed");
      })
      .catch(function(reason) {
        NotificationService.failed(
          "Could not delete book from cloud!" +
            (reason.data && reason.data.error && reason.data.error.message
              ? " Message: " + reason.data.error.message
              : "")
        );
        NotificationService.error(reason);
      });
  }; */

  vm.modifyBook = function(/*book*/) {};

  vm.canAddBooks = function() {
    return (
      (vm.data && !vm.data.addBookAdminOnly) ||
      RoleHandler.hasAdminAccessToCloud(vm.data)
    );
  };

  vm.showAddTileDialog = function() {
    $mdDialog.show({
      bindToController: true,
      templateUrl: () =>
        import("app/components/rc-cloud/add-book-dialog/add-book-dialog.html").then(m => m.default),
      controller: "AddBookDialog"
    });
  };

  vm.exportMembers = function(book) {
    if (!book.isbn) {
      book.isbn = "";
    }
    var members = vm.data._members;
    if (members.length === 0) {
      NotificationService.success("No members to export");
      return;
    }
    var export_data = {};
    export_data = members.map(function(d) {
      var e = {};
      e.email = d.email;
      e.isbn = book.isbn;
      e.purchaseDate = "2017-01-01";
      e.priceIncGst = "0.00";
      e.currencyCode = "AUD";
      e.bookExpireDate = "";
      e.multiOrder = "false";
      return e;
    });
    ExportService.exportArrayToXLSX(export_data);
  };

  vm.bookOptions = [
    {
      title: "Details",
      function: vm.showBookDetails
    }
  ];

  if (vm.isAdmin) {
    vm.bookOptions.push({
      title: "Delete from Cloud",
      function: vm.confirmDeleteBookFromCloud
    });
  }

  if (vm.isAdmin) {
    vm.bookOptions.push({
      title: "Export Members",
      function: vm.exportMembers
    });
  }
}
