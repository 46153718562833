/**
 * Created by danie on 12/01/2016.
 */
import { assertAuthentication } from "@readcloud/api-client/build/v2/auth";
ApiService.$inject = [
  "HttpService",
  "$q",
  "CONFIG",
  "_",
  "ERROR_CODES",
  "$localStorage",
  "$rootScope",
  "AUTH_EVENTS",
  "assert"
];
const angular = require("angular");
angular.module("readCloudWebConsole").service("ApiService", ApiService);

/* @ngInject */
function ApiService(
  HttpService,
  $q,
  CONFIG,
  _,
  ERROR_CODES,
  $localStorage,
  $rootScope,
  AUTH_EVENTS,
  assert
) {
  var baseUrl = "";
  var restApiRoot = CONFIG.restApiRoot || "/api";
  var apiUrl = baseUrl + restApiRoot;
  var defaultLimit = 50;
  var defaultSkip = 0;

  var clientId = "readCloudWebConsole";
  var ApiService = {};

  function stringifyQuery(query) {
    if (!query) return "";

    var output = "?";
    if (query.order) {
      output += "filter[order]=" + query.order + "&";
    }

    output += "filter=" + encodeURIComponent(angular.toJson(query));

    return output;
  }

  function defaultGet(base, query) {
    query = query || {};
    query.skip = query.skip || defaultSkip;
    query.limit = query.limit || defaultLimit;
    return HttpService.get(
      base.replace(/\/$/, "") +
        "?filter=" +
        encodeURIComponent(angular.toJson(query))
    );
  }

  function defaultCount(base, query) {
    query = query || {};
    query.skip = 0;
    delete query.limit;
    var where = query.where || {};
    return HttpService.get(
      base.replace(/\/$/, "") +
        "?where=" +
        encodeURIComponent(angular.toJson(where))
    );
  }

  var Users = (function() {
    var PathToModel = "/DBUsers/";
    var Base = apiUrl + PathToModel;

    return {
      get: function(query) {
        query = query || {};
        query.skip = query.skip || defaultSkip;
        query.limit = query.limit || defaultLimit;

        return HttpService.get(
          Base.substr(0, Base.length - 1) +
            "?filter=" +
            encodeURIComponent(angular.toJson(query))
        );
      },
      _delete: function(userId) {
        return HttpService.post(Base + userId + "/delete");
      },
      enable: function(userId) {
        return HttpService.post(Base + userId + "/enable");
      },
      getById: function(id) {
        if (!id) return;
        return HttpService.get(Base + id);
      },
      getByEmail: function(email) {
        // console.log("users.getByEmail: ", email);
        return this.get({
          where: {
            email: email,
            deleted: {
              inq: [true, false]
            }
          }
        });
      },
      count: function(query) {
        query = query || {};
        if (query.where) {
          query = query.where;
        }
        return HttpService.get(
          Base + "count?where=" + encodeURIComponent(angular.toJson(query))
        ).then(function(result) {
          return result.data.count;
        });
      },
      getClouds: function(id) {
        if (!id) return;
        return HttpService.get(Base + id + "/_clouds");
      },
      getUserIncludingRelations: function(userId) {
        if (!userId) return $q.reject();
        return HttpService.get(
          Base +
            userId +
            "?filter=" +
            encodeURIComponent(
              angular.toJson({ include: [{ _clouds: ["_books"] }] })
            )
        );
      },
      me: function() {
        return HttpService.get(Base + "me");
      },
      getByIdWithIncludes: function(id) {
        return HttpService.get(Base + id + "/withIncludes");
      },
      getDevices: function(id) {
        return HttpService.get(Base + id + "/_devices");
      },
      deleteAllDevices: function(id) {
        return HttpService.delete(Base + id + "/_devices");
      },
      getRefreshTokens: function(id) {
        return HttpService.get(Base + id + "/_refreshTokens");
      },
      deleteAllRefreshTokens: function(id) {
        return HttpService.delete(Base + id + "/_refreshTokens");
      },
      deleteRefreshTokenByExp: function(userId, exp) {
        if (exp instanceof Date) exp = exp.toISOString();
        var where = {};
        where.exp = exp;
        return HttpService.delete(
          Base +
            userId +
            "/_refreshTokens?filter=" +
            encodeURIComponent(angular.toJson(where))
        );
      },
      update: function(userId, data) {
        return HttpService.put(Base + userId, data);
      },
      toggleDeleted: function(userId) {
        return HttpService.post(Base + userId + "/toggleDeleted");
      },
      changePassword: function(userId, password, passwordConfirm) {
        return HttpService.post(Base + userId + "/changePassword", {
          password: password,
          confirm: passwordConfirm
        });
      },
      getTransactions: function(id, query) {
        return HttpService.get(
          Base +
            id +
            "/_transactions?filter=" +
            encodeURIComponent(angular.toJson(query))
        );
      },
      getTransactionsCount: function(id) {
        return HttpService.get(Base + id + "/_transactions/count");
      },
      registerStudent: function(data) {
        return HttpService.post(Base + "registerStudent", data);
      },
      registerTeacher: function(data) {
        return HttpService.post(Base + "registerTeacher", data);
      },
      registerPublic: function(data) {
        return HttpService.post(Base + "registerPublic", data);
      },
      getActivityLogs: function(id, query) {
        return HttpService.get(
          Base +
            id +
            "/_activity?filter=" +
            encodeURIComponent(angular.toJson(query))
        );
      },
      getActivityLogsCount: function(id) {
        return HttpService.get(Base + id + "/_activity/count");
      },
      getAllRoles: function() {
        return HttpService.get(Base + "getAllRoles");
      },

      cloudsView: function(userId) {
        return HttpService.get(Base + userId + "/cloudsView");
      }
    };
  })();

  var Clouds = (function() {
    var PathToModel = "/Clouds/";
    var Base = apiUrl + PathToModel;

    return {
      get: function(query) {
        query = query || {};
        query.skip = query.skip || defaultSkip;
        query.limit = query.limit || defaultLimit;
        return HttpService.get(
          Base.substr(0, Base.length - 1) + "?filter=" + angular.toJson(query)
        );
      },
      getById: function(id, filter) {
        return HttpService.get(
          Base + id + (filter ? "?filter=" + angular.toJson(filter) : "")
        );
      },
      getCloudMembers: function(id) {
        return HttpService.get(Base + id + "/_members");
      },
      reportById: function(id) {
        assert(id);
        return HttpService.get(Base + id + "/report");
      },
      removeBookFromCloud: function(bookId, cloudId) {
        return HttpService.delete(Base + cloudId + "/_books/rel/" + bookId);
      },
      count: function(query) {
        query = query || {};
        if (query.where) {
          query = query.where;
        }
        return HttpService.get(
          Base + "count?where=" + angular.toJson(query)
        ).then(function(result) {
          return result.data.count;
        });
      },
      update: function(id, data) {
        return HttpService.put(Base + id, data);
      },
      enable: function(id) {
        return HttpService.post(Base + id + "/enable");
      },
      disable: function(id) {
        return HttpService.post(Base + id + "/disable");
      }
    };
  })();

  var Auth = {
    loginNK: function(email, password) {
      var deferred = $q.defer();

      HttpService.get("https://api.readcloud.com/api/v12/loginNK", {
        params: { email: email, password: password }
      })
        .then(function(result) {
          if (result.data.error) deferred.reject(result.data.error);

          deferred.resolve(result);
        })
        .catch(function(reason) {
          if (reason.data && reason.data.result && reason.data.result.error)
            reason = reason.data.result.error;

          deferred.reject(reason);
        });

      return deferred.promise;
    },
    loginJWT: function(email, password) {
      return HttpService.post(baseUrl + "/token", {
        email: email,
        password: password,
        clientId: clientId,
        grant_type: "password"
      });
    },
    me: function(id) {
      return HttpService.get(baseUrl + "/api/DBUsers/" + id);
    },
    renewJWT: function(refresh_token) {
      return HttpService.post(baseUrl + "/token", {
        grant_type: "refresh_token",
        refresh_token: refresh_token,
        clientId: clientId
      });
    }
  };

  var Util = {
    getStatus: function() {
      return HttpService.get(apiUrl);
    }
  };

  var Transactions = (function() {
    var PathToModel = "/Transactions/";
    var Base = apiUrl + PathToModel;
    return {
      deleteById: function(id) {
        return HttpService.delete(Base + id);
      },
      repurchase: function(id) {
        return HttpService.post(Base + id + "/repurchase");
      },
      publisherReport: function(startDate, endDate, publisher) {
        return HttpService.post(Base + "publisherReport", {
          startDate: startDate,
          endDate: endDate,
          publisher: publisher
        });
      }
    };
  })();

  var Institutions = (function() {
    var PathToModel = "/Institutions/";
    var Base = apiUrl + PathToModel;

    return {
      getById: function(id) {
        if (!id) return;
        return HttpService.get(apiUrl + "/Institutions/" + id);
      },
      get: function(query) {
        query = query || {};
        //var temp = apiUrl + "/Institutions" + stringifyQuery(query);
        //var temp1 = defaultCount(Base + 'count', query);

        return HttpService.get(
          apiUrl + "/Institutions?filter=" + angular.toJson(query)
        );
      },
      getByName: function(name) {
        // console.log("institutions.getByName: ", name);
        return this.get({
          where: {
            name: name,
            deleted: {
              inq: [true, false]
            }
          }
        });
      },
      updateOne: function(id, data) {
        return HttpService.put(apiUrl + "/Institutions/" + id, data);
      },
      count: function(query) {
        return defaultCount(Base + "count", query);
      },
      create: function(data) {
        return HttpService.post(apiUrl + "/Institutions/", data);
      },
      registerInstitution: function(data) {
        return HttpService.post(Base + "registerInstitution", data);
      },
      createDemoClouds: function(id, emailList, includeDemoUsers) {
        return HttpService.post(
          apiUrl + "/Institutions/" + id + "/createDemoClouds",
          {
            emailList: emailList,
            includeDemoUsers: includeDemoUsers
          }
        );
      }
    };
  })();

  var Resellers = (function() {
    var PathToModel = "/Resellers/";
    var Base = apiUrl + PathToModel;

    return {
      get: function(query) {
        return defaultGet(Base, query);
      },
      getById: function(id) {
        return HttpService.get(Base + id);
      },
      getAppConfig: function(id, osName) {
        return HttpService.get(Base + id + "/_apps/" + osName);
      },
      updateAppConfig: function(resellerId, osName, data) {
        return HttpService.put(Base + resellerId + "/_apps/" + osName, data);
      },
      getMappedOrders: function(id, query) {
        return HttpService.get(
          Base + id + "/mappedOrders/?filter=" + angular.toJson(query)
        );
      },
      addMappedOrder: function(id, data) {
        return HttpService.post(Base + id + "/mappedOrders", data);
      },
      updateMappedOrder: function(id, name, data) {
        return HttpService.put(Base + id + "/mappedOrders/" + name, data);
      },
      deleteMappedOrder: function(id, name) {
        return HttpService.delete(Base + id + "/mappedOrders/" + name);
      }
    };
  })();

  var Applications = (function() {
    var PathToModel = "/ClientApplications/";
    var Base = apiUrl + PathToModel;

    return {
      getById: function(id) {
        return HttpService.get(Base + id);
      },

      getList: function(filter) {
        return defaultGet(
          Base,
          _.assign(filter, {
            fields: ["id", "name"]
          })
        );
      },
      deleteBuild: function(id, buildId) {
        return HttpService.delete(Base + id + "/_builds/" + buildId);
      },
      getBuilds: function(applicationId) {
        return HttpService.get(Base + applicationId + "/_builds");
      },
      addBuild: function(applicationId, data) {
        return HttpService.post(Base + applicationId + "/_builds", data);
      },
      updateBuild: function(applicationId, buildId, data) {
        return HttpService.put(
          Base + applicationId + "/_builds/" + buildId,
          data
        );
      }
    };
  })();

  var Connectivity = (function() {
    var Base = baseUrl;
    return {
      check: function() {
        return HttpService.get(Base);
      }
    };
  })();

  var Books = (function() {
    var Base = apiUrl + "/Books/";
    var DRMBase = apiUrl + "/DRMBooks/";
    return {
      search: function(query) {
        return defaultGet(Base + "search/", query);
      },
      ebookSearch: function(text) {
        return HttpService.get(DRMBase + "search/" + text);
      },
      count: function(query) {
        return HttpService.get(Base + "count?filter=" + stringifyQuery(query));
      },
      find: query => defaultGet(Base, query)
    };
  })();

  var Onix = (function() {
    var Base = apiUrl + "/OnixGeneratorSeeds/";
    return {
      generate: function(data) {
        return HttpService.post(Base + "generate", data);
      }
    };
  })();

  var Actions = (function() {
    var Base = apiUrl + "/Actions/";
    return {
      getEntities: function() {
        return HttpService.get(Base + "getEntities");
      },
      get: function(query) {
        return defaultGet(Base, query);
      },
      create: function(data) {
        return HttpService.post(Base, data);
      },
      update: function(id, data) {
        return HttpService.put(Base + id, data);
      },
      deleteAction: function(id) {
        return HttpService.post(Base + id + "/disable");
      },
      getTypes: function() {
        return HttpService.get(Base + "getTypes");
      }
    };
  })();

  ApiService = {
    Users: Users,
    Clouds: Clouds,
    Institutions: Institutions,
    Auth: Auth,
    Util: Util,
    Resellers: Resellers,
    Transactions: Transactions,
    Connectivity: Connectivity,
    Applications: Applications,
    Books: Books,
    Onix: Onix,
    Actions: Actions
  };

  for (var a in ApiService) {
    for (var c in ApiService[a]) {
      if (_.isFunction(ApiService[a][c])) {
        ApiService[a][c] = (function(_super) {
          //TokenExpired Interceptor

          return function() {
            var _arguments = arguments;
            var _this = this;

            var d = $q.defer();

            _super.apply(this, arguments).then(d.resolve, function(reason) {
              if (
                reason &&
                reason.data &&
                reason.data.error &&
                reason.data.error.code
              ) {
                //TODO: Clean this up. Perhaps use a switch statement to emit the correct events
                if (reason.data.error.code === ERROR_CODES.TokenExpired) {
                  assertAuthentication()
                    .then(() => {
                      _super
                        .apply(_this, _arguments)
                        .then(d.resolve, d.reject, d.notify);
                    })
                    .catch(e => {
                      $rootScope.$emit(AUTH_EVENTS.invalidRefreshToken);
                      d.reject(e);
                    });
                  /* ApiService.Auth.renewJWT(auth.refresh_token).then(function() {
                    //This will call the method that was originally intended to be called, but now after renewJWT, we will have a new access_token.
                    //Authentication service will pick this up automatically because of the HTTP interceptors written.
                    //Interceptors detect data.access_token or data.refresh_token, then emit the correct rootScopt events. See index.config.js

                    $rootScope.$emit(AUTH_EVENTS.renewingJWT);
                    _super
                      .apply(_this, _arguments)
                      .then(d.resolve, d.reject, d.notify);
                  }, d.reject); */
                } else if (
                  reason.data.error.code === ERROR_CODES.InvalidRefreshToken
                ) {
                  $rootScope.$emit(AUTH_EVENTS.invalidRefreshToken);
                  d.reject(reason);
                } else if (
                  reason.data.error.code === ERROR_CODES.InvalidUsernamePassword
                ) {
                  $rootScope.$emit(AUTH_EVENTS.incorrectUsernamePassword);
                  d.reject(reason);
                } else if (
                  reason.data.error.code === ERROR_CODES.AuthorizationRequired
                ) {
                  $rootScope.$emit(AUTH_EVENTS.unauthorizedRequest);
                  d.reject(reason);
                } else {
                  //$rootScope.$emit(AUTH_EVENTS.unknownAuthenticationError, reason);
                  d.reject(reason);
                }
              } else {
                d.reject(reason);
              }
            });

            return d.promise;
          };
        })(ApiService[a][c]);
      }
    }
  }

  return ApiService;
}
