RCMappedOrderCreatorModalCtrl.$inject = ["$mdDialog"];
const angular = require("angular");
angular
  .module("readCloudWebConsole")
  .controller("RCMappedOrderCreatorModalCtrl", RCMappedOrderCreatorModalCtrl);

/* @ngInject */

function RCMappedOrderCreatorModalCtrl($mdDialog) {
  var vm = this;
  vm.close = $mdDialog.hide;
}
