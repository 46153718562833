"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var index_1 = require("./index");

var FormData = typeof window !== "undefined" && window.FormData || // tslint:disable-next-line:no-var-requires
require("form-data");

exports.usernameValidation = function (email, config) {
  return index_1.getApiv14().get("/username_validation", tslib_1.__assign({
    params: {
      email: email
    }
  }, config));
};

exports.getConfig = function (userEmail, config) {
  return index_1.getApiv14().get("/config", tslib_1.__assign({
    params: {
      userEmail: userEmail
    }
  }, config));
};

exports.myActions = function (userEmail, config) {
  return index_1.getApiv14().get("/my_actions", tslib_1.__assign({
    params: {
      userEmail: userEmail
    }
  }, config));
};

exports.login = function (loginRequest, config) {
  return index_1.getApiv14().post("/login", loginRequest, config);
};

exports.serviceNotifications = function (config) {
  return index_1.getApiv14().get("/service_notifications", config);
};

exports.deltaAll = function (millisec, userEmail, upToVersion, config) {
  if (millisec === void 0) {
    millisec = 0;
  }

  if (userEmail === void 0) {
    userEmail = "";
  }

  return index_1.getApiv14().get("/delta/all", tslib_1.__assign({
    params: {
      millisec: millisec,
      userEmail: userEmail,
      upToVersion: upToVersion
    }
  }, config));
};

exports.getAll = function (opts, config) {
  return index_1.getApiv14().post("/get/all", opts, tslib_1.__assign({}, config));
};

exports.ltiLaunch = function (bookId, config) {
  return index_1.getApiv14().get("/lti_launch", tslib_1.__assign({
    params: {
      bookId: bookId
    }
  }, config));
};

exports.ltiLaunchData = function (bookId, config) {
  return index_1.getApiv14().get("/lti_launch_data", tslib_1.__assign({
    params: {
      bookId: bookId
    }
  }, config));
};

exports.resetPasswordGen = function (email, config) {
  return index_1.getApiv14().get("/resetpasswordgen", tslib_1.__assign({
    params: {
      email: email
    }
  }, config));
};

exports.resetPassword = function (email, tmpPwd, newPwd, config) {
  /*getApiv14().post("/resetpassword", {
    params: { email, tmpPwd, newPwd },
    ...config
  });*/
  return index_1.getApiv14().get("/resetpassword", tslib_1.__assign({
    params: {
      email: email,
      tmpPwd: tmpPwd,
      newPwd: newPwd
    }
  }, config));
};

exports.setPassword = function (req, config) {
  return index_1.getApiv14().post("/setpassword", req, config);
};

exports.uploadFile = function (_a, config) {
  var file = _a.file,
      fileType = _a.fileType,
      fileExtension = _a.fileExtension,
      fileName = _a.fileName,
      fileSubType = _a.fileSubType,
      params = _a.params;
  var form = new FormData();
  form.append("file", file, {
    filename: fileName
  });
  form.append("fileType", fileType);

  if (fileExtension) {
    form.append("fileExtension", fileExtension);
  }

  if (fileSubType) {
    form.append("fileSubType", fileSubType);
  }

  if (fileName) {
    form.append("fileName", fileName);
  }

  return index_1.getApiv14().post("/upload_file", form, tslib_1.__assign({
    params: params,
    headers: tslib_1.__assign(tslib_1.__assign({}, form.getHeaders()), config ? config.headers : {})
  }, config));
};

exports.getApiKeys = function () {
  return index_1.getApiv14().get("/get_api_keys");
};