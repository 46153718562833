"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.createInstitution = function (institution) {
  return index_1.getNewapi().post("/api/Institutions", institution);
};

exports.getInstitutions = function (query) {
  return index_1.getNewapi().get("/api/Institutions", {
    params: {
      filter: query
    }
  });
};

exports.updateInstitution = function (id, institution) {
  return index_1.getNewapi().patch("/api/Institutions/" + id, institution);
};

exports.getStats = function (institutionId) {
  return index_1.getNewapi().get("/api/Institutions/" + institutionId + "/stats");
};