import { composeReducer, scopeReducers, createFactory } from "recrux";
import { makeApiCallAction } from "new/redux/reducers/shared";
import { combineEpics } from "redux-observable";
import { getAnnotations } from "@readcloud/api-client/build/v2/users";
import {
  setNewapiUrl
} from "@readcloud/api-client/build/v2";

import { createSelector } from "reselect";
import _ from "lodash";
import { map, mergeMap } from "rxjs/operators";

const namespace = "annotationsExport";
const defaultState = {
  data: {},
  error: null,
  loading: false,
  viewMode: 0, // 0 = list, 1 = table
  sortBy: "updated",
  filter: {
    privateOnly: false,
    personalOnly: false,
    limit: 20
  }
};
export const getData = makeApiCallAction(namespace, "getData");
export const update = createFactory({
  namespace,
  actionName: "update",
  reducer: (state, { payload }) => {
    return { ...state, ...payload };
  }
});
export const annotationExportReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    getData.reducer,
    update.reducer
  )
});
export const annotationExportEpic = combineEpics((action$, store) =>
  action$.ofType(getData.type).pipe(
    map(({ payload }) => ({
      ...select(store.getState()).filter,
      ...payload
    })),
    mergeMap(({ limit, cloudId, bookId, privateOnly, personalOnly }) => {

      // PJW: Fix for annotation export.
      // TODO: handle this properly

      setNewapiUrl("/");

      return getAnnotations(bookId, cloudId, limit, privateOnly, personalOnly)
        .then(r => r.data)
        .then(({ annotations, bookDetails, cloudDetails }) => ({
          annotations,
          ...bookDetails,
          cloudDetails
        }))
        .then(getData.fulfill)
        .catch(getData.error)
      }
    )
  )
);

export const select = state => state[namespace];
export const selectSortedAnnotations = createSelector(
  select,
  ({ data, sortBy }) =>
    _.orderBy((data && data.annotations) || [], sortBy, "desc")
);
