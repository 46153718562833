/**
 * Created by danie on 4/18/2016.
 */

RCToolbarUserMenu.$inject = ["UserActions", "AuthenticationService"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcToolbarUserMenu", {
  controller: RCToolbarUserMenu,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-toolbar/rc-toolbar-user-menu/rc-toolbar-user-menu.html").then(m => m.default)
});

/* @ngInject */
function RCToolbarUserMenu(UserActions, AuthenticationService) {
  var vm = this;
  vm.openProfileMenu = function(menu, e) {
    menu(e);
  };

  vm.email = AuthenticationService.getCurrentUser()
    ? AuthenticationService.getCurrentUser().email
    : null;

  vm.logout = UserActions.logout;

  vm.menuItems = [
    {
      label: "My Clouds",
      func: UserActions.myProfile
    },
    {
      label: "Logout",
      func: UserActions.logout
    }
  ];
}
