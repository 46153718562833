/**
 * Created by danie on 4/03/2016.
 */
import angular from "angular";
RouteHandler.$inject = [
  "AuthenticationService",
  "$state",
  "$q",
  "NotificationService",
  "$location",
  "_",
  "$stateParams",
  "ApiService",
  "$interval",
  "CONFIG",
  "$document",
  "Analytics"
];

angular.module("readCloudWebConsole").service("RouteHandler", RouteHandler);

/* @ngInject */
function RouteHandler(
  AuthenticationService,
  $state,
  $q,
  NotificationService,
  $location,
  _,
  $stateParams,
  ApiService,
  $interval,
  CONFIG,
  $document,
  Analytics
) {
  var RouteHandler = {};

  function updatePageTitle() {
    $document[0].title = CONFIG.title + " | " + $state.current.data.title;
  }

  /**
   *  A map of state names to array of functions (subscribers)
   * @type {Object.<string, Array.function>}
   */
  var subscribers = {};

  function loginViaUrl(e, toState, toParams) {
    if (toParams.accessToken) {
      return $q.resolve(
        AuthenticationService.accessTokenOverride(toParams.accessToken)
      );
    } else return $q.resolve();
  }

  function test(query, name) {
    var pat = new RegExp(query);

    return pat.test(name);
  }

  function rejectRouteChange(e /*,toState*/) {
    return function(reason) {
      if (reason.message) NotificationService.info(reason.message);

      if (reason.redirect) {
        e.preventDefault();
        $state.go(reason.redirect, { returnUrl: $location.url() });
      }
    };
  }

  /**
   * Test a subscriber query with the name of the current state
   * @param subscriberQuery
   */
  function subscriberTest(subscriberQuery) {
    var name = $state.current.name;

    return test(subscriberQuery, name);
  }

  function subscriberTestCB(name) {
    return function(query) {
      return test(query, name);
    };
  }

  var middlewareStack = [loginViaUrl, AuthenticationService.stateFilter];

  RouteHandler.stateChangeStart = function(
    e,
    toState,
    toParams,
    fromState,
    fromParams
  ) {
    //Ensure data object is set.
    toState.data = toState.data || {};
    fromState.data = fromState.data || {};
    toParams = toParams || {};
    fromParams = fromParams || {};

    var promise = $q.resolve({});
    middlewareStack.forEach(function(middleware) {
      promise = promise.then(function() {
        return middleware(e, toState, toParams, fromState, fromParams);
      });
    });
    promise.catch(rejectRouteChange(e, toState, toParams));
  };

  //TODO: Pattern match route subscriptions
  RouteHandler.stateChangeSuccess = function(
    e,
    toState,
    toParams /*, toParams, fromState, fromParams*/
  ) {
    var currentUser = AuthenticationService.getCurrentUser();
    currentUser && Analytics.set("userId", currentUser.email);
    Analytics.trackPage(
      $state.href(toState.name, toParams),
      toState.data && toState.data.title
    );
    updatePageTitle();

    var filteredSubscribers = _.filter(
      _.keys(subscribers),
      subscriberTestCB(toState.name)
    );

    filteredSubscribers.forEach(function(_sub) {
      subscribers[_sub].forEach(function(sub) {
        sub(toParams);
      });
    });
  };

  RouteHandler.stateNotFound = function(/*e, unfoundState, fromState, fromParams*/) {};

  /**
   * Subscribe a function to when a state is accessed
   * @param query
   * @param subscriberFunction
   */
  RouteHandler.subscribe = function(query, subscriberFunction) {
    if (!(query && subscriberFunction)) return;

    if (!subscribers[query]) {
      subscribers[query] = [subscriberFunction];
    } else {
      subscribers[query].push(subscriberFunction);
    }

    if (subscriberTest(query)) subscriberFunction($stateParams);
  };

  /**
   * Use the authentication service to determine the default route for the currently logged in user, then
   * go to that route.
   */
  RouteHandler.gotoDefault = function gotoDefault() {
    $state.go(AuthenticationService.getDefaultRoute());
  };

  return RouteHandler;
}
