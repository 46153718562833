/**
 * Created by danie on 4/4/2016.
 */

const angular = require("angular");
angular.module("readCloudWebConsole").component("rcBookThumbnail", {
  controller: RCBookThumbnail,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-cloud/rc-book/rc-book-thumbnail/rc-book-thumbnail.html").then(m => m.default),
  bindings: {
    url: "@",
    title: "@"
  },
  transclude: {
    menu: "?mdMenu",
    overlay: "?div"
  }
});

/* @ngInject */

function RCBookThumbnail() {}
