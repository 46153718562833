import React from "react";
import glamorous, { Div } from "glamorous";
import { Padding } from "new/shared";
import { Flex } from "new/shared";
import MediaQuery from "react-responsive";

import { VerticalDivider, Divider } from "new/shared";
import pt from "prop-types";
const ContentContainer = glamorous(Flex)({
  height: "100%",
  width: "100%",
  flex: 1
});

const LeftContainer = glamorous(Flex)({
  flexShrink: 0,
  flexBasis: 250
});
const RightContainer = glamorous(Flex)({
  flex: 1,
  width: "100%",
  height: "100%"
});

export const Layout = ({
  renderHeader,
  renderLeft,
  renderRight,
  leftStyle,
  rightStyle,
  containerStyle,
  forceColumn,
  ...other
}) => (
  <Div {...other}>
    <MediaQuery maxWidth={768}>
      {matches => (
        <Flex column width="100%" height="100%" {...containerStyle}>
          <Flex
            alignItems="center"
            width="100%"
            height={80}
            padding={16}
            flexShrink={0}
          >
            {renderHeader && renderHeader({ mobile: matches })}
          </Flex>
          <Divider />
          <Padding />

          <ContentContainer column={matches || forceColumn}>
            <LeftContainer style={leftStyle}>
              {renderLeft && renderLeft({ mobile: matches })}
            </LeftContainer>
            {!matches ? (
              <VerticalDivider />
            ) : (
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            )}
            <RightContainer style={rightStyle}>
              {renderRight && renderRight({ mobile: matches })}
            </RightContainer>
          </ContentContainer>
        </Flex>
      )}
    </MediaQuery>
  </Div>
);

Layout.propTypes = {
  renderHeader: pt.func.isRequired,
  renderLeft: pt.func.isRequired,
  renderRight: pt.func.isRequired
};
