RCBookSelectionModalController.$inject = ["$mdDialog"];
const angular = require("angular");
angular
  .module("readCloudWebConsole")
  .controller("RCBookSelectionModalController", RCBookSelectionModalController);

/*@ngInject*/
function RCBookSelectionModalController($mdDialog) {
  var vm = this;

  vm.selected = [];

  vm.done = function() {
    $mdDialog.hide(vm.selected);
  };

  vm.close = $mdDialog.cancel;
}
