import { createStore, applyMiddleware, compose } from "redux";

import { createEpicMiddleware } from "redux-observable";

import { persistStore } from "redux-persist";

import { rootEpic } from "new/redux/rootEpic";
import { rootReducer } from "new/redux/reducers";
import { bootstrap } from "new/redux/reducers/persist";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(createEpicMiddleware(rootEpic)))
  );

  const persistor = persistStore(store);
  return { store, persistor };
};

export const storeAndPersistor = configureStore();

export const store = storeAndPersistor.store;
export const persistor = storeAndPersistor.persistor;
persistor.subscribe(() => {
  if (persistor.getState().bootstrapped) {
    store.dispatch(bootstrap());
  }
});
