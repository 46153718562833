import { sessionReducer } from "./session";
import { historyReducer } from "./history";
import { configReducer } from "./config";
import { reducer as formReducer } from "redux-form";
import { scopeReducers, composeReducer } from "recrux";
import { institutionAdminReducer } from "new/InstitutionAdmin/reducer";
import { cloudAdminReducer } from "new/CloudAdmin/reducer";
import { sidebarReducer } from "new/Sidebar/reducer";
import { snackbarReducer } from "new/Snackbar/reducer";
import { persistReducer } from "new/redux/reducers/persist";
import { annotationExportReducer } from "new/Reports/AnnotationExport/reducer";
import { bookSearchReducer } from "new/Books/Search/reducer";
import { logsReducer } from "../../Logs/reducer";

const defaultState = {};

export const rootReducer = composeReducer(
  (state = defaultState) => state,
  scopeReducers({ form: formReducer }),
  sessionReducer,
  historyReducer,
  configReducer,
  institutionAdminReducer,
  cloudAdminReducer,
  sidebarReducer,
  snackbarReducer,
  persistReducer,
  annotationExportReducer,
  bookSearchReducer,
  logsReducer
);
