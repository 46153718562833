import angular from "angular";
import moduleName from "./moduleName";
import uirouter from "angular-ui-router";
import angularScroll from "angular-scroll";
import ngAnimte from "angular-animate";
import ngAria from "angular-aria";
import ngSanitize from "angular-sanitize";
import ngFileSaver from "angular-file-saver";
import ngMaterial from "angular-material";
import ngMessages from "angular-messages";
import { reactAngularModule } from "react-angular";
import "ngreact";
require("ngstorage");
require("angular-google-analytics");
let app = () => {
  return {
    template: require("./app.html"),
    controller: "AppCtrl",
    controllerAs: "app"
  };
};

class AppCtrl {
  constructor() {
    this.url = "https://github.com/preboot/angular-webpack";
  }
}

angular
  .module(moduleName, [
    uirouter,
    angularScroll,
    ngAnimte,
    ngAria,
    "ngStorage",
    ngSanitize,
    ngFileSaver,
    ngMaterial,
    require("angular-material-data-table"),
    "angular-google-analytics",
    ngMessages,
    "react",
    reactAngularModule(true).name
  ])
  .directive("app", app)
  .controller("AppCtrl", AppCtrl);

require("./allImports");
