ActionService.$inject = ["$q", "ApiService"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("ActionService", ActionService);

function ActionService($q, ApiService) {
  var ActionService = {};

  ActionService.getAll = function() {
    return ApiService.Actions.get().then(function(result) {
      return result.data;
    });
  };

  ActionService.create = function(data) {
    return ApiService.Actions.create(data).then(function(result) {
      return result.data;
    });
  };

  ActionService.update = function(id, data) {
    return ApiService.Actions.update(id, data);
  };

  ActionService.getEntities = function() {
    return ApiService.Actions.getEntities().then(function(result) {
      return result.data;
    });
  };

  ActionService.deleteAction = function(id) {
    return ApiService.Actions.deleteAction(id);
  };

  ActionService.getTypes = ApiService.Actions.getTypes;

  return ActionService;
}
