"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var omittedKeys = ["src"];
var styleKeysMap = {
  alignContent: "alignContent",
  alignItems: "alignItems",
  alignSelf: "alignSelf",
  alignmentBaseline: "alignmentBaseline",
  all: "all",
  animation: "animation",
  animationDelay: "animationDelay",
  animationDirection: "animationDirection",
  animationDuration: "animationDuration",
  animationFillMode: "animationFillMode",
  animationIterationCount: "animationIterationCount",
  animationName: "animationName",
  animationPlayState: "animationPlayState",
  animationTimingFunction: "animationTimingFunction",
  backfaceVisibility: "backfaceVisibility",
  background: "background",
  backgroundAttachment: "backgroundAttachment",
  backgroundBlendMode: "backgroundBlendMode",
  backgroundClip: "backgroundClip",
  backgroundColor: "backgroundColor",
  backgroundImage: "backgroundImage",
  backgroundOrigin: "backgroundOrigin",
  backgroundPosition: "backgroundPosition",
  backgroundPositionX: "backgroundPositionX",
  backgroundPositionY: "backgroundPositionY",
  backgroundRepeat: "backgroundRepeat",
  backgroundRepeatX: "backgroundRepeatX",
  backgroundRepeatY: "backgroundRepeatY",
  backgroundSize: "backgroundSize",
  baselineShift: "baselineShift",
  blockSize: "blockSize",
  border: "border",
  borderBlockEnd: "borderBlockEnd",
  borderBlockEndColor: "borderBlockEndColor",
  borderBlockEndStyle: "borderBlockEndStyle",
  borderBlockEndWidth: "borderBlockEndWidth",
  borderBlockStart: "borderBlockStart",
  borderBlockStartColor: "borderBlockStartColor",
  borderBlockStartStyle: "borderBlockStartStyle",
  borderBlockStartWidth: "borderBlockStartWidth",
  borderBottom: "borderBottom",
  borderBottomColor: "borderBottomColor",
  borderBottomLeftRadius: "borderBottomLeftRadius",
  borderBottomRightRadius: "borderBottomRightRadius",
  borderBottomStyle: "borderBottomStyle",
  borderBottomWidth: "borderBottomWidth",
  borderCollapse: "borderCollapse",
  borderColor: "borderColor",
  borderImage: "borderImage",
  borderImageOutset: "borderImageOutset",
  borderImageRepeat: "borderImageRepeat",
  borderImageSlice: "borderImageSlice",
  borderImageSource: "borderImageSource",
  borderImageWidth: "borderImageWidth",
  borderInlineEnd: "borderInlineEnd",
  borderInlineEndColor: "borderInlineEndColor",
  borderInlineEndStyle: "borderInlineEndStyle",
  borderInlineEndWidth: "borderInlineEndWidth",
  borderInlineStart: "borderInlineStart",
  borderInlineStartColor: "borderInlineStartColor",
  borderInlineStartStyle: "borderInlineStartStyle",
  borderInlineStartWidth: "borderInlineStartWidth",
  borderLeft: "borderLeft",
  borderLeftColor: "borderLeftColor",
  borderLeftStyle: "borderLeftStyle",
  borderLeftWidth: "borderLeftWidth",
  borderRadius: "borderRadius",
  borderRight: "borderRight",
  borderRightColor: "borderRightColor",
  borderRightStyle: "borderRightStyle",
  borderRightWidth: "borderRightWidth",
  borderSpacing: "borderSpacing",
  borderStyle: "borderStyle",
  borderTop: "borderTop",
  borderTopColor: "borderTopColor",
  borderTopLeftRadius: "borderTopLeftRadius",
  borderTopRightRadius: "borderTopRightRadius",
  borderTopStyle: "borderTopStyle",
  borderTopWidth: "borderTopWidth",
  borderWidth: "borderWidth",
  bottom: "bottom",
  boxShadow: "boxShadow",
  boxSizing: "boxSizing",
  breakAfter: "breakAfter",
  breakBefore: "breakBefore",
  breakInside: "breakInside",
  bufferedRendering: "bufferedRendering",
  captionSide: "captionSide",
  caretColor: "caretColor",
  clear: "clear",
  clip: "clip",
  clipPath: "clipPath",
  clipRule: "clipRule",
  color: "color",
  colorInterpolation: "colorInterpolation",
  colorInterpolationFilters: "colorInterpolationFilters",
  colorRendering: "colorRendering",
  columnCount: "columnCount",
  columnFill: "columnFill",
  columnGap: "columnGap",
  columnRule: "columnRule",
  columnRuleColor: "columnRuleColor",
  columnRuleStyle: "columnRuleStyle",
  columnRuleWidth: "columnRuleWidth",
  columnSpan: "columnSpan",
  columnWidth: "columnWidth",
  columns: "columns",
  contain: "contain",
  content: "content",
  counterIncrement: "counterIncrement",
  counterReset: "counterReset",
  cursor: "cursor",
  cx: "cx",
  cy: "cy",
  d: "d",
  direction: "direction",
  display: "display",
  dominantBaseline: "dominantBaseline",
  emptyCells: "emptyCells",
  fill: "fill",
  fillOpacity: "fillOpacity",
  fillRule: "fillRule",
  filter: "filter",
  flex: "flex",
  flexBasis: "flexBasis",
  flexDirection: "flexDirection",
  flexFlow: "flexFlow",
  flexGrow: "flexGrow",
  flexShrink: "flexShrink",
  flexWrap: "flexWrap",
  float: "float",
  floodColor: "floodColor",
  floodOpacity: "floodOpacity",
  font: "font",
  fontDisplay: "fontDisplay",
  fontFamily: "fontFamily",
  fontFeatureSettings: "fontFeatureSettings",
  fontKerning: "fontKerning",
  fontSize: "fontSize",
  fontStretch: "fontStretch",
  fontStyle: "fontStyle",
  fontVariant: "fontVariant",
  fontVariantCaps: "fontVariantCaps",
  fontVariantEastAsian: "fontVariantEastAsian",
  fontVariantLigatures: "fontVariantLigatures",
  fontVariantNumeric: "fontVariantNumeric",
  fontVariationSettings: "fontVariationSettings",
  fontWeight: "fontWeight",
  gap: "gap",
  grid: "grid",
  gridArea: "gridArea",
  gridAutoColumns: "gridAutoColumns",
  gridAutoFlow: "gridAutoFlow",
  gridAutoRows: "gridAutoRows",
  gridColumn: "gridColumn",
  gridColumnEnd: "gridColumnEnd",
  gridColumnGap: "gridColumnGap",
  gridColumnStart: "gridColumnStart",
  gridGap: "gridGap",
  gridRow: "gridRow",
  gridRowEnd: "gridRowEnd",
  gridRowGap: "gridRowGap",
  gridRowStart: "gridRowStart",
  gridTemplate: "gridTemplate",
  gridTemplateAreas: "gridTemplateAreas",
  gridTemplateColumns: "gridTemplateColumns",
  gridTemplateRows: "gridTemplateRows",
  height: "height",
  hyphens: "hyphens",
  imageRendering: "imageRendering",
  inlineSize: "inlineSize",
  isolation: "isolation",
  justifyContent: "justifyContent",
  justifyItems: "justifyItems",
  justifySelf: "justifySelf",
  left: "left",
  letterSpacing: "letterSpacing",
  lightingColor: "lightingColor",
  lineBreak: "lineBreak",
  lineHeight: "lineHeight",
  listStyle: "listStyle",
  listStyleImage: "listStyleImage",
  listStylePosition: "listStylePosition",
  listStyleType: "listStyleType",
  margin: "margin",
  marginBlockEnd: "marginBlockEnd",
  marginBlockStart: "marginBlockStart",
  marginBottom: "marginBottom",
  marginInlineEnd: "marginInlineEnd",
  marginInlineStart: "marginInlineStart",
  marginLeft: "marginLeft",
  marginRight: "marginRight",
  marginTop: "marginTop",
  marker: "marker",
  markerEnd: "markerEnd",
  markerMid: "markerMid",
  markerStart: "markerStart",
  mask: "mask",
  maskType: "maskType",
  maxBlockSize: "maxBlockSize",
  maxHeight: "maxHeight",
  maxInlineSize: "maxInlineSize",
  maxWidth: "maxWidth",
  maxZoom: "maxZoom",
  minBlockSize: "minBlockSize",
  minHeight: "minHeight",
  minInlineSize: "minInlineSize",
  minWidth: "minWidth",
  minZoom: "minZoom",
  mixBlendMode: "mixBlendMode",
  objectFit: "objectFit",
  objectPosition: "objectPosition",
  offset: "offset",
  offsetDistance: "offsetDistance",
  offsetPath: "offsetPath",
  offsetRotate: "offsetRotate",
  opacity: "opacity",
  order: "order",
  orientation: "orientation",
  orphans: "orphans",
  outline: "outline",
  outlineColor: "outlineColor",
  outlineOffset: "outlineOffset",
  outlineStyle: "outlineStyle",
  outlineWidth: "outlineWidth",
  overflow: "overflow",
  overflowAnchor: "overflowAnchor",
  overflowWrap: "overflowWrap",
  overflowX: "overflowX",
  overflowY: "overflowY",
  overscrollBehavior: "overscrollBehavior",
  overscrollBehaviorX: "overscrollBehaviorX",
  overscrollBehaviorY: "overscrollBehaviorY",
  padding: "padding",
  paddingBlockEnd: "paddingBlockEnd",
  paddingBlockStart: "paddingBlockStart",
  paddingBottom: "paddingBottom",
  paddingInlineEnd: "paddingInlineEnd",
  paddingInlineStart: "paddingInlineStart",
  paddingLeft: "paddingLeft",
  paddingRight: "paddingRight",
  paddingTop: "paddingTop",
  page: "page",
  pageBreakAfter: "pageBreakAfter",
  pageBreakBefore: "pageBreakBefore",
  pageBreakInside: "pageBreakInside",
  paintOrder: "paintOrder",
  perspective: "perspective",
  perspectiveOrigin: "perspectiveOrigin",
  placeContent: "placeContent",
  placeItems: "placeItems",
  placeSelf: "placeSelf",
  pointerEvents: "pointerEvents",
  position: "position",
  quotes: "quotes",
  r: "r",
  resize: "resize",
  right: "right",
  rowGap: "rowGap",
  rx: "rx",
  ry: "ry",
  scrollBehavior: "scrollBehavior",
  scrollMargin: "scrollMargin",
  scrollMarginBlock: "scrollMarginBlock",
  scrollMarginBlockEnd: "scrollMarginBlockEnd",
  scrollMarginBlockStart: "scrollMarginBlockStart",
  scrollMarginBottom: "scrollMarginBottom",
  scrollMarginInline: "scrollMarginInline",
  scrollMarginInlineEnd: "scrollMarginInlineEnd",
  scrollMarginInlineStart: "scrollMarginInlineStart",
  scrollMarginLeft: "scrollMarginLeft",
  scrollMarginRight: "scrollMarginRight",
  scrollMarginTop: "scrollMarginTop",
  scrollPadding: "scrollPadding",
  scrollPaddingBlock: "scrollPaddingBlock",
  scrollPaddingBlockEnd: "scrollPaddingBlockEnd",
  scrollPaddingBlockStart: "scrollPaddingBlockStart",
  scrollPaddingBottom: "scrollPaddingBottom",
  scrollPaddingInline: "scrollPaddingInline",
  scrollPaddingInlineEnd: "scrollPaddingInlineEnd",
  scrollPaddingInlineStart: "scrollPaddingInlineStart",
  scrollPaddingLeft: "scrollPaddingLeft",
  scrollPaddingRight: "scrollPaddingRight",
  scrollPaddingTop: "scrollPaddingTop",
  scrollSnapAlign: "scrollSnapAlign",
  scrollSnapStop: "scrollSnapStop",
  scrollSnapType: "scrollSnapType",
  shapeImageThreshold: "shapeImageThreshold",
  shapeMargin: "shapeMargin",
  shapeOutside: "shapeOutside",
  shapeRendering: "shapeRendering",
  size: "size",
  speak: "speak",
  stopColor: "stopColor",
  stopOpacity: "stopOpacity",
  stroke: "stroke",
  strokeDasharray: "strokeDasharray",
  strokeDashoffset: "strokeDashoffset",
  strokeLinecap: "strokeLinecap",
  strokeLinejoin: "strokeLinejoin",
  strokeMiterlimit: "strokeMiterlimit",
  strokeOpacity: "strokeOpacity",
  strokeWidth: "strokeWidth",
  tabSize: "tabSize",
  tableLayout: "tableLayout",
  textAlign: "textAlign",
  textAlignLast: "textAlignLast",
  textAnchor: "textAnchor",
  textCombineUpright: "textCombineUpright",
  textDecoration: "textDecoration",
  textDecorationColor: "textDecorationColor",
  textDecorationLine: "textDecorationLine",
  textDecorationSkipInk: "textDecorationSkipInk",
  textDecorationStyle: "textDecorationStyle",
  textIndent: "textIndent",
  textOrientation: "textOrientation",
  textOverflow: "textOverflow",
  textRendering: "textRendering",
  textShadow: "textShadow",
  textSizeAdjust: "textSizeAdjust",
  textTransform: "textTransform",
  textUnderlinePosition: "textUnderlinePosition",
  top: "top",
  touchAction: "touchAction",
  transform: "transform",
  transformBox: "transformBox",
  transformOrigin: "transformOrigin",
  transformStyle: "transformStyle",
  transition: "transition",
  transitionDelay: "transitionDelay",
  transitionDuration: "transitionDuration",
  transitionProperty: "transitionProperty",
  transitionTimingFunction: "transitionTimingFunction",
  unicodeBidi: "unicodeBidi",
  unicodeRange: "unicodeRange",
  userSelect: "userSelect",
  userZoom: "userZoom",
  vectorEffect: "vectorEffect",
  verticalAlign: "verticalAlign",
  visibility: "visibility",
  webkitAlignContent: "webkitAlignContent",
  webkitAlignItems: "webkitAlignItems",
  webkitAlignSelf: "webkitAlignSelf",
  webkitAnimation: "webkitAnimation",
  webkitAnimationDelay: "webkitAnimationDelay",
  webkitAnimationDirection: "webkitAnimationDirection",
  webkitAnimationDuration: "webkitAnimationDuration",
  webkitAnimationFillMode: "webkitAnimationFillMode",
  webkitAnimationIterationCount: "webkitAnimationIterationCount",
  webkitAnimationName: "webkitAnimationName",
  webkitAnimationPlayState: "webkitAnimationPlayState",
  webkitAnimationTimingFunction: "webkitAnimationTimingFunction",
  webkitAppRegion: "webkitAppRegion",
  webkitAppearance: "webkitAppearance",
  webkitBackfaceVisibility: "webkitBackfaceVisibility",
  webkitBackgroundClip: "webkitBackgroundClip",
  webkitBackgroundOrigin: "webkitBackgroundOrigin",
  webkitBackgroundSize: "webkitBackgroundSize",
  webkitBorderAfter: "webkitBorderAfter",
  webkitBorderAfterColor: "webkitBorderAfterColor",
  webkitBorderAfterStyle: "webkitBorderAfterStyle",
  webkitBorderAfterWidth: "webkitBorderAfterWidth",
  webkitBorderBefore: "webkitBorderBefore",
  webkitBorderBeforeColor: "webkitBorderBeforeColor",
  webkitBorderBeforeStyle: "webkitBorderBeforeStyle",
  webkitBorderBeforeWidth: "webkitBorderBeforeWidth",
  webkitBorderBottomLeftRadius: "webkitBorderBottomLeftRadius",
  webkitBorderBottomRightRadius: "webkitBorderBottomRightRadius",
  webkitBorderEnd: "webkitBorderEnd",
  webkitBorderEndColor: "webkitBorderEndColor",
  webkitBorderEndStyle: "webkitBorderEndStyle",
  webkitBorderEndWidth: "webkitBorderEndWidth",
  webkitBorderHorizontalSpacing: "webkitBorderHorizontalSpacing",
  webkitBorderImage: "webkitBorderImage",
  webkitBorderRadius: "webkitBorderRadius",
  webkitBorderStart: "webkitBorderStart",
  webkitBorderStartColor: "webkitBorderStartColor",
  webkitBorderStartStyle: "webkitBorderStartStyle",
  webkitBorderStartWidth: "webkitBorderStartWidth",
  webkitBorderTopLeftRadius: "webkitBorderTopLeftRadius",
  webkitBorderTopRightRadius: "webkitBorderTopRightRadius",
  webkitBorderVerticalSpacing: "webkitBorderVerticalSpacing",
  webkitBoxAlign: "webkitBoxAlign",
  webkitBoxDecorationBreak: "webkitBoxDecorationBreak",
  webkitBoxDirection: "webkitBoxDirection",
  webkitBoxFlex: "webkitBoxFlex",
  webkitBoxOrdinalGroup: "webkitBoxOrdinalGroup",
  webkitBoxOrient: "webkitBoxOrient",
  webkitBoxPack: "webkitBoxPack",
  webkitBoxReflect: "webkitBoxReflect",
  webkitBoxShadow: "webkitBoxShadow",
  webkitBoxSizing: "webkitBoxSizing",
  webkitClipPath: "webkitClipPath",
  webkitColumnBreakAfter: "webkitColumnBreakAfter",
  webkitColumnBreakBefore: "webkitColumnBreakBefore",
  webkitColumnBreakInside: "webkitColumnBreakInside",
  webkitColumnCount: "webkitColumnCount",
  webkitColumnGap: "webkitColumnGap",
  webkitColumnRule: "webkitColumnRule",
  webkitColumnRuleColor: "webkitColumnRuleColor",
  webkitColumnRuleStyle: "webkitColumnRuleStyle",
  webkitColumnRuleWidth: "webkitColumnRuleWidth",
  webkitColumnSpan: "webkitColumnSpan",
  webkitColumnWidth: "webkitColumnWidth",
  webkitColumns: "webkitColumns",
  webkitFilter: "webkitFilter",
  webkitFlex: "webkitFlex",
  webkitFlexBasis: "webkitFlexBasis",
  webkitFlexDirection: "webkitFlexDirection",
  webkitFlexFlow: "webkitFlexFlow",
  webkitFlexGrow: "webkitFlexGrow",
  webkitFlexShrink: "webkitFlexShrink",
  webkitFlexWrap: "webkitFlexWrap",
  webkitFontFeatureSettings: "webkitFontFeatureSettings",
  webkitFontSizeDelta: "webkitFontSizeDelta",
  webkitFontSmoothing: "webkitFontSmoothing",
  webkitHighlight: "webkitHighlight",
  webkitHyphenateCharacter: "webkitHyphenateCharacter",
  webkitJustifyContent: "webkitJustifyContent",
  webkitLineBreak: "webkitLineBreak",
  webkitLineClamp: "webkitLineClamp",
  webkitLocale: "webkitLocale",
  webkitLogicalHeight: "webkitLogicalHeight",
  webkitLogicalWidth: "webkitLogicalWidth",
  webkitMarginAfter: "webkitMarginAfter",
  webkitMarginAfterCollapse: "webkitMarginAfterCollapse",
  webkitMarginBefore: "webkitMarginBefore",
  webkitMarginBeforeCollapse: "webkitMarginBeforeCollapse",
  webkitMarginBottomCollapse: "webkitMarginBottomCollapse",
  webkitMarginCollapse: "webkitMarginCollapse",
  webkitMarginEnd: "webkitMarginEnd",
  webkitMarginStart: "webkitMarginStart",
  webkitMarginTopCollapse: "webkitMarginTopCollapse",
  webkitMask: "webkitMask",
  webkitMaskBoxImage: "webkitMaskBoxImage",
  webkitMaskBoxImageOutset: "webkitMaskBoxImageOutset",
  webkitMaskBoxImageRepeat: "webkitMaskBoxImageRepeat",
  webkitMaskBoxImageSlice: "webkitMaskBoxImageSlice",
  webkitMaskBoxImageSource: "webkitMaskBoxImageSource",
  webkitMaskBoxImageWidth: "webkitMaskBoxImageWidth",
  webkitMaskClip: "webkitMaskClip",
  webkitMaskComposite: "webkitMaskComposite",
  webkitMaskImage: "webkitMaskImage",
  webkitMaskOrigin: "webkitMaskOrigin",
  webkitMaskPosition: "webkitMaskPosition",
  webkitMaskPositionX: "webkitMaskPositionX",
  webkitMaskPositionY: "webkitMaskPositionY",
  webkitMaskRepeat: "webkitMaskRepeat",
  webkitMaskRepeatX: "webkitMaskRepeatX",
  webkitMaskRepeatY: "webkitMaskRepeatY",
  webkitMaskSize: "webkitMaskSize",
  webkitMaxLogicalHeight: "webkitMaxLogicalHeight",
  webkitMaxLogicalWidth: "webkitMaxLogicalWidth",
  webkitMinLogicalHeight: "webkitMinLogicalHeight",
  webkitMinLogicalWidth: "webkitMinLogicalWidth",
  webkitOpacity: "webkitOpacity",
  webkitOrder: "webkitOrder",
  webkitPaddingAfter: "webkitPaddingAfter",
  webkitPaddingBefore: "webkitPaddingBefore",
  webkitPaddingEnd: "webkitPaddingEnd",
  webkitPaddingStart: "webkitPaddingStart",
  webkitPerspective: "webkitPerspective",
  webkitPerspectiveOrigin: "webkitPerspectiveOrigin",
  webkitPerspectiveOriginX: "webkitPerspectiveOriginX",
  webkitPerspectiveOriginY: "webkitPerspectiveOriginY",
  webkitPrintColorAdjust: "webkitPrintColorAdjust",
  webkitRtlOrdering: "webkitRtlOrdering",
  webkitRubyPosition: "webkitRubyPosition",
  webkitShapeImageThreshold: "webkitShapeImageThreshold",
  webkitShapeMargin: "webkitShapeMargin",
  webkitShapeOutside: "webkitShapeOutside",
  webkitTapHighlightColor: "webkitTapHighlightColor",
  webkitTextCombine: "webkitTextCombine",
  webkitTextDecorationsInEffect: "webkitTextDecorationsInEffect",
  webkitTextEmphasis: "webkitTextEmphasis",
  webkitTextEmphasisColor: "webkitTextEmphasisColor",
  webkitTextEmphasisPosition: "webkitTextEmphasisPosition",
  webkitTextEmphasisStyle: "webkitTextEmphasisStyle",
  webkitTextFillColor: "webkitTextFillColor",
  webkitTextOrientation: "webkitTextOrientation",
  webkitTextSecurity: "webkitTextSecurity",
  webkitTextSizeAdjust: "webkitTextSizeAdjust",
  webkitTextStroke: "webkitTextStroke",
  webkitTextStrokeColor: "webkitTextStrokeColor",
  webkitTextStrokeWidth: "webkitTextStrokeWidth",
  webkitTransform: "webkitTransform",
  webkitTransformOrigin: "webkitTransformOrigin",
  webkitTransformOriginX: "webkitTransformOriginX",
  webkitTransformOriginY: "webkitTransformOriginY",
  webkitTransformOriginZ: "webkitTransformOriginZ",
  webkitTransformStyle: "webkitTransformStyle",
  webkitTransition: "webkitTransition",
  webkitTransitionDelay: "webkitTransitionDelay",
  webkitTransitionDuration: "webkitTransitionDuration",
  webkitTransitionProperty: "webkitTransitionProperty",
  webkitTransitionTimingFunction: "webkitTransitionTimingFunction",
  webkitUserDrag: "webkitUserDrag",
  webkitUserModify: "webkitUserModify",
  webkitUserSelect: "webkitUserSelect",
  webkitWritingMode: "webkitWritingMode",
  whiteSpace: "whiteSpace",
  widows: "widows",
  width: "width",
  willChange: "willChange",
  wordBreak: "wordBreak",
  wordSpacing: "wordSpacing",
  wordWrap: "wordWrap",
  writingMode: "writingMode",
  x: "x",
  y: "y",
  zIndex: "zIndex",
  zoom: "zoom"
};
exports.cssPropertyKeys = Object.keys(styleKeysMap).filter(function (i) {
  return omittedKeys.indexOf(i) === -1;
});