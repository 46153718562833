import { reduxForm } from "redux-form";
import {
  updateConfig,
  stateSchema,
  selectConfig
} from "new/redux/reducers/config";
import { compose } from "recompose";
import React from "react";
import { FormField } from "new/shared";
import { SelectField, Toggle } from "redux-form-material-ui";
import { Padding } from "new/shared";
import { Flex } from "new/shared";
import { makeSchemaValidator } from "new/utils";
import MenuItem from "material-ui/MenuItem";
import { connect } from "react-redux";
import { RoleOverride } from "./RoleOverride";

const FlexForm = Flex.withComponent("form");

export const ConfigForm = compose(
  connect(state => ({
    initialValues: selectConfig(state)
  })),
  reduxForm({
    form: "secretConfig",
    validate: makeSchemaValidator(stateSchema),
    onChange: (values, dispatch) => dispatch(updateConfig(values))
  })
)(() => (
  <FlexForm justifyContent="flex-start">
    <Flex column alignItems="flex-start" flex>
      <p>Not currently working, just a stub</p>
      <FormField
        name="apiUrl"
        component={SelectField}
        floatingLabelText="API Url"
        autoWidth
      >
        <MenuItem
          value="https://api.readcloud.com/apiv2"
          primaryText="https://api.readcloud.com/apiv2"
        />
        <MenuItem
          value="https://devapi.readcloud.com/newapi"
          primaryText="https://devapi.readcloud.com/newapi"
        />
      </FormField>
      <Padding />
      <FormField component={Toggle} name="easMode" label="EAS Mode" />
      <Padding />
      <FormField component={Toggle} name="noNav" label="No Nav" />
      <Padding />
    </Flex>
    <Flex column flex alignItems="flex-start" justifyContent="flex-start">
      <p>These are working</p>
      <Padding />
      <FormField
        component={Toggle}
        name="persistButton"
        label="Keep secret menu button visible"
      />
      <Padding />
      <RoleOverride />
      <Padding />
      <FormField
        component={Toggle}
        name="openBookInReader"
        label="Show open book in reader option for search results"
      />
    </Flex>
  </FlexForm>
));
