/**
 * Created by danie on 4/5/2016.
 */

RCAdminSearchUsers.$inject = [
  "$element",
  "$mdDialog",
  "ExportService",
  "UserService",
  "InstitutionService",
  "NotificationService",
  "UtilitiesService",
  "$sessionStorage",
  "_",
  "$scope",
  "DialogService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcAdminSearchUsers", {
  controller: RCAdminSearchUsers,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-admin-search-users/rc-admin-search-users.html").then(m => m.default),
  bindings: {
    selectable: "=?",
    where: "=?",
    selected: "=?",
    multiple: "=?",
    onSelect: "=?",
    rowActions: "<"
  }
});

/* @ngInject */

function RCAdminSearchUsers(
  $element,
  $mdDialog,
  ExportService,
  UserService,
  InstitutionService,
  NotificationService,
  UtilitiesService,
  $sessionStorage,
  _,
  $scope,
  DialogService
) {
  var vm = this;

  vm.selected = vm.selected || [];

  vm.close = $mdDialog.cancel;

  vm.search = function(text) {
    return UserService.search(text);
  };

  vm.institutions = [];

  vm.searchQuery = {};

  vm.resetSearch = function() {
    vm.searchQuery.where = {};
    vm.searchQuery.limit = 50;
    vm.searchQuery.skip = 0;
    vm.searchQuery.page = 1;
  };

  vm.resetSearch();

  //Look at session storage for previous search query fields
  vm.searchQuery = $sessionStorage.searchUserQuery = _.assign(
    {},
    vm.searchQuery,
    $sessionStorage.searchUserQuery
  );

  //Watch for changes in the vm.where field, then merge it with search query
  //
  // Note: Don't want to automatically perform the search
  // as we use the 'where' param to make decisions (ie. if Institution is passed in don't LoadInstitutions just
  // load the passed in one)
  //
  $scope.$watch(
    "vm.where",
    function() {
      _.merge(vm.searchQuery.where, vm.where);

      // if(_.filter(vm.where, function(o){return !!o}).length > 0 && vm.isSearchQueryValid(vm.searchQuery.where)) {
      //   vm.performSearch(vm.searchQuery);
      // }
    },
    true
  );

  vm.loadInstitutions = function() {
    if (vm.where && vm.where.institution) {
      return vm.institutions.push(vm.where.institution);
    }

    return InstitutionService.getList().then(function(result) {
      vm.institutions = result.data.map(function(i) {
        return i.name;
      });
    });
  };

  vm.isSearchQueryValid = function(query) {
    for (var i in query) {
      if (query[i]) return true;
    }
    return false;
  };

  vm.performSearch = function() {
    vm.getSearchPromise(vm.searchQuery).then(function(data) {
      vm.searchResults = data;
    });
  };

  function sanitizeQuery(query) {
    if (!query || !query.where) return query;
    var output = _.assign({}, query);

    output.where = _.pickBy(query.where, function(i) {
      return !!i;
    });
    return output;
  }

  vm.getSearchPromise = function() {
    vm.isLoading = true;

    var query = {},
      where = {};
    var fields = {
      id: true,
      firstName: true,
      lastName: true,
      institution: true,
      institutions: true,
      email: true,
      role: true,
      yearLevel: true,
      parentEmail: true,
      loginDateTime: true,
      pollingDateTime: true,
      created: true,
      updated: true,
      deleted: true,
      status: true
    };

    query = sanitizeQuery(_.assign(query, vm.searchQuery));
    where = _.assign(
      query.where,
      UtilitiesService.regexifyWhereFields(
        _.omit(query.where, ["deleted", "institution"])
      )
    );

    if (vm.includeDeleted) {
      where.deleted = vm.includeDeleted;
    }

    return vm
      .getTotal(where)
      .then(function(result) {
        if (result === 0) {
          return NotificationService.toastError("No results found.");
        } else {
          vm.total = result;

          return (vm.searchPromise = UserService.get({
            fields: fields,
            where: where,
            limit: vm.searchQuery.limit,
            skip: vm.searchQuery.skip,
            order: vm.searchQuery.order
          }).then(function(result) {
            vm.isLoading = false;

            var users = result.data;

            if (!angular.isArray(users)) users = [users];

            users = users.map(function(i) {
              i.created = i.created
                ? new Date(i.created).toLocaleString()
                : undefined;
              i.updated = i.updated
                ? new Date(i.updated).toLocaleString()
                : undefined;
              i.loginDateTime = i.loginDateTime
                ? new Date(i.loginDateTime).toLocaleString()
                : undefined;
              i.pollingDateTime = i.pollingDateTime
                ? new Date(i.pollingDateTime).toLocaleString()
                : undefined;
              return i;
            });

            return users;
          }));
        }
      })
      .catch(function() {})
      .finally(function() {});
  };

  vm.getTotal = function(where) {
    return UserService.count(where).then(function(result) {
      return result;
    });
  };

  vm.onExport = function() {
    var query = {},
      where = {};
    var fields = {
      id: true,
      firstName: true,
      lastName: true,
      institution: true,
      email: true,
      role: true,
      yearLevel: true,
      parentEmail: true,
      loginDateTime: true,
      created: true,
      updated: true,
      deleted: true,
      pollingDateTime: true
    };

    query = sanitizeQuery(_.assign(query, vm.searchQuery));
    where = _.assign(
      query.where,
      UtilitiesService.regexifyWhereFields(
        _.omit(query.where, ["deleted", "institution"])
      )
    );

    if (vm.includeDeleted) {
      where.deleted = vm.includeDeleted;
    }

    UserService.get({
      fields: fields,
      where: where,
      limit: 99999,
      skip: 0,
      order: "institution ASC, role ASC, email ASC"
    }).then(function(result) {
      var users = result.data;
      if (!angular.isArray(users)) users = [users];
      users = users.map(function({
        created,
        updated,
        loginDateTime,
        pollingDateTime,
        ...other
      }) {
        return {
          ...other,
          created: created ? new Date(created).toLocaleString() : undefined,
          updated: updated ? new Date(updated).toLocaleString() : undefined,
          loginDateTime: loginDateTime
            ? new Date(loginDateTime).toLocaleString()
            : undefined,
          lastActivityTime: pollingDateTime
            ? new Date(pollingDateTime).toLocaleString()
            : undefined
        };
      });
      ExportService.exportArrayToXLSX(users);
    });
  };

  vm.loadInstitutions();

  vm.showUserDetails = function(e) {
    DialogService.UserDetails(e.id);
  };

  var selected = [];

  vm.userSelectedChanged = function(document, isChecked) {
    if (isChecked) selected.push(document);
    else {
      var i = selected.indexOf(document);

      if (i > -1) {
        selected.splice(i, 1);
      }
    }

    vm.selectedChanged(selected);
  };

  vm.tableFields = [
    {
      label: "Email",
      id: "email"
    },
    {
      label: "First Name",
      id: "firstName"
    },
    {
      label: "Last Name",
      id: "lastName"
    },
    {
      label: "Institution",
      id: "institution"
    },
    {
      label: "Role",
      id: "role"
    },
    {
      label: "Latest Activity",
      id: "pollingDateTime"
    }
  ];
}
