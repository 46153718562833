import {
  cloudBulkPurchase,
  addBooks
} from "@readcloud/api-client/build/v2/clouds";
import * as R from "ramda";
import { compose } from "ramda";
import { fcatch } from "../../../utils";
import { app } from "app/app";
import { store } from "new/redux/store";
import { getData } from "new/CloudAdmin/reducer";
import { v2 } from "@readcloud/api-client";
controller.$inject = [
  "$scope",
  "ApiService",
  "RouteService",
  "CloudService",
  "UtilitiesService",
  "NotificationService",
  "$element",
  "$q",
  "DialogService",
  "_",
  "HistoryService"
];

app.component("rcCloudDetails", {
  controller: controller,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-cloud-details/template.html").then(
      m => m.default
    ),
  bindings: {
    cloudId: "<"
  }
});

function controller(
  $scope,
  ApiService,
  RouteService,
  CloudService,
  UtilitiesService,
  NotificationService,
  $element,
  $q,
  DialogService,
  _,
  HistoryService
) {
  var vm = this;

  vm.data = {};

  var retrieveNotifier = NotificationService.toastIt("Retrieve", "Cloud");
  var deleteNotifier = NotificationService.toastIt("Delete", "Cloud");
  var enableNotifier = NotificationService.toastIt("Enable", "Cloud");
  var updateNotifier = NotificationService.toastIt("Update", "Cloud");

  const loaderWrap = _promise =>
    UtilitiesService.inlineLoader(_promise, $element);

  const bulkPurchase = compose(
    updateNotifier,
    loaderWrap,
    fcatch(updateNotifier),
    cloudBulkPurchase
  );

  vm.bulkPurchase = () => bulkPurchase(vm.cloudId);

  vm.getCloud = function(cloudId) {
    if (!cloudId) return $q.reject();
    var promise = CloudService.get({
      where: { id: vm.cloudId, deleted: { inq: [true, false] } },
      include: ["_books", "_members", "_admins"]
    });
    UtilitiesService.inlineLoader(promise, $element);
    return promise;
  };

  vm.refreshCloudData = function() {
    vm.getCloud(vm.cloudId)
      .then(res =>
        v2.Clouds.institutionDetails(vm.cloudId).then(r => ({
          institutionDisabledEnrolmentCompletionEmail:
            r.data.noEnrolWelcomeEmail,
          ...res.data[0],
          ...(r.data.noEnrolWelcomeEmail
            ? {
                disableEnrolmentCompletionEmail: true
              }
            : {}),
          vettrakOccurrenceId:
            res.data[0].extConfig &&
            res.data[0].extConfig.vetTrak &&
            res.data[0].extConfig.vetTrak.id,
          axcelerateInstanceId:
            res.data[0].extConfig &&
            res.data[0].extConfig.axcelerate &&
            res.data[0].extConfig.axcelerate.id  
        }))
      )
      .then(function(data) {
        vm.data = data;
        store.dispatch(getData.fulfill(vm.data));
        vm.data.id &&
          vm.data.name &&
          HistoryService.hitCloud(vm.data.id, vm.data.name);
        //vm.$scope.$apply();
      }, retrieveNotifier);
  };

  vm.openMenu = function($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  };

  vm.$onInit = function() {
    vm.refreshCloudData();
  };

  vm.handleAddUsers = function(users) {
    if (!(users && users.length)) return;
    NotificationService.areYouSure(
      "Add users",
      "Are you sure you want to add these users: " +
        users
          .map(function(user) {
            return user.email;
          })
          .join(", ")
    );
  };

  vm.options = [
    {
      title: "Refresh",
      function: () => vm.refreshCloudData()
    }
  ];

  vm.memberTableFields = [
    {
      label: "First Name",
      id: "firstName"
    },
    {
      label: "Last Name",
      id: "lastName"
    },
    {
      label: "Email",
      id: "email"
    },
    {
      label: "Role",
      id: "role"
    }
  ];

  vm.getEditData = function() {
    return vm.data;
  };

  vm.getEditFields = function() {
    if (!vm.data || !vm.data.id) {
      return;
    }
    return [
      {
        label: "Name",
        id: "name",
        editable: true,
        warning:
          vm.data.type === "School" &&
          "Warning: cloud names must match the instititution's class code if they are synchronized"
      },
      {
        label: "Description",
        id: "description",
        editable: true
      },
      {
        label: "Institution",
        id: "institution",
        editable: false
      },
      {
        label: "Only admins can add content",
        id: "addBookAdminOnly",
        editable: true,
        toggle: true
      },
      {
        label: "Type",
        id: "type"
      },
      {
        label: "VETTrak Occurrence Id",
        id: "vettrakOccurrenceId",
        editable: true
      },
      {
        label: "Axcelerate Instance Id",
        id: "axcelerateInstanceId",
        editable: true
      }/*,
      {
        label: "Disable Enrolment Completion Email",
        id: "disableEnrolmentCompletionEmail",
        editable: true,
        toggle: true,
        ...(vm.data.institutionDisabledEnrolmentCompletionEmail
          ? {
              editable: false,
              warning:
                "Enrolment completion emails are disabled by the institution, therefore you cannot override this"
            }
          : {})
      }*/
    ];
  };

  $scope.$watch("vm.data", function() {
    if (vm.data && vm.data.id) {
      vm.editData = vm.getEditData();
      vm.editFields = vm.getEditFields();
    }
  });

  vm.delete = function() {
    return ApiService.Clouds.disable(vm.cloudId).then(
      deleteNotifier,
      _.flow(
        deleteNotifier,
        _.set.bind(null, vm.data, "deleted", false)
      )
    );
  };

  vm.enable = function() {
    return ApiService.Clouds.enable(vm.cloudId).then(enableNotifier, function(
      error
    ) {
      enableNotifier(error);
      vm.data.deleted = true;
    });
  };

  vm.handleDeletedChanged = () =>
    vm.data.deleted
      ? NotificationService.areYouSure(
          "Delete Cloud",
          "Are you sure you want to delete this cloud?"
        ).then(vm.delete, () => (vm.data.deleted = false))
      : vm.enable();

  vm.cloudChanged = ({
    vettrakOccurrenceId,
    axcelerateInstanceId,
    disableEnrolmentCompletionEmail,
    institutionDisabledEnrolmentCompletionEmail,
    ...data
  }) => {
    return ApiService.Clouds.update(vm.cloudId, {
      ...data,
      ...(!!vettrakOccurrenceId || !!axcelerateInstanceId
        ? {
            extConfig: {
              ...data.extConfig,
              vetTrak: {
                //...(data.extConfig && data.extConfig.vetTrak),
                id: vettrakOccurrenceId
              },
              axcelerate: {
                id: axcelerateInstanceId
              }
            }
          }
        : {
          extConfig: {
            ...data.extConfig,
            vetTrak: {
              //...(data.extConfig && data.extConfig.vetTrak),
              id: undefined
            },
            axcelerate: {
              id: undefined
            }
          }
        }),    
      ...(disableEnrolmentCompletionEmail === undefined
        ? {}
        : disableEnrolmentCompletionEmail &&
          !institutionDisabledEnrolmentCompletionEmail
        ? {
            disableEnrolmentCompletionEmail: true
          }
        : {
            disableEnrolmentCompletionEmail: false
          })
    }).then(
      R.compose(
        updateNotifier,
        result => vm.refreshCloudData()
      ),
      updateNotifier
    );
  };

  vm.addEntity = (type, prop = "id") => initiator => () =>
    initiator()
      .then(R.map(R.prop(prop)))
      .then(users =>
        _.assign(
          {},
          vm.data,
          { [type]: _.union(users, vm.data[type]) },
          type === "admins" ? { members: _.union(users, vm.data.members) } : null
        )
      )
      .then(R.curryN(2, ApiService.Clouds.update)(vm.cloudId))
      .then(
        R.compose(
          updateNotifier,
          data => {
            vm.refreshCloudData();
            return data;
          }
        ),
        updateNotifier
      );

  const wrapInUpdate = promise =>
    promise.then(updateNotifier, updateNotifier).then(vm.refreshCloudData);

  vm.removeMember = id =>
    wrapInUpdate(
      NotificationService.areYouSure(
        "Remove member",
        "Are you sure you would like to remove this member?"
      ).then(() =>
        ApiService.Clouds.update(
          vm.cloudId,
          _.assign({}, vm.data, {
            members: vm.data.members.filter(e => e !== id),
            admins: vm.data.admins.filter(e => e !== id)
          })
        )
      )
    );

  vm.removeAdmin = id =>
    wrapInUpdate(
      NotificationService.areYouSure(
        "Remove admin",
        "Are you sure you would like to remove this admin?"
      ).then(() =>
        ApiService.Clouds.update(
          vm.cloudId,
          _.assign({}, vm.data, {
            admins: vm.data.admins.filter(e => e !== id)
          })
        )
      )
    );

  vm.memberActions = [
    {
      label: "Remove from cloud (including admins)",
      func: row => vm.removeMember(row.id)
    }
  ];

  vm.adminActions = [
    {
      label: "Remove from admins",
      func: row => vm.removeAdmin(row.id)
    }
  ];

  vm.addMembers = vm.addEntity("members")(() =>
    DialogService.UserSelection({ institution: vm.data.institution }, true)
  );

  vm.addAdmins = vm.addEntity("admins")(() =>
    DialogService.UserSelection({ institution: vm.data.institution }, true)
  );

  vm.addBooks = () =>
    DialogService.BookSelection()
      .then(books => books.map(({ isbn }) => ({ isbn })))
      .then(books => wrapInUpdate(addBooks(vm.data.id, books)));
}
