import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { select, update } from "./reducer";
import { FlexForm } from "new/shared";
import React from "react";
import { FormField } from "new/shared";
import { SelectField, Toggle } from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import { Padding } from "new/shared";
import { equals } from "ramda";
import { compose, setPropTypes } from "recompose";
import pt from "prop-types";

const styles = {
  selectField: {
    width: 100
  },
  toggleField: {
    fontSize: 14
  }
};

const ViewToolsComponent = () => (
  <FlexForm alignItems="center">
    <FormField
      name="viewMode"
      floatingLabelText="View Mode"
      component={SelectField}
      style={styles.selectField}
    >
      <MenuItem primaryText="Table" value={1} />
      <MenuItem primaryText="List" value={0} />
    </FormField>
    <Padding />
    <FormField
      name="filter.limit"
      floatingLabelText="Limit"
      component={SelectField}
      style={styles.selectField}
    >
      <MenuItem primaryText="20" value={20} />
      <MenuItem primaryText="50" value={50} />
      <MenuItem primaryText="100" value={100} />
    </FormField>
    <Padding />
    <FormField
      component={Toggle}
      name="filter.privateOnly"
      label="Private Only"
      style={styles.toggleField}
    />
    <Padding />
    <FormField
      component={Toggle}
      name="filter.personalOnly"
      label="Only made by you"
      style={styles.toggleField}
    />
    <Padding />
  </FlexForm>
);

export const ViewTools = compose(
  setPropTypes({
    onFilterChanged: pt.func.isRequired,
    annotations: pt.array
  }),
  connect(
    compose(
      ({ filter, viewMode, sortBy }) => ({
        initialValues: {
          filter,
          viewMode,
          sortBy
        }
      }),
      select
    )
  ),
  reduxForm({
    form: "annotationExportViewTools",
    onChange: (values, dispatch, { onFilterChanged }, previous) => {
      dispatch(update(values));
      if (!equals(values.filter, previous.filter)) {
        onFilterChanged && onFilterChanged(values.filter);
      }
    }
  })
)(ViewToolsComponent);
