"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.getJwtPayload = function (jwt) {
  try {
    return JSON.parse(Buffer.from(jwt.split(".")[1], "base64").toString());
  } catch (e) {
    throw new Error("Invalid JWT: " + jwt);
  }
};

exports.isJwtValid = function (token) {
  var payload = exports.getJwtPayload(token);
  var expiry = new Date(payload.exp * 1000 - 10 * 1000); // Give us a 10 second buffer

  return expiry > new Date();
};

exports.getErrorFromApiResponse = function (responseError) {
  try {
    return responseError && responseError.response && responseError.response.data ? responseError.response.data.error ? responseError.response.data.error.message : responseError.response.data.result ? responseError.response.data.result.error : responseError.message : responseError.message;
  } catch (e) {
    return responseError.message;
  }
};