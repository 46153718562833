import React from "react";
import { Dialog, RaisedButton, Divider } from "material-ui";
import { Toggle, TextField } from "redux-form-material-ui";
import { Div } from "glamorous";
import { compose, setPropTypes, withState } from "recompose";
import { FormField, Flex } from "app/new-components/shared";
import { reduxForm } from "redux-form";

import { string } from "prop-types";
import {
  makeSchemaValidator,
  serverErrorToSubmissionError
} from "app/new-components/utils";
import ReactJson from "react-json-view";
import { nextChristmas } from "utils";
import { pick } from "ramda";
import { Padding } from "new/shared";
import { APICallStateContainer } from "new/shared";
import moment from "moment";
import { FormDatePicker } from "new/shared/FormComponents";
import { cloudBulkPurchase } from "@readcloud/api-client/build/v2/clouds";
const schema = {
  type: "object",
  additionalProperties: false,
  required: [
    "forceRepurchase",
    "priceIncGst",
    "purchaseDate",
    "bookExpireDate",
    "currencyCode`"
  ],
  properties: {
    forceRepurchase: {
      type: "boolean"
    },
    priceIncGst: {
      type: "number",
      minimum: 0
    },
    purchaseDate: {
      type: "object"
    },
    bookExpireDate: {
      type: "object"
    },
    currencyCode: {
      type: "string",
      minLength: 3
    }
  }
};

const getOnlySchemaValues = pick(Object.keys(schema.properties));
const stringToNumber = s => parseInt(s, 10);

const isDateInTheFuture = value =>
  moment(value)
    .endOf("day")
    .isSameOrAfter(moment())
    ? null
    : "date must be in the future";

const BulkPurchaseFormComponent = ({
  onRequestClose,
  handleSubmit,
  submitting,
  submitSucceeded,
  responseData,
  error,
  ...props
}) => (
  <form>
    {!responseData ? (
      <div>
        <APICallStateContainer
          column
          alignItems="flex-start"
          error={error}
          loading={submitting}
        >
          <FormField
            name="priceIncGst"
            component={TextField}
            normalize={stringToNumber}
            type="number"
            floatingLabelText="Price Including GST"
          />
          <Padding />
          <FormField
            name="currencyCode"
            component={TextField}
            floatingLabelText="Currency Code"
          />
          <Padding />
          <FormField
            name="purchaseDate"
            validate={isDateInTheFuture}
            component={FormDatePicker}
            floatingLabelText="Purchase Date"
          />
          <Padding />
          <FormField
            name="bookExpireDate"
            component={FormDatePicker}
            validate={isDateInTheFuture}
            floatingLabelText="Book Expire Date"
          />
          <Padding />
          <FormField
            name="forceRepurchase"
            component={Toggle}
            label="Force Repurchase"
          />
          <Padding />
        </APICallStateContainer>
        <Div paddingTop={16} width="100%" display="flex">
          <RaisedButton label="Cancel" onClick={onRequestClose} />
          <Div flex={1} />
          <RaisedButton primary label="Submit" onClick={handleSubmit} />
        </Div>
      </div>
    ) : (
      <Flex column>
        <h3>Result</h3>
        <Divider />
        <Padding />
        <ReactJson
          displayDataTypes={false}
          style={{ maxHeight: 600, overflowY: "overlay" }}
          src={responseData}
        />
        <Padding />
        <RaisedButton primary label="OK" onClick={onRequestClose} />
      </Flex>
    )}
  </form>
);

const BulkPurchaseForm = compose(
  setPropTypes({
    cloudId: string.isRequired
  }),
  withState("responseData", "setResponseData"),
  reduxForm({
    form: "bulkPurchaseForm",
    validate: makeSchemaValidator(schema),
    onSubmit: (values, dispatch, { cloudId, setResponseData }) =>
      cloudBulkPurchase(cloudId, getOnlySchemaValues(values))
        .then(({ data: { result } }) => {
          setResponseData(result);
          window.dispatchEvent(new Event("resize"));
        })
        .catch(serverErrorToSubmissionError),
    initialValues: {
      purchaseDate: new Date(),
      forceRepurchase: false,
      bookExpireDate: nextChristmas().toDate(),
      currencyCode: "AUD",
      priceIncGst: 0
    }
  })
)(BulkPurchaseFormComponent);

const Component = ({ onSubmit, formProps, cloudId, ...props }) => (
  <Dialog modal={true} {...props}>
    <BulkPurchaseForm
      cloudId={cloudId}
      onSubmit={onSubmit}
      onRequestClose={props.onRequestClose}
      {...formProps}
    />
  </Dialog>
);

export const BulkPurchaseModal = Component;
