"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.addInstitution = function (req) {
  return index_1.getApiv14().post("/add/institution", req);
};

exports.deleteInstitution = function (name) {
  return index_1.getApiv14().delete("/delete/institution", {
    data: {
      name: name
    }
  });
};

exports.institutionRcsResources = function (apiKey) {
  return index_1.getApiv14().get("/institution_rcs_resources", {
    params: {
      apiKey: apiKey
    }
  });
};