import angular from "angular";
RCActionCreatorModalController.$inject = ["$mdDialog"];
angular
  .module("readCloudWebConsole")
  .controller("RCActionCreatorModalController", RCActionCreatorModalController);

function RCActionCreatorModalController($mdDialog) {
  var vm = this;
  vm.success = $mdDialog.hide;
  vm.cancel = $mdDialog.cancel;
}
