import { composeReducer, scopeReducers, createFactory } from "recrux";
import { combineEpics } from "redux-observable";
import {
  makeDefaultApiCallState,
  makeApiCallAction
} from "new/redux/reducers/shared";
import { createSelector } from "reselect";
import moment from "moment";
import _ from "lodash";
import { findById } from "@readcloud/api-client/build/v2/clouds";
import { bookStatistics } from "@readcloud/api-client/build/v2/users";
import { debounceTime, mergeMap } from "rxjs/operators";
const namespace = "cloudAdmin";

const defaultState = {
  ...makeDefaultApiCallState(),
  userBookStats: {
    ...makeDefaultApiCallState()
  },
  openCards: {}
};

export const getData = makeApiCallAction(namespace, "getData");
const getDataEpic = action$ =>
  action$.ofType(getData.type).pipe(
    debounceTime(200),
    mergeMap(({ payload }) =>
      findById(payload, { include: ["_books", "_members"] })
        .then(response => response.data)
        .then(getData.fulfill)
        .catch(({ message }) => getData.error(message))
    )
  );

export const getUserBookStats = makeApiCallAction(
  namespace,
  "getUserBookStats",
  state => ({ ...state, data: {}, error: null, loading: true })
);
const getUserBookStatsEpic = action$ =>
  action$.ofType(getUserBookStats.type).pipe(
    mergeMap(({ payload: { bookId, userId, from, to, limit } }) =>
      bookStatistics(bookId, userId, {
        from:
          from ||
          moment()
            .subtract(30, "days")
            .toDate(),
        to: to || moment().toDate(),
        limit
      })
        .then(response => getUserBookStats.fulfill(response.data))
        .catch(getUserBookStats.error)
    )
  );
export const toggleCard = createFactory({
  namespace,
  actionName: "toggleCard",
  reducer: (state, { payload }) => ({
    ...state,
    openCards: { [payload]: !state.openCards[payload] }
  })
});

export const select = state => state[namespace];
export const selectUserBookStats = state => select(state).userBookStats.data;
export const selectLastReadPages = state =>
  selectUserBookStats(state).lastReadPages;

export const selectLast5PageStats = createSelector(
  selectLastReadPages,
  (lastReadPages = []) => _.orderBy(lastReadPages, "timeLastClosed", "desc")
);

export const selectBooks = createSelector(select, ({ data }) =>
  _.keyBy(data._books, "id")
);

export const selectMembers = createSelector(select, ({ data }) =>
  _.keyBy(data._members, "id")
);

export const cloudAdminReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    getData.reducer,
    scopeReducers({
      userBookStats: getUserBookStats.reducer
    }),
    toggleCard.reducer
  )
});

export const cloudAdminEpic = combineEpics(getDataEpic, getUserBookStatsEpic);
