/**
 * Created by danie on 4/18/2016.
 */

const angular = require("angular");
angular.module("readCloudWebConsole").component("rcSmallCard", {
  controller: RCSmallCard,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-small-card/rc-small-card.html").then(m => m.default),
  transclude: true,
  bindings: {
    title: "=",
    collapsible: "=?",
    isOpen: "=?"
  }
});

/* @ngInject */

function RCSmallCard() {
  var vm = this;

  vm.$onInit = () => {
    if (!vm.collapsible) vm.isOpen = true;
  };

  vm.toggle = function() {
    if (vm.collapsible) vm.isOpen = !vm.isOpen;
  };
}
