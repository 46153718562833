import { createAsyncFactory } from "recrux";

export const makeApiCallAction = (
  namespace,
  actionName,
  requestReducer,
  fulfillReducer,
  errorReducer
) =>
  createAsyncFactory({
    namespace,
    actionName,
    requestReducer:
      requestReducer || (state => ({ ...state, loading: true, error: null })),
    fulfillReducer:
      fulfillReducer ||
      ((state, { payload }) => ({
        ...state,
        data: payload,
        loading: false,
        error: null
      })),
    errorReducer:
      errorReducer ||
      ((state, { payload }) => ({
        ...state,
        loading: false,
        error: payload
      }))
  });

export const makeMultiApiCallAction = (namespace, actionName) =>
  createAsyncFactory({
    namespace,
    actionName,
    requestReducer: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        data: null,
        loading: true,
        error: null
      }
    }),
    fulfillReducer: (state, { payload: { key, payload } }) => ({
      ...state,
      [key]: {
        data: payload,
        loading: false,
        error: null
      }
    }),
    errorReducer: (state, { payload: { key, payload } }) => ({
      ...state,
      [key]: {
        loading: false,
        error: payload,
        data: null
      }
    })
  });

export const makeDefaultApiCallState = () => ({
  data: {},
  error: null,
  loading: false
});
