"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var ramda_1 = require("ramda");

var rxjs_1 = require("rxjs");

var accessToken;
var refreshToken;
var clientId;

var addAuthInterceptor = function addAuthInterceptor(client) {
  client.interceptors.request.use(function (config) {
    if (accessToken) {
      config.headers = tslib_1.__assign({
        Authorization: "Bearer " + accessToken
      }, config.headers);
    }

    return config;
  });
  return client;
};

var addCommonHeaders = function addCommonHeaders(client) {
  //console.log("CommonHeaderCreated");
  client.defaults.headers = tslib_1.__assign({
    Accept: "application/json",
    "content-type": "application/json"
  }, client.defaults.headers);
  return client;
};

exports.addCommonAxiosConfig = ramda_1.compose(addAuthInterceptor, addCommonHeaders);

exports.setAccessToken = function (token) {
  return accessToken = token;
};

exports.getAccessToken = function () {
  return accessToken;
};

exports.setRefreshToken = function (token) {
  return refreshToken = token;
};

exports.getRefreshToken = function () {
  return refreshToken;
};

exports.setClientId = function (_clientId) {
  return clientId = _clientId;
};

exports.getClientId = function () {
  return clientId;
};

exports.refreshTokenFailure$ = new rxjs_1.Subject();