import jszip from "jszip";
import moduleName from "./moduleName";
import XLSX from "xlsx";
import * as R from "ramda";
import _ from "lodash";
import moment from "moment";
import assert from "assert";
import angular from "angular";
if (!window.jszip) window.jszip = jszip;

angular
  .module(moduleName)
  .constant("moment", moment)
  .constant("XLSX", XLSX)
  .constant("_", _)
  .constant("R", R)
  .constant("CONFIG", {
    restApiRoot: "/api",
    title: "ReadCloud Web Console"
  })
  //.constant('URI',URI)
  .constant("assert", assert)
  .constant("AUTH_EVENTS", {
    successfulLogin: "successfulLogin",
    unauthorized: "unauthorized",
    failedLogin: "failedLogin",
    receivedJWT: "receivedJWT",
    receivedRefreshToken: "receivedRefreshToken",
    unknownAuthenticationError: "unknownAuthenticationError",
    logout: "logout",
    renewingJWT: "renewingJWT",
    invalidRefreshToken: "invalidRefreshToken",
    notLoggedIn: "notLoggedIn",
    corsError: "corsError",
    incorrectUsernamePassword: "incorrectUsernamePassword"
  })
  .constant("USER_ROLES", {
    SchoolAdmin: "SchoolAdmin",
    ResellerAdmin: "ResellerAdmin",
    GroupAdmin: "GroupAdmin",
    Admin: "Admin",
    Student: "Student",
    Teacher: "Teacher",
    Public: "Public"
  })
  .constant("USER_ROLE_DEFAULT_ROUTES", {
    SchoolAdmin: "main.admin",
    GroupAdmin: "main.admin",
    ResellerAdmin: "main.admin",
    Admin: "main.admin",
    Student: "main.me",
    Teacher: "main.me"
  })
  .constant("ERROR_CODES", {
    TokenExpired: "TokenExpiredError",
    InvalidRefreshToken: "INVALID_REFRESH_TOKEN",
    AuthorizationRequired: "AUTHORIZATION_REQUIRED",
    InvalidUsernamePassword: "INCORRECT_USERNAME_PASSWORD"
  });
