"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _1 = require(".");

exports.streamExport = function (params) {
  return _1.getApiv14().get("/stream/export", {
    params: params
  });
};

exports.streamImport = function (params, body) {
  return _1.getApiv14().post("/stream/import", body, {
    params: params
  });
};