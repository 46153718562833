import { createFactory, composeReducer, scopeReducers } from "recrux";
import { combineEpics } from "redux-observable";
import {
  getInstitutions,
  getStats as getInstitutionStats,
  updateInstitution
} from "@readcloud/api-client/build/v2/institutions";
import { newError } from "new/utils";
import { makeApiCallAction } from "new/redux/reducers/shared";
import { of, from } from "rxjs";
import { map, flatMap, startWith, mergeMap } from "rxjs/operators";
export const namespace = "institutionAdmin";
const defaultState = {
  loading: false,
  data: {},
  error: null,
  stats: {
    loading: false,
    error: null,
    data: {}
  },
  statusState: {
    loading: false,
    error: null
  }
};
export const getData = makeApiCallAction(namespace, "getData");
const getDataEpic = action$ =>
  action$.ofType(getData.type).pipe(
    flatMap(({ payload }) => {
      return getInstitutions({
        where: { id: payload },
        limit: 1
      })
        .then(institutions => {
          if (!institutions.data || !institutions.data.length)
            return getData.error(
              newError(
                "Institution not found",
                `Institution with ID '${payload}' not found`
              )
            );
          return getData.fulfill(institutions.data[0]);
        })
        .catch(({ message }) => getData.error(message));
    })
  );
export const refresh = createFactory({
  namespace,
  actionName: "refresh",
  reducer: state => state
});
const refreshEpic = (action$, store) =>
  action$
    .ofType(refresh.type)
    .pipe(map(() => getData(select(store.getState()).data.id)));

export const getStats = makeApiCallAction(namespace, "getStats");
export const resetStats = createFactory({
  namespace,
  actionName: "resetStats",
  reducer: state => defaultState.stats
});
const getStatsEpic = action$ =>
  action$.ofType(getStats.type).pipe(
    flatMap(({ payload }) =>
      of(null).pipe(
        flatMap(() =>
          getInstitutionStats(payload)
            .then(response => response.data)
            .then(getStats.fulfill)
            .catch(({ message }) => getStats.error(message))
        ),
        startWith(resetStats())
      )
    )
  );

export const setStatus = makeApiCallAction(namespace, "setStatus");

const setStatusEpic = action$ =>
  action$.ofType(setStatus.type).pipe(
    flatMap(({ payload }) =>
      of(null).pipe(
        mergeMap(() =>
          from(
            updateInstitution(payload.id, { status: payload.status })
              .then(() => null)
              .catch(({ message }) => setStatus.error(message))
          ).pipe(
            mergeMap(
              //action is null therefore was successul, otherwise its an error
              action => (!action ? [setStatus.fulfill(), refresh()] : action)
            )
          )
        )
      )
    )
  );

export const select = state => state[namespace];
export const institutionAdminEpic = combineEpics(
  getDataEpic,
  refreshEpic,
  getStatsEpic,
  setStatusEpic
);

export const institutionAdminReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    getData.reducer,
    scopeReducers({
      stats: composeReducer(getStats.reducer, resetStats.reducer)
    }),
    scopeReducers({
      statusState: setStatus.reducer
    })
  )
});
