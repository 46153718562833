"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.isDev = function () {
  return process.env.NODE_ENV === "development";
};

exports.isProd = function () {
  return process.env.NODE_ENV === "production";
};