import React from "react";
import { FlatButton } from "material-ui";
import { compose, setPropTypes } from "recompose";
import { BulkPurchaseModal } from "./BulkPurchaseModal";

import { withDialogState } from "app/new-components/shared";

import { string } from "prop-types";
const Component = ({ cloudId, open, onRequestOpen, buttonProps, ...props }) => (
  <FlatButton onClick={onRequestOpen} label="Bulk Purchase" {...buttonProps}>
    <BulkPurchaseModal cloudId={cloudId} open={open} {...props} />
  </FlatButton>
);

export const BulkPurchaseButton = compose(
  withDialogState,
  setPropTypes({
    cloudId: string.isRequired
  })
)(Component);
