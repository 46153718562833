ctrl.$inject = ["UserService", "AuthenticationService", "_"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcRolesSelector", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-roles-selector/template.html").then(m => m.default),
  bindings: {
    initial: "<",
    onSelectedChanged: "<"
  }
});
/* @ngInject */
function ctrl(UserService, AuthenticationService, _) {
  var vm = this;

  UserService.getAllRoles().then(function(roles) {
    var userRole = AuthenticationService.getRole();
    if (userRole === "ResellerAdmin" || userRole === "GroupAdmin") {
      vm.roles = ["Student", "Teacher", "SchoolAdmin", "GroupAdmin"];
    } else {
      vm.roles = roles;
    }
  });

  vm.selected = vm.initial || [];

  vm.changed = function() {
    if (vm.onSelectedChanged) vm.onSelectedChanged(vm.selected);
  };

  vm.queryRoles = function(role) {
    return (vm.roles || []).filter(function(r) {
      var tester = new RegExp(".*" + role + ".*", "i");
      var caseInsensitive = new RegExp(role, "i");
      return (
        tester.test(r) &&
        !vm.selected.find(function(_r) {
          return caseInsensitive.test(_r);
        })
      );
    });
  };
}
