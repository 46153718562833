/**
 * Created by danie on 5/10/2016.
 */

UIService.$inject = [
  "$mdMedia",
  "$state",
  "$document",
  "$localStorage",
  "_",
  "RouteService",
  "HistoryService",
  "DialogService",
  "CONFIG"
];
const angular = require("angular");
angular.module("readCloudWebConsole").service("UIService", UIService);

/* @ngInject */
function UIService(
  $mdMedia,
  $state,
  $document,
  $localStorage,
  _,
  RouteService,
  HistoryService,
  DialogService,
  CONFIG
) {
  var UIService = {};

  var subscribers = [];

  function addSubscriber(subscriber) {
    if (subscribers.indexOf(subscriber) === -1) subscribers.push(subscriber);
  }

  function notifySubscribers() {
    subscribers.forEach(function(s) {
      s();
    });
  }

  /**
     * Config variables to be used throughout.
     * @type {{sidebarVisiblePoint: string, sidebarWidth: string,pageTitlePrefix: string}}
       */
  var config = {
    sidebarVisiblePoint: "gt-sm",
    sidebarWidth: "200px",
    pageTitlePrefix: CONFIG.title + " | "
  };

  $localStorage.UIService = $localStorage.UIService || {};

  HistoryService.subscribe(function() {
    notifySubscribers();
  });

  UIService.sidebarDisabled = false;
  UIService.toolbarDisabled = false;
  UIService.disableSidebar = function() {
    UIService.sidebarDisabled = true;
  };
  UIService.enableSidebar = function() {
    UIService.sidebarDisabled = false;
  };
  UIService.disableToolbar = function() {
    UIService.toolbarDisabled = true;
  };
  UIService.enableToolbar = function() {
    UIService.toolbarDisabled = false;
  };

  UIService.isSidebarVisible = function isSidebarVisible() {
    return !UIService.sidebarDisabled;
  };

  UIService.isToolbarVisible = function() {
    return !UIService.toolbarDisabled;
  };

  UIService.getSidebarWidth = function getSidebarWidth() {
    return config.sidebarWidth;
  };

  UIService.getRouteTitle = function getRouteTitle() {
    if (
      $state &&
      $state.current &&
      $state.current.data &&
      $state.current.data.title
    )
      return $state.current.data.title;
    else return "ReadCloud";
  };

  UIService.getPageTitle = function getPageTitle() {
    return config.pageTitlePrefix + UIService.getRouteTitle();
  };

  UIService.updatePageTitle = function updatePageTitle() {
    $document[0].title = UIService.getPageTitle();
  };

  UIService.updateNavTree = function updateNavTree() {
    //$localStorage.UIService.navTree = navTree = _.assign(navTree, tree);
    /*console.log(_.assign(navTree, tree));*/
  };

  UIService.addSubscriber = addSubscriber;

  UIService.enableEasMode = function() {
    UIService.easMode = true;
  };

  UIService.disableEasMode = function() {
    UIService.easMode = false;
  };

  UIService.isEasMode = function() {
    return UIService.easMode;
  };

  return UIService;
}
