import glamorous from "glamorous";
import { compose, setDisplayName } from "recompose";
import LogoWhiteCloudPng from "assets/images/LogoInverse.svg";
export const LogoInverse = compose(setDisplayName("LogoWhite"))(
  glamorous.div({
    backgroundImage: `url('${LogoWhiteCloudPng}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100%",
    width: 50,
    height: 50
  })
);
