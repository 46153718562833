/**
 * Created by danie on 6/28/2016.
 */

RCUserList.$inject = ["DialogService", "_"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserList", {
  templateUrl: () => import("app/components/rc-user-list/template.html").then(m => m.default),
  controller: RCUserList,
  controllerAs: "vm",
  bindings: {
    selectedUsers: "=",
    onChange: "<"
  }
});

/* @ngInject */
function RCUserList(DialogService, _) {
  var vm = this;
  vm.selectedUsers = vm.selectedUsers || [];

  vm.showAddUsers = function() {
    DialogService.UserSelection(null, true).then(function(users) {
      if (vm.onChange) vm.onChange();
      vm.selectedUsers = _.union(vm.selectedUsers, _.map(users, "email"));
    });
  };
}
