/**
 * Created by danie on 4/21/2016.
 */

DialogService.$inject = [
  "$mdDialog",
  "$mdMedia",
  "$mdBottomSheet",
  "RouteService",
  "$document",
  "$location"
];
const angular = require("angular");
angular.module("readCloudWebConsole").service("DialogService", DialogService);

/*@ngInject*/
function DialogService(
  $mdDialog,
  $mdMedia,
  $mdBottomSheet,
  RouteService,
  $document,
  $location
) {
  var DialogService = {};

  function dialogSettings(template, controller, locals) {
    return {
      template,
      controller: controller,
      controllerAs: "vm",
      bindToController: true,
      autoWrap: false,
      locals: locals
    };
  }

  DialogService.UserCreator = function(prefil) {
    return $mdDialog.show({
      ok: "Close",
      template: require("app/modals/rc-user-creator.html"),
      controllerAs: "vm",
      locals: {
        data: prefil || {}
      },
      bindToController: true,
      controller: [
        "$mdDialog",
        function($mdDialog) {
          var vm = this;
          vm.close = $mdDialog.hide;
          vm.success = function(data) {
            if (data && data.email) RouteService.admin.userDetails(data.email);
          };
        }
      ]
    });
  };

  DialogService.BookDetails = function(data) {
    $mdDialog.show({
      ok: "Close",
      template: require("app/components/rc-cloud/book-details-dialog/book-details-dialog.html"),
      controller: "bookDetailsDialogController",
      bindToController: true,
      controllerAs: "vm",
      locals: { data: data },
      fullscreen: $mdMedia("xs") || $mdMedia("sm")
    });
  };

  DialogService.UserSelection = function(where, multiple) {
    var controller = "RCUserSelectionModalController";
    var locals = {
      where: where,
      multiple: multiple
    };

    const template = require("../modals/rc-user-selection-modal/rc-user-selection-modal.html");

    return $mdDialog.show(dialogSettings(template, controller, locals));
  };

  DialogService.UserDetails = function(userId) {
    return $mdDialog.show({
      template:
        '<md-dialog flex="100" flex-gt-sm="90"><rc-user-details-dialog user-id="' +
        userId +
        '"></rc-user-details-dialog></md-dialog>',
      autoWrap: false
    });
  };

  DialogService.BookSelection = function() {
    return $mdDialog.show({
      template: require("app/modals/rc-book-selection-modal/template.html"),
      autoWrap: false,
      controller: "RCBookSelectionModalController",
      controllerAs: "vm",
      clickOutsideToClose: true
    });
  };

  DialogService.MappedOrderCreator = function(resellerId) {
    return $mdDialog.show({
      template: require("app/modals/rc-mapped-order-creator-modal/template.html"),
      controller: "RCMappedOrderCreatorModalCtrl",
      controllerAs: "vm",
      clickOutsideToClose: true,
      autoWrap: false,
      locals: {
        resellerId: resellerId
      },
      bindToController: true
    });
  };

  DialogService.MappedOrderEditor = function(mappedOrder) {
    return $mdDialog.show({
      template: require("app/modals/rc-mapped-order-editor-modal/template.html"),
      controller: "RCMappedOrderEditorModalController",
      controllerAs: "vm",
      clickOutsideToClose: true,
      autoWrap: false,
      locals: {
        mappedOrder: mappedOrder
      },
      bindToController: true
    });
  };

  DialogService.OnixGenerator = function() {
    return $mdDialog.show({
      template: require("app/modals/rc-onix-generator-modal/template.html"),
      controller: "RCOnixGeneratorModalController",
      controllerAs: "vm",
      fullscreen: $mdMedia("xs") || $mdMedia("sm")
    });
  };

  DialogService.InstitutionCreator = function() {
    return $mdDialog.show({
      template: require("app/modals/rc-institution-creator-modal/template.html"),
      controller: "RCInstitutionCreatorModalController",
      controllerAs: "vm"
    });
  };

  DialogService.ApplicationBuildCreator = function(applicationId) {
    if (!applicationId) return;

    return $mdDialog.show({
      template: require("app/modals/rc-application-build-creator/template.html"),
      controller: "RCApplicationBuildCreatorModalController",
      controllerAs: "vm",
      bindToController: true,
      autoWrap: false,
      locals: {
        applicationId: applicationId
      }
    });
  };

  DialogService.fromTemplate = function fromTemplate(template, data) {
    return $mdDialog.show({
      autoWrap: false,
      closeTo: ".close",
      template,
      locals: data,
      bindToController: true,
      controllerAs: "vm",
      controller: function() {
        this.cancel = $mdDialog.hide;
      }
    });
  };

  DialogService.ShowDirectTemplateURL = function() {
    function getDirectURL() {
      return JSON.stringify($location);
    }

    getDirectURL();
    var dialog = $mdDialog
      .alert()
      .title("Direct URL")
      .textContent(getDirectURL())
      .ok("OK");

    return $mdDialog.show(dialog);
  };

  DialogService.ActionCreator = function() {
    return $mdDialog.show({
      autoWrap: true,
      template: require("app/modals/rc-action-creator-modal/template.html"),
      bindToController: true,
      controllerAs: "vm",
      controller: "RCActionCreatorModalController"
    });
  };

  return DialogService;
}
