"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.updateUserPreferences = function (req, currentDateMs) {
  return index_1.getApiv14().put("/update/app_state", [{
    tags: {
      realm: "UserPrefState"
    },
    state: req,
    date: new Date().toISOString(),
    version: "3"
  }], {
    params: {
      currentDateMs: currentDateMs
    }
  });
}; // User preferences are pulled down when the application first loads in a get/all,
// there is no reason to create a get request currently.