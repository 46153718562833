"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ALL_ANNOTATIONS = "All Annotations";
exports.ALL_ANNOTATIONS = ALL_ANNOTATIONS;
var MY_ANNOTATIONS = "My Annotations";
exports.MY_ANNOTATIONS = MY_ANNOTATIONS;
var NO_ANNOTATIONS = "No Annotations";
exports.NO_ANNOTATIONS = NO_ANNOTATIONS;