"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.bulkPurchase = function (body, apiKey) {
  return index_1.getApiv14().post("/reseller/bulk_purchase", body, {
    params: {
      apiKey: apiKey
    }
  });
};

exports.addReseller = function (req) {
  return index_1.getApiv14().post("/add/reseller", req);
};

exports.deleteReseller = function (name) {
  return index_1.getApiv14().delete("/delete/reseller", {
    data: {
      name: name
    }
  });
};