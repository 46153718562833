/**
 * Created by danie on 31/03/2016.
 */
import moment from "moment";
BookDetailsDialog.$inject = ["$mdDialog"];

const angular = require("angular");
angular.module("readCloudWebConsole").controller("bookDetailsDialogController", BookDetailsDialog);

/* @ngInject */

function BookDetailsDialog($mdDialog) {
  var vm = this;

  if (vm.data && vm.data.description && /(&lt;|&#60;)+/.test(vm.data.description)) {
    vm.data.description = angular
      .element("<div/>")
      .html(vm.data.description)
      .text();
  }

  if (getExpireDate()) {
    vm.expireDate = getExpireDate();
  }

  vm.close = function() {
    $mdDialog.hide();
  };

  function getExpireDate() {
    var expireDate =
      vm.data.transaction &&
      (vm.data.transaction.bookExpireDate ||
        (vm.data.rcsLicenseData && vm.data.rcsLicenseData.expireDate));
    return expireDate ? moment(expireDate).format("YYYY-MM-DD") : null;
  }
}
