/**
 * Created by danie on 29/03/2016.
 */

UserService.$inject = [
  "ApiService",
  "UtilitiesService",
  "$q",
  "NotificationService",
  "InstitutionService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").service("UserService", UserService);

var userRoles = ["Student", "Teacher"];
//var userRoles = ["Student", "Teacher", "Public"]; Removed public due to issues

//TODO: Refactor each model service to have a base service that manages middleware, or create a service adapter that wraps each method, before it is returned (at the end), like API Service
//TODO: In each model service, we should add an authentication and cache layer/middleware.
/* @ngInject*/
function UserService(
  ApiService,
  UtilitiesService,
  $q,
  NotificationService,
  InstitutionService
) {
  var UserService = {};

  /**
   * Wrap a promise so that loaders show accordingly
   * @param promise
   */
  function wrap(promise, action) {
    var cb = NotificationService.toastIt(action, "User");

    promise.then(cb, cb);
    return promise;
  }

  UserService.getAllRoles = function() {
    return ApiService.Users.getAllRoles().then(function(result) {
      return result.data;
    });
  };

  UserService.getCreatorFields = function getCreatorFields(data) {
    if (!data) data = {};
    return [
      {
        label: "Institution",
        id: "institution",
        value: data.institution,
        optionDataId: "name",
        options: InstitutionService.get,
        required: true
      },
      {
        label: "Email",
        id: "email",
        value: data.email,
        required: true
      },
      {
        label: "First Name",
        id: "firstName",
        value: data.firstName,
        required: true
      },
      {
        label: "Last Name",
        id: "lastName",
        value: data.lastName,
        required: true
      },
      {
        label: "Year Level",
        id: "yearLevel",
        value: data.yearLevel
      },
      {
        label: "Role",
        id: "role",
        value: data.role,
        options: userRoles,
        required: true
      },

      {
        label: "Parent Email",
        id: "parentEmail",
        value: data.parentEmail
      },
      {
        label: "Password",
        id: "password",
        value: data.password,
        secure: true,
        required: true
      }
    ];
  };

  // Removed these fields from Institution in below function
  // optionDataId: "name",
  // options: InstitutionService.get

  UserService.getDetailFields = function getDetailFields(data) {
    if (data) {
      return [
        {
          label: "Email",
          id: "email",
          value: data.email
        },
        {
          label: "First Name",
          id: "firstName",
          value: data.firstName,
          editable: true
        },
        {
          label: "Last Name",
          id: "lastName",
          value: data.lastName,
          editable: true
        },
        {
          label: "Year Level",
          id: "yearLevel",
          value: data.yearLevel,
          editable: true
        },
        {
          label: "Role",
          id: "role",
          value: data.role,
          editable: true,
          options: userRoles
        },
        {
          label: "Institution",
          id: "institution",
          value: data.institution,
          editable: false
        },
        {
          label: "Parent Email",
          id: "parentEmail",
          value: data.parentEmail,
          editable: true
        },
        {
          label: "Last Login",
          id: "loginDateTime",
          value: data.loginDateTime
        },
        {
          label: "Latest Activity",
          id: "pollingDateTime",
          value: data.pollingDateTime
        },
        {
          label: "Created",
          id: "created",
          value: data.created
        },
        {
          label: "Last Updated",
          id: "updated",
          value: new Date(data.updated).toLocaleDateString()
        }
      ];
    }
  };

  UserService.me = function() {
    return ApiService.Users.me();
  };

  UserService.search = function(text) {
    var query = {};
    var d = $q.defer();

    query.limit = 10;
    query.fields = {
      id: true,
      firstName: true,
      lastName: true,
      institution: true,
      email: true
    };

    query.where = {
      or: [
        { firstName: UtilitiesService.regexifyField(text) },
        { lastName: UtilitiesService.regexifyField(text) },
        { institution: UtilitiesService.regexifyField(text) },
        { email: UtilitiesService.regexifyField(text) }
      ]
    };

    ApiService.Users.get(query).then(function(result) {
      if (!result.data) return d.resolve([]);
      d.resolve(result.data);
    }, d.reject);

    return d.promise;
  };

  UserService.getById = function(id) {
    return ApiService.Users.getById(id);
  };

  UserService.getByIdWithIncludes = function(id) {
    return ApiService.Users.getByIdWithIncludes(id);
  };

  UserService.get = function(query) {
    return ApiService.Users.get(query);
  };

  UserService.count = function(query) {
    return ApiService.Users.count(query);
  };

  UserService.getByEmail = function(email) {
    return ApiService.Users.getByEmail(email).then(function(result) {
      if (result.data && result.data.length === 0) {
        return $q.reject();
      } else return result;
    });
  };

  UserService.getDevices = function(id) {
    return ApiService.Users.getDevices(id);
  };

  /**
   * Delete all devices for a particular user
   * @param userId
   * @returns {*}
   */
  UserService.deleteAllDevices = function(userId) {
    return ApiService.Users.deleteAllDevices(userId);
  };

  UserService.getRefreshTokens = function(id) {
    return ApiService.Users.getRefreshTokens(id);
  };

  /**
   * Delete all refresh tokens for a particular user
   * @param userId
   * @returns {*}
   */
  UserService.deleteAllRefreshTokens = function(userId) {
    return ApiService.Users.deleteAllRefreshTokens(userId);
  };

  UserService.deleteRefreshTokenByExp = function(userId, expDate) {
    return ApiService.Users.deleteRefreshTokenByExp(userId, expDate);
  };

  UserService.changePassword = function changePassword(
    userId,
    password,
    confirm
  ) {
    return wrap(
      ApiService.Users.changePassword(userId, password, confirm),
      "Update"
    );
  };

  UserService.updateUser = function updateUser(userId, data) {
    return wrap(ApiService.Users.update(userId, data), "Update");
  };

  UserService.delete = function(userId) {
    return wrap(ApiService.Users._delete(userId), "Delete");
  };

  UserService.enable = function(userId) {
    return wrap(ApiService.Users.enable(userId), "Enable");
  };

  UserService.getTransactions = function(userId, query) {
    return ApiService.Users.getTransactions(userId, query);
  };

  UserService.getTransactionsCount = function(userId) {
    return ApiService.Users.getTransactionsCount(userId);
  };

  UserService.getActivityLogs = function(userId, query) {
    return ApiService.Users.getActivityLogs(userId, query);
  };

  UserService.getActivityLogsCount = function(userId) {
    return ApiService.Users.getActivityLogsCount(userId);
  };

  UserService.register = function(data) {
    if (!data) return;
    switch (data.role) {
      case "Student":
        return wrap(ApiService.Users.registerStudent(data), "Create");
      case "Teacher":
        return wrap(ApiService.Users.registerTeacher(data), "Create");
      case "Public":
        return wrap(ApiService.Users.registerPublic(data), "Create");
      default:
        throw new Error("Unknown role!");
    }
  };

  return UserService;
}
