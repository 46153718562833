import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { selectSessionData, changeRole } from "../redux/reducers/session";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

export const RoleOverride = compose(
  connect(state => ({ value: selectSessionData(state).role }), {
    onChange: (e, i, v) => changeRole(v)
  })
)(({ ...other }) => (
  <SelectField floatingLabelText="Role Override" {...other}>
    <MenuItem primaryText="Admin" value="Admin" />
    <MenuItem primaryText="ResellerAdmin" value="ResellerAdmin" />
    <MenuItem primaryText="GroupAdmin" value="GroupAdmin" />
    <MenuItem primaryText="SchoolAdmin" value="SchoolAdmin" />
    <MenuItem primaryText="Teacher" value="Teacher" />
    <MenuItem primaryText="Student" value="Student" />
  </SelectField>
));
