"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scrollModes = {
  HORIZONTAL: "HORIZONTAL",
  VERTICAL: "VERTICAL",
  GRID: "GRID"
};
exports.scrollModeValues = Object.values(exports.scrollModes);
exports.qualityOptions = {
  HIGHEST: 16777216,
  MIDDLE: 1048576,
  LOWEST: 262144
};
exports.qualityOptionValues = Object.values(exports.qualityOptions);
exports.scaleModes = {
  "100": 1,
  "125": 1.25,
  "150": 1.5,
  "175": 1.75,
  "200": 2,
  "250": 2.5,
  "300": 3,
  "400": 4
};
exports.maxScale = 4;
exports.minScale = 1;
exports.scaleStep = 0.1;
exports.renderingStates = {
  INITIAL: 0,
  RUNNING: 1,
  PAUSED: 2,
  FINISHED: 3
};
exports.domCursors = {
  help: "help",
  wait: "wait",
  crosshair: "crosshair",
  notAllowed: "not-allowed",
  pointer: "pointer",
  initial: "initial",
  auto: "auto",
  text: "text"
};
exports.CursorMode = {
  RECTANGLE: "RECTANGLE",
  OVAL: "OVAL",
  FREE_DRAW: "FREE_DRAW",
  LINE: "LINE",
  ANNOTATE_TEXT: "ANNOTATE_TEXT",
  EDIT: "EDIT_ANNOTATION"
};
exports.orientations = {
  PORTRAIT: "PORTRAIT",
  LANDSCAPE: "LANDSCAPE"
};
exports.bookTypes = {
  PDF: "pdf",
  EPUB: "epub"
};
exports.bookTypeExtensions = {
  PDF: ".pdf",
  EPUB: ".epub"
};
exports.fontFamilies = {
  Arial: "Arial",
  Courier: "Courier",
  Helvetica: "Helvetica",
  TimesNewRoman: "Times New Roman"
};
exports.fontSizes = {
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "14": 14,
  "16": 16,
  "18": 18,
  "20": 20,
  "24": 24,
  "28": 28,
  "32": 32,
  "36": 36
};
exports.colorOptions = {
  orange: "#FF6900",
  yellow: "#FCB900",
  green: "#00D084",
  blue: "#0693E3",
  red: "#EB144C",
  purple: "#9900EF"
};
exports.textAlignments = {
  left: "left",
  center: "center",
  right: "right",
  justify: "justify"
};
exports.scrollDirections = {
  forwards: "forwards",
  backwards: "backwards"
};
exports.ENC_METHODS = ["None", "aes-256-ctr", "aes-256-cbc"];
exports.findStates = {
  FOUND: 0,
  NOT_FOUND: 1,
  WRAPPED: 2,
  PENDING: 3
};
exports.documentProcessStates = {
  Initial: "Initial",
  Downloading: "Loading",
  Processing: "Processing",
  Loaded: "Loaded"
};
exports.errorTypes = {
  InstantiationError: "InstantiationError",
  ProcessError: "ProcessError",
  DownloadError: "DownloadError",
  DocumentError: "DocumentError"
};
exports.errorMessages = {
  InstantiationError: "Could not instantiate the reader",
  DocumentError: "Could not open the document",
  DownloadError: "Could not read the document",
  ProcessError: "An error occurred processing the document"
};
exports.contactEmail = "contact@readcloud.com";
exports.pageViewModes = {
  OnePage: "OnePage",
  TwoPages: "TwoPages"
};
exports.pageViewModeValues = Object.values(exports.pageViewModes);