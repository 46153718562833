import { composeReducer, scopeReducers, createFactory } from "recrux";
import { makeApiCallAction, makeDefaultApiCallState } from "./shared";
const namespace = "session";
export const select = state => state[namespace];
export const selectLogin = state => select(state).login;
export const selectSessionData = state => selectLogin(state).data;

// Mainly for debugging purposes right now
export const changeRole = createFactory({
  namespace,
  actionName: "changeRole",
  reducer: (state, { payload }) => ({
    ...state,
    data: { ...state.data, role: payload }
  })
});

const defaultState = {
  login: makeDefaultApiCallState()
};
export const login = makeApiCallAction(namespace, "login");

export const sessionReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    scopeReducers({
      login: composeReducer(login.reducer, changeRole.reducer)
    })
  )
});
