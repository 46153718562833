import { userRoles } from "new/shared/data";
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { selectSessionData } from "new/redux/reducers/session";
import { omitProps } from "new/shared/hocs";
export const RoleSwitcherComponent = ({
  currentRole,
  Student,
  Teacher,
  ResellerAdmin,
  GroupAdmin,
  Admin,
  SchoolAdmin,
  Default,
  ...other
}) => {
  let Component;
  switch (currentRole) {
    case userRoles.Admin:
      Component = Admin ? Admin : Default;
      break;
    case userRoles.ResellerAdmin:
      Component = ResellerAdmin ? ResellerAdmin : Default;
      break;
    case userRoles.GroupAdmin:
      Component = GroupAdmin ? GroupAdmin : Default;
      break;
    case userRoles.SchoolAdmin:
      Component = SchoolAdmin ? SchoolAdmin : Default;
      break;
    case userRoles.Teacher:
      Component = Teacher ? Teacher : Default;
      break;
    case userRoles.Student:
      Component = Student ? Student : Default;
      break;
    default:
      Component = Default;
  }

  return <Component {...other} />;
};

export const RoleSwitcher = compose(
  connect(
    state => ({
      currentRole: selectSessionData(state).role
    }),
    null
  ),
  omitProps(["dispatch"])
)(RoleSwitcherComponent);
