RCMappedOrderCreator.$inject = ["ResellerService", "NotificationService"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcMappedOrderCreator", {
  controller: RCMappedOrderCreator,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-mapped-order-creator/template.html").then(m => m.default),
  bindings: {
    onCreate: "<",
    resellerId: "<"
  }
});

/* @ngInject */
function RCMappedOrderCreator(ResellerService, NotificationService) {
  var vm = this;

  vm.fields = [
    {
      label: "Name",
      id: "name",
      required: true
    }
  ];

  vm.submit = function(data) {
    return ResellerService.addMappedOrder(vm.resellerId, data).then(
      function() {
        if (vm.onCreate) vm.onCreate(data);
        NotificationService.toastError("Created successfully");
      },
      function(result) {
        NotificationService.failed(
          "Could not create mapped order" +
            (result &&
            result.data &&
            result.data.error &&
            result.data.error.message
              ? ": " + result.data.error.message
              : "")
        );
      }
    );
  };
}
