ctrl.$inject = ["ActionService"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcActionTypeSelector", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-actions-manager/rc-action-type-selector/template.html").then(m => m.default),
  bindings: {
    initial: "<",
    onSelectedChanged: "<",
    required: "<",
    disabled: "<"
  }
});

function ctrl(ActionService) {
  var vm = this;

  vm.selected = vm.initial;

  vm.changed = function() {
    if (vm.onSelectedChanged && vm.selected !== vm.initial)
      vm.onSelectedChanged(vm.selected);
  };

  ActionService.getTypes().then(function(result) {
    vm.options = result.data;
  });
}
