"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function debounce(func, waitMilliseconds, options) {
  if (waitMilliseconds === void 0) {
    waitMilliseconds = 50;
  }

  if (options === void 0) {
    options = {
      isImmediate: false
    };
  }

  var timeoutId;
  return function () {
    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    var context = this;

    var doLater = function doLater() {
      timeoutId = undefined;

      if (!options.isImmediate) {
        func.apply(context, args);
      }
    };

    var shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  };
}

exports.debounce = debounce;