ctrl.$inject = [
  "ActionService",
  "NotificationService",
  "$element",
  "UtilitiesService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcActionCreator", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-actions-manager/rc-action-creator/template.html").then(m => m.default),
  bindings: {
    onSuccess: "<"
  }
});
/* @ngInject */
function ctrl(ActionService, NotificationService, $element, UtilitiesService) {
  var vm = this;

  var notifier = NotificationService.toastIt("Create", "Action");
  vm.fields = [
    {
      label: "Name",
      id: "name",
      required: true
    },
    {
      label: "Entity",
      id: "entity",
      options: ActionService.getEntities,
      required: true
    },
    {
      label: "URL",
      id: "url",
      required: true
    },
    {
      label: "Type",
      id: "type",
      options: ActionService.getTypes,
      required: true
    }
  ];

  vm.submit = function(data) {
    var promise = ActionService.create(data);
    UtilitiesService.inlineLoader(promise, $element);
    promise.then(notifier, notifier);
    promise.then(function(result) {
      if (vm.onSuccess) vm.onSuccess(result);
    });

    return promise;
  };
}
