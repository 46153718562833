/**
 * Created by danie on 4/8/2016.
 */

const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUsersList", {
  controller: RCUsersList,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-users-list/rc-users-list.html").then(m => m.default),
  bindings: {
    data: "=",
    selectable: "=?",
    multiple: "=?",
    onAction: "=?",
    selected: "=?",
    promise: "=?",
    get: "=?",
    query: "=?"
  }
});

/* @ngInject */
function RCUsersList() {
  var vm = this;

  var selected = [];

  vm.userSelectedChanged = function(document, isChecked) {
    if (isChecked) selected.push(document);
    else {
      var i = selected.indexOf(document);

      if (i > -1) {
        selected.splice(i, 1);
      }
    }

    vm.selectedChanged(selected);
  };

  vm.fields = [
    {
      label: "Email",
      id: "email"
    },
    {
      label: "First Name",
      id: "firstName"
    },
    {
      label: "Last Name",
      id: "lastName"
    },
    {
      label: "Institution",
      id: "institution"
    },
    {
      label: "Role",
      id: "role"
    },
    {
      label: "Last Login",
      id: "loginDateTime"
    }
  ];
}
