"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var wwindow = typeof window !== "undefined" ? window : undefined;
var atob = wwindow ? window.atob : Buffer ? function (str) {
  return Buffer.from(str, "base64").toString();
} : null;

if (!atob) {
  throw new Error("Invalid environment, could not make an 'atob' function");
}

exports.decodeJwt = function (jwt) {
  var split = jwt.split(".");
  return {
    header: JSON.parse(atob(split[0])),
    payload: JSON.parse(atob(split[1]))
  };
};