/**
 * Created by danie on 11/02/2016.
 */

InstitutionService.$inject = ["ApiService", "$q", "AuthenticationService", "UtilitiesService"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("InstitutionService", InstitutionService);

/* @ngInject */
function InstitutionService(ApiService, $q, AuthenticationService, UtilitiesService) {
  var InstitutionService = {};

  InstitutionService.getById = function(id) {
    if (!id) return;
    var deferred = $q.defer();

    ApiService.Institutions
      .getById(id)
      .then(function(result) {
        deferred.resolve(result.data);
      })
      .catch(function(reason) {
        deferred.reject(reason);
      });

    return deferred.promise;
  };

  InstitutionService.getByName = function(name) {
    if (!name) return;
    var deferred = $q.defer();

    ApiService.Institutions
      .getByName(name)
      .then(function(result) {
        deferred.resolve(result.data);
      })
      .catch(function(reason) {
        deferred.reject(reason);
      });

    return deferred.promise;
  };

  InstitutionService.updateOne = function(id, data) {
    if (!id) return;
    if (!data) return;

    var promise = ApiService.Institutions.updateOne(id, data);

    return promise;
  };

  InstitutionService.getAll = function() {
    var deferred = $q.defer();

    switch (AuthenticationService.auth.user.role) {
      case "SchoolAdmin":
        ApiService.Institutions
          .getById(AuthenticationService.auth.user.institution)
          .then(function(result) {
            deferred.resolve(result.data);
          })
          .catch(deferred.reject);
        break;
      default:
        deferred.reject("You do not have authorization to acess the Institution Manager");
        break;
    }

    return deferred.promise;
  };

  InstitutionService.get = function(query) {
    return ApiService.Institutions.get(query);
  };

  InstitutionService.count = function(query) {
    return ApiService.Institutions.count(query);
  };

  InstitutionService.create = function(payload) {
    return ApiService.Institutions.create(payload);
  };

  InstitutionService.search = function(text) {
    var query = {};
    var d = $q.defer();

    query.limit = 10;
    query.fields = {
      name: true,
      description: true
    };

    query.where = {
      or: [
        { name: UtilitiesService.regexifyField(text) },
        { description: UtilitiesService.regexifyField(text) }
      ]
    };

    ApiService.Institutions.get(query).then(function(result) {
      if (!result.data) return d.resolve([]);
      d.resolve(result.data);
    }, d.reject);

    return d.promise;
  };

  InstitutionService.setToDemo = function(id) {
    return InstitutionService.updateOne(id, {
      status: "Demo"
    });
  };

  InstitutionService.setToProduction = function(id) {
    return InstitutionService.updateOne(id, {
      status: "Production"
    });
  };

  InstitutionService.createDemoClouds = function(id, emailList, createDemoUsers) {
    return ApiService.Institutions.createDemoClouds(id, emailList, createDemoUsers);
  };

  InstitutionService.getList = function getList() {
    return ApiService.Institutions.get({
      fields: {
        id: true,
        name: true
      }
    });
  };

  InstitutionService.registerInstitution = function(data) {
    return ApiService.Institutions.registerInstitution(data);

  };

  return InstitutionService;
}
