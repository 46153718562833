/**
 * Created by danie on 4/4/2016.
 */

const angular = require("angular");
angular.module("readCloudWebConsole").component("rcBook", {
  controller: RCBook,
  controllerAs: "vm"
});

/* @ngInject */

function RCBook() {}
