/**
 * Created by danie on 9/02/2016.
 */

UtilitiesService.$inject = [
  "$mdDialog",
  "$interval",
  "$compile",
  "$rootScope",
  "NotificationService",
  "FileSaver",
  "Blob"
];
const angular = require("angular");
angular.module("readCloudWebConsole").service("UtilitiesService", UtilitiesService);

/* @ngInject */
function UtilitiesService(
  $mdDialog,
  $interval,
  $compile,
  $rootScope,
  NotificationService,
  FileSaver,
  Blob
) {
  var UtilitiesService = {};

  var spinner = $compile("<rc-spinner></rc-spinner>")($rootScope);

  var loadingModal = {
    template: require("app/components/loading-modal.html"),
    escapeToClose: true
  };

  /**
     * This function is not going to be synchronous and could be weird
     * @param parent
     * @returns {*}
       */
  //TODO: fix synchronicity with this function
  function showLoader(parent) {
    if (parent) loadingModal.parent = parent;
    else delete loadingModal.parent;
    return $mdDialog.show(loadingModal);
  }

  function hideLoader() {
    return $mdDialog.hide(loadingModal);
  }

  //TODO: We need a way to make sure that loading modals dont stack, and that when hide is called, it actually works...

  UtilitiesService.showLoader = showLoader;

  UtilitiesService.hideLoader = hideLoader;

  UtilitiesService.promiseLoader = function(promise) {
    showLoader();
    promise.finally(hideLoader);

    var i = $interval(function() {
      if (promise.$$state.status !== 0) {
        //eslint-disable-line
        hideLoader();
        $interval.cancel(i);
      }
    }, 1000);
  };

  // Convert query conditions to Strongloop Where filter, combined with loosely contains regex ('.*\Q \E*.')
  UtilitiesService.regexifyWhereFields = function(fields) {
    var output = {};
    if (Object.keys(fields).length) {
      for (var c in fields) {
        if (fields.hasOwnProperty(c) && typeof fields[c] != "object") {
          output[c] = {
            //regexp: '/.*\\Q' + fields[c] + '\\E.*/i'
            regexp: new RegExp(".*" + fields[c] + ".*", "i").toString()
          };
        }
      }
    }

    return output;
  };

  UtilitiesService.regexifyField = function(text) {
    return {
      regexp: "/.*\\Q" + text + "\\E.*/i"
    };
  };

  UtilitiesService.inlineLoader = function(promise, element) {
    if (
      element &&
      element.append &&
      element.remove &&
      promise &&
      promise.finally &&
      promise.$$state.status === 0
    ) {
      //eslint-disable-line

      element.append(spinner);
      promise.finally(function() {
        spinner.remove();
      });
    }
    return promise;
  };

  UtilitiesService.getLoopbackOrder = function(field) {
    return field.toString().charAt(0) === "-"
      ? field.toString().substr(1, field.length) + " DESC"
      : field + " ASC";
  };

  UtilitiesService.promiseAssign = function promiseAssign(promise, asignee, field) {
    return promise.then(function(result) {
      asignee[field] = result.data;
    });
  };

  UtilitiesService.getPromiseWrapper = function(modelName) {
    return function promiseWrapper(promise, action) {
      var cb = NotificationService.toastIt(action, modelName);

      promise.then(cb, cb);
      return promise;
    };
  };

  UtilitiesService.convertHtmlStringToText = function(string) {
    return angular
      .element("<div/>")
      .html(string)
      .text();
  };

  UtilitiesService.copy = function(data) {
    return JSON.parse(JSON.stringify(data));
  };

  UtilitiesService.saveXMLAsFile = function(data, fileName) {
    var out = new Blob([data], { type: "text/xml;charset=utf-8" });
    FileSaver.saveAs(out, fileName);
  };

  UtilitiesService.decodeJwt = function(jwtString) {
    return JSON.parse(atob(jwtString.split(".")[1]));
  };

  return UtilitiesService;
}
