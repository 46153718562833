import React from "react";
import { BehaviorSubject } from "rxjs";
import { useObservable } from "rxjs-hooks";
import { BasicDialog } from "./BasicDialog";
const dialogStateSubject = new BehaviorSubject({ open: false });
dialogStateSubject.subscribe(() => null);

export function DialogController(props) {
  const dialogState = useObservable(() => dialogStateSubject);
  return dialogState && dialogState.render ? (
    <BasicDialog
      {...dialogState}
      onRequestClose={() => dialogStateSubject.next({ open: false })}
    />
  ) : null;
}

export const showDialog = (title, render, other = {}) => {
  dialogStateSubject.next({
    ...dialogStateSubject.getValue(),
    open: true,
    render,
    title,
    ...other
  });
};
