/**
 * Created by danie on 5/2/2016.
 */

RCModelEditor.$inject = [
  "$scope",
  "_",
  "NotificationService",
  "$element",
  "$window"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcModelEditor", {
  controller: RCModelEditor,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-model-editor/template.html").then(m => m.default),
  bindings: {
    submit: "<",
    fields: "<",
    data: "<",
    createMode: "<",
    onCancel: "<",
    cancel: "=?"
  }
});

/* @ngInject */

function RCModelEditor($scope, _, NotificationService, $element, $window) {
  var vm = this;

  vm.$onInit = () => {
    vm.fieldOptions = {};
    vm.data = vm.data || {};
    vm.isFunction = angular.isFunction;
    if (vm.createMode) vm.editMode = true;
    vm.output = {};
  };

  $scope.$watch("vm.data", function() {
    vm.viewModel = copy(vm.data || {});
    if (vm.createMode) {
      vm.output = copy(vm.data || {});
    }
  });

  $scope.$watch("vm.fields", function() {
    _(vm.fields).forEach(function(field) {
      if (field.options && _.isFunction(field.options)) {
        vm.loadFieldOptions(field.id, field.options);
      }
    });
  });

  function copy(obj) {
    if (!obj) return null;
    var out = {};
    var keys = Object.keys(obj);
    for (var i in keys) {
      out[keys[i]] = obj[keys[i]];
    }
    return out;
  }

  vm.canSubmit = function() {
    if ($scope.editor)
      return (
        $scope.editor.$dirty &&
        !_(vm.fields)
          .filter(function(o) {
            return o.required === true && vm.viewModel && !vm.viewModel[o.id];
          })
          .value().length
      );
  };

  vm.cancel = function() {
    if (vm.canSubmit())
      NotificationService.areYouSure(
        "Model editor",
        "Are you sure you want to cancel? (Your changes will be lost)"
      ).then(function() {
        vm.reset();
      });
    else vm.reset();
  };

  vm.reset = function() {
    if (!vm.createMode) {
      vm.viewModel = copy(vm.data);
      vm.editMode = false;
    }

    try {
      vm.onCancel();
    } catch (e) {}
  };

  vm.sanitize = function sanitize(data) {
    // console.log(data);
    var keys = Object.keys(data);
    for (var i in keys) {
      var key = keys[i];
      if (data[key]) data[key] = data[key].toString();
    }
    return _.omitBy(data, _.flow(_.isUndefined, _.isNull));
  };

  vm.modelChanged = function(fieldId) {
    vm.output[fieldId] = vm.viewModel[fieldId];
  };

  vm.loadFieldOptions = function(id, func) {
    func().then(function(result) {
      if (_.isArray(result.data)) {
        vm.fieldOptions[id] = result.data;
      }
      if (_.isArray(result)) {
        vm.fieldOptions[id] = result;
      }
    });
  };

  vm.onSubmit = function() {
    if (vm.submit) {
      vm.submit(vm.sanitize(vm.output)).then(function() {
        vm.reset();
      });
    }
  };
}
