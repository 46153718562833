/**
 * Created by danie on 7/22/2016.
 */

BookService.$inject = ["ApiService", "UtilitiesService"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("BookService", BookService);

/*@ngInject*/
function BookService(ApiService, UtilitiesService) {
  var BookService = {};

  BookService.search = function search(query) {
    var modQuery = UtilitiesService.copy(query);

    modQuery.where = UtilitiesService.regexifyWhereFields(modQuery.where);
    return ApiService.Books.search(modQuery);
  };

  BookService.find = query => {
    var modQuery = UtilitiesService.copy(query);

    modQuery.where = UtilitiesService.regexifyWhereFields(modQuery.where);
    return ApiService.Books.find(modQuery);
  };

  BookService.searchCount = function count(query) {
    var modQuery = UtilitiesService.copy(query);
    modQuery.where = UtilitiesService.regexifyWhereFields(modQuery.where);
    return ApiService.Books.count(modQuery);
  };

  return BookService;
}
