import { composeReducer, createFactory } from "recrux";
import { scopeReducers } from "recrux";

const defaultState = {
  message: null,
  open: false
};

const namespace = "snackbar";
export const showMessage = createFactory({
  namespace,
  actionName: "showMessage",
  reducer: (state, { payload }) => ({
    ...state,
    message: payload,
    open: true
  })
});

export const hideMessage = showMessage.assign({
  actionName: "hideMessage",
  reducer: state => ({
    ...state,
    message: null,
    open: false
  })
});

export const snackbarReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    showMessage.reducer
  )
});

export const select = state => state[namespace];
