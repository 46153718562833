import { withContext, getContext } from "recompose";
import pt from "prop-types";
export const injectServices = withContext(
  {
    AuthenticationService: pt.any,
    DialogService: pt.any,
    RouteService: pt.any,
    HistoryService: pt.any
  },
  ({ services }) => ({ ...services })
);

export const withServices = names =>
  getContext(names.reduce((acc, name) => ({ ...acc, [name]: pt.any }), {}));
