/**
 * Created by danie on 5/5/2016.
 */
import angular from "angular";
RCInstitutionCreatorModalController.$inject = ["$mdDialog", "RouteService"];

angular
  .module("readCloudWebConsole")
  .controller("RCInstitutionCreatorModalController", RCInstitutionCreatorModalController);

/* @ngInject */
function RCInstitutionCreatorModalController($mdDialog, RouteService) {
  var vm = this;
  vm.close = $mdDialog.hide;
  vm.success = function(data) {
    if (data && data.id) RouteService.admin.institutionEdit(data.id);
  };
}
