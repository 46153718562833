import "./index.constants";
import "./index.config";

import "./index.route";
import "./index.run";

//Scripts
//importAll(require.context("./routes", true, /\.js$/));
importAll(require.context("./components", true, /\.js$/));
importAll(require.context("./modals", true, /\.js$/));
importAll(require.context("./services", true, /\.js$/));
//importAll(require.context("./components", true, /\.html$/));
//importAll(require.context("./routes", true, /\.html$/));
importAll(require.context("./modals", true, /\.html$/));

//Styles
import("./index.css");
import("angular-material/angular-material.min.css");
import("angular-material-data-table/dist/md-data-table.min.css");

function importAll(r) {
  r.keys().forEach(r);
}
