"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.addLog = function (log) {
  return index_1.getApiv14().post("/add/log", log);
};

exports.searchLog = function (logSearch) {
  return index_1.getApiv14().get("/search/log", {
    params: logSearch
  });
};