"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var index_1 = require("./index");

exports.resellerPurchase = function (userBooks, apiKey, config) {
  return index_1.getApiv14().post("/reseller/update/user", userBooks, tslib_1.__assign(tslib_1.__assign({}, config), {
    params: tslib_1.__assign(tslib_1.__assign({}, config ? config.params : {}), {
      apiKey: apiKey
    })
  }));
};

exports.resellerDeletePurchases = function (userBooks, apiKey, config) {
  return index_1.getApiv14().post("/reseller/update/user", tslib_1.__assign(tslib_1.__assign({}, userBooks), {
    contentAction: "delete"
  }), tslib_1.__assign(tslib_1.__assign({}, config), {
    params: tslib_1.__assign(tslib_1.__assign({}, config ? config.params : {}), {
      apiKey: apiKey
    })
  }));
};

exports.addBook = function (req, config) {
  return index_1.getApiv14().post("/add/book", req, config);
};

exports.listBooks = function (userEmail, config) {
  if (userEmail === void 0) {
    userEmail = "";
  }

  return index_1.getApiv14().get("/list/book.json", tslib_1.__assign(tslib_1.__assign({}, config), {
    params: {
      userEmail: userEmail
    }
  }));
};

exports.deltaBooks = function (millisec, config) {
  return index_1.getApiv14().get("/delta/books", tslib_1.__assign({
    params: {
      millisec: millisec
    }
  }, config));
};

exports.searchBook = function (searchKey, searchString, config) {
  return index_1.getApiv14().get("/search/book", tslib_1.__assign({
    params: {
      searchKey: searchKey,
      searchString: searchString
    }
  }, config));
};

exports.searchBookAdvanced = function (req, config) {
  return index_1.getApiv14().post("/search/advanced/book", req, config);
};

exports.getPublishers = function (userEmail, config) {
  if (userEmail === void 0) {
    userEmail = "";
  }

  return index_1.getApiv14().get("/get_publishers", tslib_1.__assign(tslib_1.__assign({}, config), {
    params: {
      userEmail: userEmail
    }
  }));
};

exports.getResellers = function (userEmail, config) {
  if (userEmail === void 0) {
    userEmail = "";
  }

  return index_1.getApiv14().get("/get_resellers", tslib_1.__assign(tslib_1.__assign({}, config), {
    params: {
      userEmail: userEmail
    }
  }));
};