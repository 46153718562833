import getMuiTheme from "material-ui/styles/getMuiTheme";

export const colors = {
  primary: "#0056a4",
  secondary: "#00a9a9",
  accent: "#EF8354",
  primary2: "#0B3954",
  primary3: "#BFD7EA"
};

export const muiTheme = getMuiTheme({
  palette: {
    primary1Color: colors.primary,
    primary2Color: colors.primary2,
    primary3Color: colors.primary3,
    accent1Color: colors.accent
  }
});
