import { app } from "./app/app";
import { Root } from "./app/new-components/Root";
import { stringify as toYmlString } from "json2yaml";
import * as stringify from "json-stringify-safe";
import React from "react";
import { compose } from "ramda";
import moment from "moment";

export const fcatch = catcher => promise => promise.catch(catcher);
export const wrapRoot = (Component, services) => props => (
  <Root services={services}>{React.createElement(Component, props)}</Root>
);
export const registerNg = (Component, name) => {
  const directive = (
    reactDirective,
    RouteService,
    DialogService,
    AuthenticationService,
    HistoryService
  ) =>
    reactDirective(
      wrapRoot(Component, {
        RouteService,
        DialogService,
        AuthenticationService,
        HistoryService
      })
    );
  directive.$inject = [
    "reactDirective",
    "RouteService",
    "DialogService",
    "AuthenticationService",
    "HistoryService"
  ];

  app.directive(name, directive);
};
export const toYml = input =>
  typeof input === "string"
    ? input
    : compose(
        obj => toYmlString(obj, 10, 2),
        JSON.parse,
        stringify
      )(input);

export const nextChristmas = () => {
  const current = moment();
  const thisYearChristmas = moment().set({ month: 11, date: 25 });
  const nextYearChristmas = moment().set({
    month: 11,
    date: 25,
    year: current.get("year") + 1
  });
  return current.isAfter(thisYearChristmas)
    ? nextYearChristmas
    : thisYearChristmas;
};
