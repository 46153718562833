"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isTouchDevice() {
  var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

  var mq = function mq(queryString) {
    return window.matchMedia(queryString).matches;
  };

  if ("ontouchstart" in window || window.DocumentTouch && document instanceof window.DocumentTouch) {
    return true;
  } // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH


  var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
  return mq(query);
}

exports.isTouchDevice = isTouchDevice;

exports.isIOS = function () {
  return !!navigator.platform && /ipad|iphone|ipod/i.test(navigator.platform);
};

exports.isAndroid = function () {
  return /android/i.test(navigator.platform);
};

exports.isChromebook = function () {
  return /(chromebook| cros )/i.test(navigator.userAgent);
};

exports.isWebApp = function (platform) {
  return ["web", "lti", "office"].includes(platform);
};

exports.isNativeApp = function (platform) {
  return ["windows", "osx", "ios", "android"].includes(platform);
};

exports.isFirefox = function () {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

exports.isEdge = function () {
  return navigator.userAgent.toLowerCase().indexOf("edge") > -1 || navigator.userAgent.toLowerCase().indexOf("edg") > -1;
};