/**
 * Created by Daniel on 6/22/2016.
 */

RCResellersSelector.$inject = ["ResellerService", "UtilitiesService"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcResellersSelector", {
  controller: RCResellersSelector,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-resellers-selector/template.html").then(m => m.default),
  bindings: {
    initial: "<",
    onSelectedChanged: "<",
    required: "<"
  }
});

/*@ngInject*/
function RCResellersSelector(ResellerService, UtilitiesService) {
  var vm = this;

  vm.selected = vm.initial;
  vm.get = function() {
    return ResellerService.get();
  };

  vm.get().then(function(result) {
    if (result.data) {
      vm.resellers = result.data;
    }
  });

  vm.changed = function() {
    if (vm.onSelectedChanged) vm.onSelectedChanged(vm.selected);
  };
}
