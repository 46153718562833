import { scopeReducers, createFactory, composeReducer } from "recrux";
import storage from "redux-persist/lib/storage";
import { createMigrate, persistReducer } from "redux-persist";

const migrations = {
  1: state => ({ ...state, locked: true })
};

const namespace = "sidebar";
const defaultState = {
  openNodes: {},
  locked: true
};

const persistConfig = {
  key: namespace,
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: false })
};

export const toggle = createFactory({
  namespace,
  actionName: "toggle",
  reducer: (state, { payload }) => ({
    ...state,
    openNodes: {
      ...state.openNodes,
      [payload]: !state.openNodes[payload]
    }
  })
});

export const toggleLock = createFactory({
  namespace,
  actionName: "toggleLock",
  reducer: (state, { payload }) => ({
    ...state,
    locked: !state.locked
  })
});

export const sidebarReducer = scopeReducers({
  [namespace]: persistReducer(
    persistConfig,
    composeReducer(
      (state = defaultState) => state,
      toggle.reducer,
      toggleLock.reducer
    )
  )
});

export const select = state => state[namespace];

export const selectOpenNodes = state => select(state).openNodes;
