"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _1 = require(".");

exports.userActivity = function (userActivities, config) {
  return _1.getApiv14().post("/user_activity", userActivities, config);
};