RCOnixGeneratorModalController.$inject = ["$mdDialog"];
const angular = require("angular");
angular
  .module("readCloudWebConsole")
  .controller("RCOnixGeneratorModalController", RCOnixGeneratorModalController);

function RCOnixGeneratorModalController($mdDialog) {
  var vm = this;
  vm.close = $mdDialog.hide;
}
