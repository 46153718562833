"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var yup_1 = require("yup");

exports.isFieldRequired = function (path, schema, values) {
  try {
    var subSchema = yup_1.reach(schema, path, values);
    return !!subSchema.describe().tests.find(function (t) {
      return t && t.name === "required";
    });
  } catch (e) {
    return false;
  }
};

exports.getFieldNamesForSchemaDescription = function (rootSchema, values, currentDescription, currentPath) {
  if (currentPath === void 0) {
    currentPath = "";
  }

  if (currentDescription.type === "object") {
    var fieldValues = Object.keys(currentDescription.fields);
    return fieldValues.flatMap(function (key) {
      var path = "" + (currentPath ? currentPath + "." : "") + key;

      try {
        return exports.getFieldNamesForSchemaDescription(rootSchema, values, yup_1.reach(rootSchema, path, values).describe(), path);
      } catch (e) {
        return [];
      }
    });
  } else {
    return [currentPath];
  }
};

exports.getFieldNamesForSchema = function (schema, values) {
  var desc = yup_1.reach(schema, "", values).describe();
  return exports.getFieldNamesForSchemaDescription(schema, values, desc);
};