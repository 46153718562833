import angular from "angular";
import moduleName from "./moduleName";
import moment from "moment";
import { getAccessToken } from "@readcloud/api-client/build/client";
/* @ngInject */
AuthHTTPInterceptor.$inject = [
  "$localStorage",
  "$q",
  "AUTH_EVENTS",
  "$rootScope"
];
export function config(
  $logProvider,
  $mdThemingProvider,
  $mdDateLocaleProvider,
  $httpProvider,
  $uiViewScrollProvider,
  AnalyticsProvider,
  $compileProvider
) {
  // https://toddmotto.com/angular-1-6-is-here#component-and-oninit - Need to do this so that 1.6 works
  $compileProvider.preAssignBindingsEnabled(true);

  //Analytics
  AnalyticsProvider.setAccount("UA-62081235-5");
  //Tell ui router to use anchor scroll
  $uiViewScrollProvider.useAnchorScroll();

  // Enable log
  $logProvider.debugEnabled(true);

  /*
          Angular Material Theme Config
       */

  $mdThemingProvider.definePalette("ReadCloud-Primary", {
    "50": "#aed9ff",
    "100": "#62b4ff",
    "200": "#2a99ff",
    "300": "#0076e1",
    "400": "#0066c3",
    "500": "#0056a4",
    "600": "#004685",
    "700": "#003667",
    "800": "#002648",
    "900": "#00162a",
    A100: "#aed9ff",
    A200: "#62b4ff",
    A400: "#0066c3",
    A700: "#003667",
    contrastDefaultColor: "light",
    contrastDarkColors: "50 100 200 A100 A200"
  });

  $mdThemingProvider.definePalette("ReadCloud-Secondary", {
    "50": "#b3ffff",
    "100": "#67ffff",
    "200": "#2fffff",
    "300": "#00e6e6",
    "400": "#00c8c8",
    "500": "#00a9a9",
    "600": "#008a8a",
    "700": "#006c6c",
    "800": "#004d4d",
    "900": "#002f2f",
    A100: "#b3ffff",
    A200: "#67ffff",
    A400: "#00c8c8",
    A700: "#006c6c",
    contrastDefaultColor: "light",
    contrastDarkColors: "50 100 200 300 400 A100 A200 A400"
  });

  $mdThemingProvider
    .theme("ReadCloud-Default")
    .primaryPalette("ReadCloud-Primary")
    .accentPalette("ReadCloud-Secondary");

  $mdThemingProvider.setDefaultTheme("ReadCloud-Default");

  // FORMAT THE DATE FOR THE DATEPICKER
  $mdDateLocaleProvider.formatDate = function(date) {
    return moment(date).format("DD-MMM-YYYY"); //$filter('date')(date, "dd-MM-yyyy");
  };

  $httpProvider.interceptors.push(AuthHTTPInterceptor);

  // Set options third-party lib
  /* toastrConfig.allowHtml = true;
      toastrConfig.timeOut = 3000;
      toastrConfig.positionClass = 'toast-top-right';
      toastrConfig.preventDuplicates = true;
      toastrConfig.progressBar = true;*/
}
config.$inject = [
  "$logProvider",
  "$mdThemingProvider",
  "$mdDateLocaleProvider",
  "$httpProvider",
  "$uiViewScrollProvider",
  "AnalyticsProvider",
  "$compileProvider"
];

/* @ngInject */
function AuthHTTPInterceptor($localStorage, $q, AUTH_EVENTS, $rootScope) {
  return {
    request: function(config) {
      if (getAccessToken())
        config.headers.Authorization = "Bearer " + getAccessToken();

      return config;
    },

    response: function(response) {
      function updateJWT(token) {
        $rootScope.$emit(AUTH_EVENTS.receivedJWT, token);
      }

      function receivedRefreshToken(token) {
        $rootScope.$emit(AUTH_EVENTS.receivedRefreshToken, token);
      }

      if (response.data && response.data.access_token) {
        updateJWT(response.data.access_token);
      }

      if (response.data && response.data.refresh_token) {
        receivedRefreshToken(response.data.refresh_token);
      }

      return response;
    },

    responseError: function(rejection) {
      var d = $q.defer();

      switch (rejection.status) {
        case -1:
          $rootScope.$emit(AUTH_EVENTS.corsError, rejection);
          break;
        case 401:
          $rootScope.$emit(AUTH_EVENTS.unauthorized, rejection);
          d.reject(rejection);
          break;
        default:
          break;
      }

      return d.promise;
    }
  };
}

//This decorator will allow you to specify a () => Promise<String> to a templateUrl field in components.
configureTemplateProxy.$inject = ["$provide"];
function configureTemplateProxy($provide) {
  templateProxy.$inject = ["$delegate"];
  function templateProxy($delegate) {
    return function(tpl) {
      if (tpl instanceof Promise) {
        return tpl;
      } else return $delegate(tpl);
    };
  }

  $provide.decorator("$templateRequest", templateProxy);
}

angular
  .module(moduleName)
  .config(config)
  .config(configureTemplateProxy);
