/**
 * Created by danie on 5/13/2016.
 */

RCSearchController.$inject = ["$scope"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcSearchController", {
  controller: RCSearchController,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-search-controller/template.html").then(m => m.default),
  bindings: {
    fields: "=",
    onSubmit: "<",
    noIncludeDeleted: "<"
  }
});

/* @ngInject */

function RCSearchController($scope) {
  var vm = this;

  vm.searchQuery = {
    where: {}
  };

  vm.promiseOptions = {};

  vm.isSearchQueryValid = function(query) {
    for (var i in query) {
      if (query[i]) return true;
    }

    return false;
  };

  $scope.$watch("vm.fields", function() {
    if (vm.fields.length) {
      for (var i in vm.fields) {
        var field = vm.fields[i];

        if (
          field.options &&
          field.options.then &&
          !vm.promiseOptions[field.id]
        ) {
          //eslint-disable-next-line
          field.options.then(function(result) {
            vm.promiseOptions[field.id] = result;
          });
        }
      }
    }
  });
}
