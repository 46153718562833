import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { defaultProps } from "recompose";
import { ApolloProvider as _ApolloProvider } from "react-apollo";
import { getAccessToken } from "@readcloud/api-client/build/client";
const client = new ApolloClient({
  link: setContext((_, { headers }) => ({
    headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` }
  })).concat(createHttpLink({ uri: "/v4/graphql" })),
  cache: new InMemoryCache()
});

export const ApolloProvider = defaultProps({ client })(_ApolloProvider);
