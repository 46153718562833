/**
 * Created by Daniel on 6/8/2016.
 */
TransactionService.$inject = ["ApiService"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("TransactionService", TransactionService);

/* @ngInject */
function TransactionService(ApiService) {
  var TransactionService = {};

  /**
   *
   * @param {Object} data
   * @param {string} data.id
   * @param {string} data.isbn
   * @param {string} data.publisher
   * @param {Date} data.date
   * @param {string} data.downloadURL
   * @param {string} data.storeID
   * @param {Date} data.purchaseDate
   * @param {number} data.priceIncGst
   * @param {string} data.currencyCode
   * @param {Date} data.bookExpireDate
     */
  TransactionService.model = function TransactionModel(data) {
    this.id = data.id;
    this.email = data.email;
    this.isbn = data.isbn;
    this.publisher = data.publisher;
    this.date = data.date;
    this.downloadURL = data.downloadURL;
    this.storeID = data.storeID;
    this.purchaseDate = data.purchaseDate;
    this.priceIncGst = data.priceIncGst;
    this.currencyCode = data.currencyCode;
    this.bookExpireDate = data.bookExpireDate;
  };

  TransactionService.repurchase = function repurchase(id) {
    return ApiService.Transactions.repurchase(id);
  };

  return TransactionService;
}
