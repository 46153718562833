ApplicationService.$inject = ["ApiService"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("ApplicationService", ApplicationService);

/*@ngInject*/
function ApplicationService(ApiService) {
  var ApplicationService = {};

  ApplicationService.getById = ApiService.Applications.getById;

  ApplicationService.getList = ApiService.Applications.getList;

  ApplicationService.deleteBuild = ApiService.Applications.deleteBuild;

  ApplicationService.getBuilds = ApiService.Applications.getBuilds;

  ApplicationService.addBuild = ApiService.Applications.addBuild;

  ApplicationService.getBuildCreatorFields = function(prefill) {
    if (!prefill) prefill = {};

    return [
      {
        label: "Version",
        id: "id",
        required: true,
        value: prefill.id
      },
      {
        label: "Download URL",
        id: "downloadURL",
        required: true,
        value: prefill.downloadUrl
      },
      {
        label: "Change Description",
        id: "changeDescription",
        value: prefill.changeDescription
      }
    ];
  };

  return ApplicationService;
}
