/**
 * Created by danie on 4/29/2016.
 */

rcDeviceManager.$inject = [
  "$scope",
  "UserService",
  "$q",
  "NotificationService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcDeviceManager", {
  controller: rcDeviceManager,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-device-manager/template.html").then(m => m.default),
  bindings: {
    userId: "="
  }
});

/* @ngInject */

function rcDeviceManager($scope, UserService, $q, NotificationService) {
  var vm = this;

  vm.data = [];
  vm.promise = {};
  vm.selected = [];

  /**
       * Get a promise notification callback function.
       * Return a function that should be passed as a promise callback for handling notifications
       */
  function getPromCB(action) {
    return NotificationService.toastIt(action, "Device");
  }

  function getCurrentDevices(userId) {
    return UserService.getDevices(userId);
  }

  function deleteAllDevices(userId) {
    return UserService.deleteAllDevices(userId);
  }

  function getData() {
    return (vm.promise = getCurrentDevices(vm.userId).then(function(result) {
      vm.data = result.data;
    }));
  }

  $scope.$watch("vm.userId", function() {
    if (vm.userId) {
      getData();
    }
  });

  function parseDate(value) {
    return new Date(value).toLocaleString();
  }

  vm.fields = [
    {
      label: "Device Id",
      id: "deviceId"
    },
    {
      label: "Source",
      id: "deviceIdSource"
    },
    {
      label: "OS",
      id: "osName"
    },
    {
      label: "Version",
      id: "osVersion"
    },
    {
      label: "App Version",
      id: "appVersion"
    },
    {
      label: "Created Date",
      id: "created",
      parse: parseDate
    },
    {
      label: "Updated Date",
      id: "updated",
      parse: parseDate
    }
  ];

  vm.refresh = function() {
    getData();
  };

  /**
       * Generate a list of promises for each selected device to be deleted, then execute.
       * Use the delete all if all are selected.
       */
  vm.deleteSelected = function() {
    var d = $q.defer();
    var promises = [];

    var cb = getPromCB("Delete");

    if (vm.selected.length === vm.data.length) {
      promises.push(deleteAllDevices(vm.userId));
    } else {
      NotificationService.success(
        "Deleting single device has not yet been implemented"
      );
      /*for(var i in vm.selected) {
            promises.push(UserService.deleteRefreshTokenByExp(vm.id,vm.selected[i].exp));
          }*/
    }
    $q.all(promises).then(cb, cb);

    return d.promise;
  };

  vm.deleteAll = function() {
    var cb = getPromCB("Delete");

    return NotificationService.areYouSure(
      "Delete all Devices",
      "This will delete all devices for this user."
    ).then(function() {
      UserService.deleteAllDevices(vm.userId).then(cb, cb);
      getData();
    });
  };
}
