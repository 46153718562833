"use strict";
/* tslint:disable */

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
var allLoggers = [console.log, console.debug, console.error, console.warn, console.dir];
var logLevels = {
  NONE: "NONE",
  ERROR: "ERROR",
  WARN: "WARN",
  INFO: "INFO",
  DEBUG: "DEBUG"
};
/* const logStyles = {
  INFO: 'color: #B8E986;',
  
}


const styliseMessage = (style: string, message: any) => [`%c ${message}`, style]; */

var styliseMessage = function styliseMessage(style, message) {
  return ["%c " + message, style];
};

var logLevelLoggers = {
  NONE: [],
  ERROR: [console.error],
  WARN: [console.error, console.warn],
  INFO: [console.warn, console.error, console.dir, console.log],
  DEBUG: allLoggers
};
var config = {
  showTrace: false,
  colors: false,
  timestamps: false,
  level: "INFO",
  timestampFormatter: function timestampFormatter(date) {
    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + "." + date.getMilliseconds();
  }
};

exports.updateConfig = function (conf) {
  return config = __assign(__assign({}, config), conf);
};

var makeLogFn = function makeLogFn(fn) {
  return function () {
    var params = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      params[_i] = arguments[_i];
    }

    if (config.level === "NONE") return;
    var allowedLoggers = logLevelLoggers[config.level];

    if (allowedLoggers.find(function (logger) {
      return logger === fn;
    })) {
      fn.apply(void 0, __spreadArrays(config.timestamps ? __spreadArrays(styliseMessage("font-weight: bold;", config.timestampFormatter(new Date()))) : [], params, config.showTrace ? ["STACK TRACE: ", console.trace()] : []));
    }
  };
};

exports.warn = makeLogFn(console.warn);
exports.log = makeLogFn(console.log);
exports.dir = makeLogFn(console.dir);
exports.error = makeLogFn(console.error);
exports.debug = makeLogFn(console.debug);
exports.rcLogger = {
  log: exports.log,
  warn: exports.warn,
  dir: exports.dir,
  error: exports.error,
  debug: exports.debug,
  updateConfig: exports.updateConfig
};