/**
 * Created by danie on 10/03/2016.
 */

CloudService.$inject = ["ApiService", "assert"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("CloudService", CloudService);

/* @ngInject */
function CloudService(ApiService, assert) {
  var CloudService = {};

  CloudService.getMyClouds = function(id, filter) {
    return ApiService.Users.getClouds(id, filter);
  };

  CloudService.getById = function(id, filter) {
    assert(id);

    return ApiService.Clouds.getById(id, filter);
  };

  CloudService.reportById = function(id) {
    return ApiService.Clouds.reportById(id);
  };

  CloudService.getCloudMembers = function(id) {
    assert(id);

    return ApiService.Clouds.getCloudMembers(id);
  };

  CloudService.removeBookFromCloud = function(bookId, cloudId) {
    if (!bookId || !cloudId) return null;

    return ApiService.Clouds.removeBookFromCloud(bookId, cloudId);
  };

  CloudService.get = function(query) {
    return ApiService.Clouds.get(query);
  };

  CloudService.count = function(query) {
    return ApiService.Clouds.count(query);
  };

  return CloudService;
}
