"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.isLocalHost = function (hostname) {
  if (hostname === void 0) {
    hostname = window.location.hostname;
  }

  return !!(hostname === "localhost" || hostname === "[::1]" || hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
};

exports.ensureHttps = function () {
  if (typeof window !== "undefined" && window.location && window.location.protocol === "http:" && !exports.isLocalHost(window.location.hostname)) {
    window.location.href = window.location.href.replace(/^http(?!s)/, "https");
  }
};