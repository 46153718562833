"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.sortAndFilterClouds = function (clouds, books) {
  var booksMap = books.reduce(function (acc, next) {
    var _a;

    return __assign(__assign({}, acc), (_a = {}, _a[next.id] = next, _a));
  }, {});
  var interactive = clouds.map(function (x) {
    return x.books;
  }).reduce(function (a, b) {
    return a.concat(b);
  }).filter(function (bookId) {
    var book = bookId && booksMap[bookId];
    if (!book) return false;
    return book.isbn && book.isbn.startsWith("RC_INTLOGIN");
  });
  var interactiveCloud = {
    id: "interactive",
    name: "My Interactive Logins",
    books: interactive,
    description: "",
    deleted: false,
    institution: "",
    type: "",
    members: [],
    admins: [],
    addBookAdminOnly: true,
    persistent: false,
    updated: new Date().toISOString(),
    created: new Date().toISOString()
  };
  return clouds.concat(interactiveCloud).filter(function (x) {
    return !x.deleted && x.books && x.books.length > 0;
  }).sort(function (a, b) {
    return !a.name || a.name === "My Purchases" ? 0 : a.name.localeCompare(b.name);
  });
};

var defaultBaseRCSURL = typeof window !== "undefined" ? "https://" + window.location.hostname + (window.location.port && ":" + window.location.port) + "/rcs" : "https://app.readcloud.com/rcs";

exports.buildUploadedBookProxyUrl = function (downloadUri) {
  if (!!window.location.port) {
    return downloadUri.replace("api.readcloud.com", window.location.hostname + ":" + window.location.port);
  } else {
    return downloadUri.replace("api.readcloud.com", window.location.hostname);
  }
};

exports.buildRcsResourceUrl = function (resource, accessToken, baseUrl) {
  if (baseUrl === void 0) {
    baseUrl = defaultBaseRCSURL;
  }

  return baseUrl + "/" + resource.type.toLowerCase() + "/" + resource.fileName + "." + resource.fileExt + (accessToken ? "?auth=" + accessToken : "");
};

exports.getLatestRcsResources = function (rcs, baseUrl, accessToken) {
  if (baseUrl === void 0) {
    baseUrl = defaultBaseRCSURL;
  }

  if (rcs && rcs.resources && rcs.resources.length) {
    var latest = rcs.resources.reduce(function (acc, current) {
      return current.type === "Thumbnail" ? __assign(__assign({}, acc), {
        thumbnail: !acc.thumbnail ? current : current.version > acc.thumbnail.version ? current : acc.thumbnail
      }) : current.type === "Bookbytes" ? __assign(__assign({}, acc), {
        bookbytes: !acc.bookbytes ? current : current.version > acc.bookbytes.version ? current : acc.bookbytes
      }) : acc;
    }, {});
    latest.thumbnailUrl = latest.thumbnail ? exports.buildRcsResourceUrl(latest.thumbnail, accessToken, baseUrl) : undefined;
    latest.bookbytesUrl = latest.bookbytes ? exports.buildRcsResourceUrl(latest.bookbytes, accessToken, baseUrl) : undefined;
    return latest;
  } else {
    return {};
  }
};
/* export const getLatestRcsResourceUrls = (
  rcs: DTO.RCSBookData,
  baseUrl = defaultBaseURL
) => {
  const latest = getLatestRcsResources(rcs);
  return {
    thumbnailUrl: latest.thumbnail
      ? buildRcsResourceUrl(latest.thumbnail, baseUrl)
      : undefined,
    bookbytesUrl: latest.bookbytes
      ? buildRcsResourceUrl(latest.bookbytes, baseUrl)
      : undefined
  };
};
 */