const angular = require("angular");
angular.module("readCloudWebConsole").component("rcModelEditorListComponent", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-model-editor/list.html").then(m => m.default),
  bindings: {
    items: "="
  }
});

/* @ngInject */
function ctrl() {
  var vm = this;
  vm.add = function() {
    vm.items.push();
  };
}
