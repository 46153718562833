"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = __importStar(require("io-ts"));

var DTO;

(function (DTO) {
  DTO.productRights = t.partial({
    play: t.boolean,
    excerpt: t.boolean,
    display: t.boolean,
    print: t.boolean,
    printPages: t.string,
    copyPercentage: t.number
  });
  DTO.productTokenPayload = t.type({
    "rights.json": t.string
  });
  DTO.rcsResource = t.type({
    type: t.string,
    mimeType: t.string,
    version: t.number,
    fileName: t.string,
    fileExt: t.string,
    finalFileSize: t.number,
    srcChecksum: t.string,
    minor: t.number,
    longId: t.string
  });
  DTO.rcsBookData = t.intersection([t.type({
    resources: t.array(DTO.rcsResource)
  }), t.partial({
    licenceToken: t.union([t.null, t.string]),
    productToken: t.union([t.null, t.string])
  })]);
  DTO.bookType = t.union([t.literal("Media"), t.literal("EBook"), t.literal("Unknown")]);
  DTO.tag = t.type({
    id: t.string,
    owner: t.string,
    name: t.string,
    bookIds: t.array(t.string),
    version: t.string,
    created: t.string,
    updated: t.string,
    deleted: t.boolean
  });
})(DTO = exports.DTO || (exports.DTO = {}));