/**
 * Created by danie on 4/26/2016.
 */

RCDataTable.$inject = ["ExportService", "UtilitiesService", "_"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcDataTable", {
  controller: RCDataTable,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-data-table/rc-data-table.html").then(m => m.default),
  bindings: {
    fields: "<",
    promise: "<?",
    data: "=",
    onSelect: "<",
    onAdd: "<?",
    onDelete: "<?",
    onExport: "<?",
    onModify: "<?",
    multiple: "<?",
    selectable: "<?",
    selected: "=?",
    filter: "=?",
    enableExport: "<?",
    toolbarBottom: "<?",
    order: "=?",
    query: "=?",
    get: "<?",
    rowActions: "<?",
    pagination: "=?",
    onRefresh: "<",
    autoRowAction: "<",
    disableFilter: "<",
    disableSorting: "<",
    paginate: "<",
    total: "<"
  }
});

/* @ngInject */
function RCDataTable(ExportService, UtilitiesService, _) {
  var vm = this;
  vm.total = vm.total || 0;
  vm._ = _;

  vm.limitOptions = [50, 100, 200, 500, 750];

  vm.query = vm.query || {
    limit: 50,
    page: 1
  };
  vm.selected = vm.selected || [];

  vm.export = function() {
    if (vm.onExport) {
      vm.onExport();
    } else {
      ExportService.exportArrayToXLSX(vm.data);
    }
  };

  vm.updatePageNumber = function(pageNumber, limit) {
    vm.query.page = pageNumber;
    vm.query.limit = limit;
    vm.query.skip = (pageNumber - 1) * limit;
  };

  vm.openMenu = function openMenu($mdOpenMenu, $event) {
    $mdOpenMenu($event);
  };

  vm.queryOrder = {};

  vm.reSearch = function reSearch(i) {
    vm.query.order = UtilitiesService.getLoopbackOrder(i);
    vm.get();
    vm.refreshTotal();
  };

  vm.onPaginate = function onPaginate(pageNumber, limit) {
    vm.updatePageNumber(pageNumber, limit);
    if (vm.get) {
      vm.promise = vm.get(_.omit(vm.query, "page"));
    }
    vm.refreshTotal();
  };

  vm.refreshTotal = function refreshTotal() {
    if (vm.getTotal) {
      vm.getTotal().then(function(result) {
        console.log(result);
        vm.total = result.data;
      });
    }
  };
}
