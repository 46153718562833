"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var axios_1 = require("axios");

var ramda_1 = require("ramda");

var client_1 = require("../client");

var _Auth = require("./auth");

var _Clouds = require("./clouds");

var _DRMBooks = require("./drmbooks");

var _Institutions = require("./institutions");

var _Tags = require("./tags");

var _Users = require("./users");

var _Speech = require("./speech");

var makeAuthInterceptor_1 = require("../makeAuthInterceptor");

var newapiUrl;

var addNewapiUrlInterceptor = function addNewapiUrlInterceptor(client) {
  client.interceptors.request.use(function (config) {
    return tslib_1.__assign({
      baseURL: newapiUrl
    }, config);
  });
  return client;
};

var ensureNewApiClientReady = function ensureNewApiClientReady() {
  if (!!newapiUrl) {
    return true;
  } else {
    throw new Error("Newapi client was accessed before the base URL was set. Please configure this before accessing the client.");
  }
};

var makeNewapiClient = ramda_1.compose(addNewapiUrlInterceptor, makeAuthInterceptor_1.makeAuthAssertionInterceptor(_Auth.reAuthenticate), client_1.addCommonAxiosConfig, axios_1.default.create);
var newapiClient = makeNewapiClient({});

exports.getNewapi = function () {
  return ensureNewApiClientReady(), newapiClient;
};

exports.setNewapiUrl = function (url) {
  return newapiUrl = url;
};

exports.Institutions = _Institutions;
exports.Auth = _Auth;
exports.Clouds = _Clouds;
exports.DRMBooks = _DRMBooks;
exports.Users = _Users;
exports.Tags = _Tags;
exports.Speech = _Speech;