RCApplicationBuildsManager.$inject = [
  "ApplicationService",
  "NotificationService",
  "UtilitiesService",
  "$scope",
  "DialogService",
  "$element"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcApplicationBuildsManager", {
  controller: RCApplicationBuildsManager,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-application-builds-manager/template.html").then(m => m.default),
  bindings: {
    applicationId: "<"
  }
});

/* @ngInject */
function RCApplicationBuildsManager(
  ApplicationService,
  NotificationService,
  UtilitiesService,
  $scope,
  DialogService,
  $element
) {
  var vm = this;

  var promiseWrapper = UtilitiesService.getPromiseWrapper("Application Build");

  var deletePromise = function(promise) {
    return promiseWrapper(promise, "Delete");
  };

  function deleteBuild(obj) {
    return deletePromise(
      ApplicationService.deleteBuild(vm.applicationId, obj.id)
    ).then(function() {
      vm.refresh();
    });
  }

  vm.selected = [];
  vm.rowActions = [
    {
      label: "Delete",
      func: deleteBuild
    }
  ];

  vm.query = {};
  vm.data = [];
  vm.get = ApplicationService.getBuilds;

  vm.refresh = function() {
    UtilitiesService.inlineLoader(
      vm.get(vm.applicationId).then(function(result) {
        vm.data = result.data;
      }, NotificationService.failed.bind(null, "Failed to get applications")),
      $element
    );
  };

  vm.addBuild = function() {
    DialogService.ApplicationBuildCreator(vm.applicationId).then(function(
      data
    ) {
      if (data) {
        vm.data.push(data);
      }
      vm.refresh();
    });
  };

  $scope.$watch("vm.applicationId", function() {
    if (vm.applicationId) {
      vm.refresh();
    }
  });

  vm.fields = ApplicationService.getBuildCreatorFields();
}
