/**
 * Created by danie on 4/29/2016.
 */

ExportService.$inject = ["XLSX", "FileSaver", "Blob", "assert", "_"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("ExportService", ExportService);

/* @ngInject */
function ExportService(XLSX, FileSaver, Blob, assert, _) {
  var ExportService = {};

  /**
       * Convert a JSON object array to an XLSX worksheet
       * @param data
       */
  function arrayToWS(objectArray) {
    assert(objectArray instanceof Array, "Data is not an array");
    assert(objectArray.length > 0, "Input array does not contain any data");

    var data = [];
    var headerRow = [];
    var curIndex = 1;
    for (var i in objectArray) {
      var o = objectArray[i];

      var keys = _.keys(o);
      headerRow = _.union(headerRow, keys);

      var row = [];
      // eslint-disable-next-line
      _.forEach(o, function(val, key) {
        row[headerRow.indexOf(key)] = val;
      });
      data[curIndex++] = row;
    }
    data[0] = headerRow;

    var ws_name = "Export";

    var wb = {};
    wb.Sheets = {};
    wb.Props = {};
    wb.SSF = {};
    wb.SheetNames = [];

    var ws = {};

    /* the range object is used to keep track of the range of the sheet */
    var range = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };

    /* Iterate through each element in the structure */
    for (var R = 0; R !== data.length; ++R) {
      if (range.e.r < R) range.e.r = R;
      for (var C = 0; C !== data[R].length; ++C) {
        if (range.e.c < C) range.e.c = C;

        /* create cell object: .v is the actual data */
        var cell = { v: data[R][C] };
        if (cell.v == null) continue;

        /* create the correct cell reference */
        var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

        /* determine the cell type */
        if (angular.isNumber(cell.v)) cell.t = "n";
        else if (typeof cell.v === "boolean") cell.t = "b";
        else cell.t = "s";

        /* add to structure */
        ws[cell_ref] = cell;
      }
    }
    ws["!ref"] = XLSX.utils.encode_range(range);

    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    return wb;
  }

  /**
     * Sheet to array buffer
     * @param s
     * @returns {ArrayBuffer}
       */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  /**
     * Give a data array, show a save-as dialog for an XLSX file
     * @param data
       */
  ExportService.exportArrayToXLSX = function(data, fileName) {
    var _data = [];
    _data = data.map(function(d) {
      delete d.$$hashKey;
      delete d._id;
      return d;
    });

    var workbook = arrayToWS(_data);
    var wopts = { bookType: "xlsx", bookSST: false, type: "binary" };
    var wbout = XLSX.write(workbook, wopts);

    var blob = new Blob([s2ab(wbout)], { type: "" });

    FileSaver.saveAs(blob, fileName || "output.xlsx");
  };

  return ExportService;
}
