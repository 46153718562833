RCCloudsView.$inject = [
  "$state",
  "$location",
  "$anchorScroll",
  "$timeout",
  "$scope",
  "R",
  "$element",
  "moment",
  "UtilitiesService",
  "$filter",
  "$mdPanel",
  "RouteService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcCloudsView", {
  controller: RCCloudsView,
  controllerAs: "vm",
  templateUrl: () => import("./rc-clouds-view.html").then(m => m.default),
  bindings: {
    data: "=",
    isAdmin: "<",
    email: "<",
    isLoading: "<",
    refresh: "<"
  }
});

/* @ngInject */
function RCCloudsView(
  $state,
  $location,
  $anchorScroll,
  $timeout,
  $scope,
  R,
  $element,
  moment,
  UtilitiesService,
  $filter,
  $mdPanel,
  RouteService
) {
  var vm = this;

  vm.$state = $state;

  vm.viewClouds = [];

  function isIntTile(cloudBook) {
    return !!cloudBook.intLoginData;
  }

  function getCloudsContainer() {
    return angular.element(document.getElementById("clouds-container"));
  }

  /*
    I really wouldn't normally use ramda this heavily, however lacking of es6/7/8 features means FP gets quite verbose
    */
  var interactiveTilesCloudFromMyPurchases = R.compose(
    R.over(R.lensProp("books"), R.filter(isIntTile)),
    R.set(R.lensProp("name"), "My Interactive Logins"),
    R.set(R.lensProp("_id"), "my-interactive-logins")
  );

  var filterNonInteractiveLoginsFromCloud = R.over(
    R.lensProp("books"),
    R.filter(R.complement(isIntTile))
  );

  var addIsLocked = R.map(
    R.over(
      R.lensProp("books"),
      R.map(function(book) {
        //Detect if bookExpireDate is less than the current date
        if (book.transaction) {
          var transaction = book.transaction;
          if (
            transaction.bookExpireDate &&
            new Date(transaction.bookExpireDate) <= new Date()
          ) {
            book.locked = true;
            return book;
          }

          //Detect is rcsLicense expireDate is less than the current date
          var rcsLicenseData =
            transaction.rcs &&
            transaction.rcs.licenseToken &&
            UtilitiesService.decodeJwt(transaction.rcs.licenseToken);

          if (rcsLicenseData) {
            book.rcsLicenseData = rcsLicenseData;
            if (
              rcsLicenseData.expireDate &&
              new Date(rcsLicenseData.expireDate) < new Date()
            ) {
              book.locked = true;
              return book;
            }
          }
        }

        // If there is an ISBN, there must be a transaction
        if (book.isbn && !book.transaction) {
          book.locked = true;
          return book;
        }

        return book;
      })
    )
  );

  // If isNil or empty, return []
  // Sort all clouds by name, a-z
  // Sort all books in all clouds by _id, ascending (latest - oldest)
  // Add locked fields to each book in each cloud.
  var processCloudsList = R.ifElse(
    R.either(R.isNil, R.isEmpty),
    R.always([]),
    R.compose(
      R.sort(function(a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }),
      R.map(
        R.over(
          R.lensProp("books"),
          R.sort(function(a, b) {
            return b._id.toLowerCase().localeCompare(a._id.toLowerCase());
          })
        )
      ),
      addIsLocked
    )
  );

  var enhanceServerClouds = function(clouds) {
    // Find my purchases cloud
    var myPurchasesCloudIndex = R.findIndex(function(cloud) {
      return cloud.name === "My Purchases" && cloud.type === "System";
    }, clouds);
    var myPurchasesCloud = clouds[myPurchasesCloudIndex];
    var newMyPurchasesCloud;
    var interactiveTilesCloud;
    // Generate my interactive tiles clouds from my purchases clouds, then push it to the clouds array
    // Remove myPurchasesCloud, then replace it with a new one, with removed interactive tiles
    if (myPurchasesCloudIndex !== -1) {
      interactiveTilesCloud = interactiveTilesCloudFromMyPurchases(
        myPurchasesCloud
      );
      clouds = R.remove(myPurchasesCloudIndex, 1, clouds);
      newMyPurchasesCloud = filterNonInteractiveLoginsFromCloud(
        myPurchasesCloud
      );
    }

    var output = processCloudsList(clouds);

    // Add my purchases/interactive clouds afterwards so that they show up on top
    if (interactiveTilesCloud) {
      output = processCloudsList([interactiveTilesCloud]).concat(output);
    }
    if (newMyPurchasesCloud) {
      output = processCloudsList([newMyPurchasesCloud]).concat(output);
    }
    return output;
  };

  vm.gotoCloudAdmin = cloudId => RouteService.admin.cloudDetails(cloudId);

  //Scroll to a cloud given the id of the dom element
  vm.gotoId = function(id) {
    if (id)
      $timeout(function() {
        var container = getCloudsContainer();
        var el = document.getElementById(id);
        if (el && container) container.scrollToElementAnimated(el, 60);
      });
  };

  function filterSearchClouds(searchText, clouds) {
    return $filter("filter")(clouds, { name: searchText });
  }

  vm.showCloudsPanel = function($event) {
    var position = $mdPanel
      .newPanelPosition()
      .relativeTo(".clouds-panel-opener")
      .addPanelPosition($mdPanel.xPosition.CENTER, $mdPanel.yPosition.BELOW);

    var config = {
      attachTo: angular.element(document.body),
      controller: CloudsPanelCtrl,
      controllerAs: "vm",
      template: require("./cloudsPanelTemplate.html"),
      position: position,
      locals: vm,
      openFrom: $event,
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false,
      zIndex: 2
    };

    $mdPanel.open(config);

    CloudsPanelCtrl.$inject = ["mdPanelRef", "$scope"];
    function CloudsPanelCtrl(mdPanelRef, $scope) {
      this.onCloudClicked = function(id) {
        vm.gotoId(id);
        mdPanelRef && mdPanelRef.close();
      };
      this.onViewAllClouds = function() {
        getCloudsContainer().scrollTopAnimated(0);
        mdPanelRef && mdPanelRef.close();
      };

      this.cloudOptions = vm.viewClouds;

      $scope.$watch("cloudSearchText", function(val) {
        this.cloudOptions = filterSearchClouds(val, vm.viewClouds);
      });
    }
  };

  $scope.$watch(
    function() {
      return vm.selectedCloud;
    },
    function(newVal) {
      newVal && vm.gotoId(newVal);
    }
  );

  $scope.$watch(
    function() {
      return vm.data;
    },
    function() {
      vm.viewClouds = enhanceServerClouds(vm.data);
    }
  );

  if ($location.hash()) vm.gotoId($location.hash());
}
