"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.addComment = function (req) {
  return index_1.getApiv14().post("/add/comment", req);
};

exports.listComments = function () {
  return index_1.getApiv14().get("/list/comment.json");
};

exports.deleteComment = function (id) {
  return index_1.getApiv14().delete("/delete/comment", {
    params: {
      id: id
    }
  });
};

exports.deltaComments = function (millisec) {
  return index_1.getApiv14().get("/delta/comments", {
    params: millisec
  });
};