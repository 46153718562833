/**
 * Created by danie on 29/03/2016.
 */

RCUserCloudsView.$inject = [
  "UserService",
  "ApiService",
  "UtilitiesService",
  "$element"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserCloudsView", {
  controller: RCUserCloudsView,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-user-clouds-view/rc-user-clouds-view.html").then(m => m.default),
  bindings: {
    userId: "<"
  }
});

/* @ngInject */
function RCUserCloudsView(UserService, ApiService, UtilitiesService, $element) {
  var vm = this;

  vm.me = {};
  vm.isLoading = true;

  function getData(id) {
    vm.isLoading = true;
    return ApiService.Users
      .cloudsView(id)
      .then(function(result) {
        vm.me = result.data.user;
        vm.clouds = result.data.clouds;
        if (!vm.clouds || !vm.clouds.length) vm.noCloudsFound = true;
      })
      .finally(function() {
        vm.isLoading = false;
      });
  }

  vm.getUser = function(id) {
    UtilitiesService.inlineLoader(getData(id), $element);
  };

  vm.getDisplayName = function() {
    if (vm.me)
      return vm.me.firstName
        ? vm.me.firstName + " " + vm.me.lastName
        : vm.me.email;
    else return "";
  };

  vm.refresh = function() {
    vm.getUser(vm.userId);
  };

  vm.$onInit = () => {
    vm.refresh();
  };
}
