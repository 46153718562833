import angular from "angular";
RCOnixGenerator.$inject = [
  "ApiService",
  "$element",
  "NotificationService",
  "UtilitiesService"
];
angular.module("readCloudWebConsole").component("rcOnixGenerator", {
  controller: RCOnixGenerator,
  controllerAs: "vm",
  templateUrl: () => import("app/components/rc-onix-generator/template.html").then(m => m.default)
});

/* @ngInject */
function RCOnixGenerator(
  ApiService,
  $element,
  NotificationService,
  UtilitiesService
) {
  var vm = this;

  function getOutputFileName(isbn) {
    return isbn + ".xml";
  }

  vm.submit = function(data) {
    data.contributors = data.contributors.split(",");
    if ('printPages' in data) {
      data.printPages = data.printPages.replace(/\s/g,'');
      var re = new RegExp("^[0-9,-]*$");
      if (!re.test(data.printPages)) {
        alert('Print Pages input invalid');
        return new Promise(function(){});
      } 
    }
    if (!('price' in data) || (data.price == null)) {
      alert('Price must be specified.');
      return new Promise(function(){});
    }
    var n = NotificationService.toastIt("Create", "Onix XML");
    var promise = ApiService.Onix.generate(data);
    UtilitiesService.inlineLoader(promise, $element);
    promise.then(n, n);
    promise.then(function(result) {
        UtilitiesService.saveXMLAsFile(result.data, getOutputFileName(data.isbn));
    });
    return promise;
  };

  vm.fields = [
    {
      label: "ISBN",
      id: "isbn",
      required: true
    },
    {
      label: "Title",
      id: "title",
      required: true
    },
    {
      label: "Description",
      id: "description",
      required: true
    },
    {
      label: "Publisher",
      id: "publisher",
      required: true
    },
    {
      label: "Contributors",
      id: "contributors",
      required: true,
      hint: "Format: Firstname Lastname,Firstname Lastname,..."
    },
    {
      label: "Publication Date",
      id: "publicationDate",
      required: true,
      hint: "YYYY-MM-DD"
    },
    {
      label: "Price",
      id: "price",
      required: false,
      type: "number",
      hint: "50.00"
    },
    {
      label: "Printable Pages",
      id: "printPages",
      required: false,
      hint: "Optional.  1,3,7,11-13,21-29,35   Note: These are the physical book page numbers"
    },
    {
      label: "Copy Percentage",
      id: "copyPercentage",
      required: false,
      type: "number",
      hint: "Optional.  Number between 0 and 100. Set to 0 to disable copying."
    }
  ];
}
