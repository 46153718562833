"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tslib_1 = require("tslib");

var index_1 = require("./index");

exports.addAnnotation = function (annotation) {
  return index_1.getApiv14().post("/add/annotation", annotation);
};

exports.updateAnnotation = function (id, annotation) {
  var authorID = annotation.authorID,
      bookID = annotation.bookID,
      body = tslib_1.__rest(annotation, ["authorID", "bookID"]);

  index_1.getApiv14().put("/update/annotation", body, {
    params: {
      id: id
    }
  });
};

exports.listAnnotations = function () {
  return index_1.getApiv14().get("/list/annotation.json");
};

exports.deleteAnnotation = function (id) {
  return index_1.getApiv14().delete("/delete/annotation", {
    params: {
      id: id
    }
  });
};

exports.deltaAnnotations = function (millisec) {
  return index_1.getApiv14().get("/delta/annotations", {
    params: {
      millisec: millisec
    }
  });
};