import { scopeReducers, composeReducer, createFactory } from "recrux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//Reducer for global app config for example secret flags, easMode, noNav, maybe even api url as well.
const namespace = "config";
const defaultState = {
  easMode: false,
  noNav: false,
  secret: false,
  persistButton: false,
  baseThumbnailUrl: "http://rcs.readcloud.com/rcs/thumbnail"
};

export const stateSchema = {
  name: "Application Config",
  type: "object",
  additionalProperties: true,
  required: ["apiUrl"],
  properties: {
    easMode: {
      type: "boolean"
    },
    noNav: {
      type: "boolean"
    },
    secret: {
      type: "boolean"
    },
    apiUrl: {
      type: "string"
    },
    persistButton: {
      type: "boolean"
    },
    openBookInReader: {
      type: "boolean"
    }
  }
};

export const updateConfig = createFactory({
  namespace,
  actionName: "updateConfig",
  reducer: (state, { payload }) => ({ ...state, ...payload })
});
export const configReducer = scopeReducers({
  [namespace]: persistReducer(
    { key: namespace, storage },
    composeReducer((state = defaultState) => state, updateConfig.reducer)
  )
});

export const selectConfig = state => state[namespace];
