/**
 * Created by danie on 4/03/2016.
 */

RoleHandler.$inject = ["AuthenticationService", "_"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("RoleHandler", RoleHandler);

/* @ngInject */
function RoleHandler(AuthenticationService, _) {
  var RoleHandler = {};

  function roleContainsAdmin(role) {
    return /.*Admin/.test(role);
  }

  RoleHandler.canSearchUsers = function() {
    return roleContainsAdmin(AuthenticationService.getRole());
  };

  RoleHandler.hasAdminAccessToCloud = function(cloud) {
    if (
      cloud &&
      cloud.admins &&
      cloud.admins.indexOf(AuthenticationService.getUserId()) !== -1 &&
      cloud.type !== "System"
    ) {
      return true;
    } else return false;
  };

  RoleHandler.isAdmin = function() {
    return AuthenticationService.getRole() === "Admin";
  };

  RoleHandler.isAuthenticatedForPatterns = function(patterns) {
    var role = AuthenticationService.getRole();
    var output = false;
    patterns.forEach(function(pattern) {
      if (_.isRegExp(pattern)) {
        output = output || pattern.test(role);
      } else if (_.isString(pattern)) {
        output = output || new RegExp(pattern).test(role);
      }
    });

    return output;
  };

  return RoleHandler;
}
