const angular = require("angular");
angular.module("readCloudWebConsole").component("rcResellerAppManager", {
  controller: ctrl,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-reseller-app-manager/template.html").then(m => m.default),
  bindings: {
    reseller: "<"
  }
});

/* @ngInject */
function ctrl() {}
