/**
 * Created by danie on 4/26/2016.
 */

ResellerService.$inject = ["ApiService"];
const angular = require("angular");
angular.module("readCloudWebConsole").service("ResellerService", ResellerService);

/* @ngInject */
function ResellerService(ApiService) {
  var ResellerService = {};

  ResellerService.appModel = function appModel(
    resellerId,
    osName,
    appVersion,
    downloadLink,
    updateText,
    extraUpdateText,
    testingLogin
  ) {
    this.resellerId = resellerId;
    this.osName = osName;
    this.appVersion = appVersion;
    this.downloadLink = downloadLink;

    this.updateText = updateText;
    this.extraUpdateText = extraUpdateText;
    this.testingLogin = testingLogin;

    appModel.prototype.save = ResellerService.updateApps.bind(null, resellerId, this);
  };

  ResellerService.get = function(query) {
    return ApiService.Resellers.get(query);
  };

  ResellerService.getById = function(id) {
    return ApiService.Resellers.getById(id);
  };

  ResellerService.updateAppConfig = function(resellerId, osName, data) {
    return ApiService.Resellers.updateAppConfig(resellerId, osName, data);
  };

  ResellerService.getAppConfig = function(resellerId, osName) {
    return ApiService.Resellers.getAppConfig(resellerId, osName);
  };

  ResellerService.getMappedOrders = ApiService.Resellers.getMappedOrders;
  ResellerService.addMappedOrder = ApiService.Resellers.addMappedOrder;
  ResellerService.updateMappedOrder = ApiService.Resellers.updateMappedOrder;
  ResellerService.deleteMappedOrder = ApiService.Resellers.deleteMappedOrder;

  return ResellerService;
}
