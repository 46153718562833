"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var __1 = require("..");

var lib_1 = require("../../lib");

var t = __importStar(require("io-ts"));

var ViewModels;

(function (ViewModels) {
  var CloudView;

  (function (CloudView) {
    /**
     * Use this function to convert a bridge book or DTO book to a cloudview model that is ready to be rendered
     */
    CloudView.bookToCloudViewBook = function (baseRcsUrl, book, tags) {
      var bookType = __1.DTO.bookType.is(book.type) ? book.type : undefined;
      var latestResources = book.rcsData ? lib_1.getLatestRcsResources(book.rcsData, baseRcsUrl) : undefined;

      if (!book.id) {
        return;
      }

      return {
        id: book.id,
        title: book.title,
        description: book.description || undefined,
        authorName: book.authorName || undefined,
        created: book.created,
        updated: book.updated,
        owner: book.owner || undefined,
        isbn: book.isbn || undefined,
        bestThumbnailUrl: latestResources && latestResources.thumbnailUrl || book.imageThumbnailURI || undefined,
        type: bookType || "Unknown",
        tags: tags.filter(function (tag) {
          return !!tag.bookIds.find(function (id) {
            return id === book.id;
          });
        })
      };
    };

    CloudView.cloudViewBook = t.intersection([t.type({
      id: t.string,
      created: t.string,
      updated: t.string,
      title: t.string,
      type: __1.DTO.bookType,
      tags: t.array(__1.DTO.tag)
    }), t.partial({
      uploading: t.boolean,
      uploadPercent: t.number,
      downloadURI: t.string,
      description: t.string,
      owner: t.string,
      authorName: t.string,
      bestThumbnailUrl: t.string,
      isbn: t.string
    })]);
  })(CloudView = ViewModels.CloudView || (ViewModels.CloudView = {}));
})(ViewModels = exports.ViewModels || (exports.ViewModels = {}));