/**
 * Created by danie on 4/22/2016.
 */

RCUserDetailsDialog.$inject = ["$mdDialog"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserDetailsDialog", {
  controller: RCUserDetailsDialog,
  controllerAs: "vm",
  template: require("app/modals/rc-user-details-dialog/rc-user-details-dialog.html"),
  bindings: {
    userId: "@"
  }
});

/* @ngInject */

function RCUserDetailsDialog($mdDialog) {
  var vm = this;

  vm.close = function() {
    $mdDialog.hide();
  };
}
