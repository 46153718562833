import angular from "angular";
import moduleName from "./moduleName";
import { prop } from "ramda";
const newAsyncControllerProvider = () => {
  let controllerProvider = function(controller) {
    return controller;
  };
  controllerProvider.$inject = ["controller"];
  return controllerProvider;
};

routerConfig.$inject = ["$stateProvider", "$urlRouterProvider"];

export function routerConfig($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state("login", {
      url: "/login?returnUrl&email&password",
      templateProvider: () =>
        import("app/routes/login/login.html").then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/login/login.controller").then(prop("default")),
      },
      controllerAs: "vm",
      data: {
        title: "Login",
        auth: {
          required: false,
        },
      },
    })
    .state("main", {
      url: "/main?accessToken&noNav&easMode&scrt",
      templateProvider: () =>
        import("app/routes/main/main.html").then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/main.controller").then(prop("default")),
      },
      controllerAs: "vm",
      abstract: true,
    })
    .state("main.me", {
      url: "/me",
      templateProvider: () =>
        import("app/routes/main/me/me.html").then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/me/me.controller").then(prop("default")),
      },
      controllerAs: "vm",
      data: {
        title: "My Profile",
        auth: {
          roles: [".*"],
        },
      },
    })
    .state("main.actions", {
      url: "/actions",
      templateProvider: () =>
        import("app/routes/main/actions/actions.html").then((m) => m.default),
      controllerAs: "vm",
      data: {
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.actions.cloudStats", {
      url: "/cloudStats?cloudId",
      templateProvider: () =>
        import("app/routes/main/actions/cloudStats.html").then(
          (m) => m.default
        ),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/actions/cloudStats.controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "Cloud Statistics",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.actions.exportAnnotations", {
      url: "/exportAnnotations?bookId&cloudId",
      templateProvider: () =>
        import("app/routes/main/actions/exportAnnotations.html").then(
          (m) => m.default
        ),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/actions/exportAnnotations.controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "Export Annotations",
        auth: {
          required: false,
        },
      },
    })
    .state("main.admin", {
      url: "/admin",
      templateProvider: () =>
        import("app/routes/main/admin/rc-admin.html").then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/admin/rc-admin.controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "Admin Utilities",
        auth: {
          required: true,
          roles: [".*Admin"],
        },
      },
    })
    .state("main.admin.institutions", {
      url: "/institutions",
      templateProvider: () =>
        import(
          "app/routes/main/admin/institutions/rc-admin-institutions.html"
        ).then((m) => m.default),
      controllerAs: "vm",
      abstract: true,
      data: {
        title: "Institution Admin",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.institutions.find", {
      url: "/find?filter",
      templateProvider: () =>
        import(
          "app/routes/main/admin/institutions/find/rc-admin-institutions-find.html"
        ).then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/admin/institutions/find/controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "Institution Search",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.institutions.edit", {
      url: "/:id;:name",
      templateProvider: () =>
        import(
          "app/routes/main/admin/institutions/edit/rc-institution-edit.html"
        ).then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import(
            "app/routes/main/admin/institutions/edit/rc-institution-edit.controller"
          ).then(prop("default")),
      },
      controllerAs: "vm",
      data: {
        title: "Institution Edit",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.users", {
      url: "/users",
      templateProvider: () =>
        import("app/routes/main/admin/users/rc-admin-users.html").then(
          (m) => m.default
        ),
      controllerAs: "vm",
      abstract: true,
      data: {
        title: "User Admin",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.users.find", {
      url: "/find?institution",
      templateProvider: () =>
        import(
          "app/routes/main/admin/users/find/rc-admin-users-find.html"
        ).then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/admin/users/find/controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "User Search",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.users.clouds", {
      url: "/clouds/:userId",
      templateProvider: () =>
        import(
          "app/routes/main/admin/users/clouds/rc-user-clouds-view.html"
        ).then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/admin/users/clouds/controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "User Clouds",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.users.details", {
      url: "/details/:email",
      templateProvider: () =>
        import("app/routes/main/admin/users/details/rc-user-details.html").then(
          (m) => m.default
        ),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/admin/users/details/controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "User Details",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.clouds", {
      url: "/clouds",
      templateProvider: () =>
        import("app/routes/main/admin/clouds/rc-admin-clouds.html").then(
          (m) => m.default
        ),
      controllerAs: "vm",
      abstract: true,
      data: {
        title: "Cloud Admin",
      },
    })
    .state("main.admin.clouds.find", {
      url: "/find?institution",
      templateProvider: () =>
        import(
          "app/routes/main/admin/clouds/find/rc-admin-clouds-find.html"
        ).then((m) => m.default),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/admin/clouds/find/controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "Cloud Search",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.clouds.details", {
      url: "/clouds/:cloudId",
      templateProvider: () =>
        import("app/routes/main/admin/clouds/details/template.html").then(
          (m) => m.default
        ),
      controllerProvider: newAsyncControllerProvider(),
      resolve: {
        controller: () =>
          import("app/routes/main/admin/clouds/details/controller").then(
            prop("default")
          ),
      },
      controllerAs: "vm",
      data: {
        title: "Cloud Details",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.settings", {
      url: "/settings",
      templateProvider: () =>
        import("app/routes/main/admin/settings/template.html").then(
          (m) => m.default
        ),
      controllerAs: "vm",
      data: {
        title: "Settings Admin",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.settings.publisherOptions", {
      url: "/publisher-options",
      templateProvider: () =>
        import(
          "app/routes/main/admin/settings/publisher-options/template.html"
        ).then((m) => m.default),
      controllerAs: "vm",
      data: {
        title: "Publisher Options",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.settings.resellerOptions", {
      url: "/reseller-options",
      templateProvider: () =>
        import(
          "app/routes/main/admin/settings/reseller-options/template.html"
        ).then((m) => m.default),
      controllerAs: "vm",
      data: {
        title: "Reseller Options",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.settings.applicationOptions", {
      url: "/application-options",
      templateProvider: () =>
        import(
          "app/routes/main/admin/settings/application-options/template.html"
        ).then((m) => m.default),
      controllerAs: "vm",
      data: {
        title: "Application Options",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.settings.mapped-orders", {
      url: "/mapped-orders",
      abstract: true,
      templateProvider: () =>
        import("app/routes/main/admin/mapped-orders/template.html").then(
          (m) => m.default
        ),
      controllerAs: "vm",
      data: {
        title: "Mapped Orders Management",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.settings.mapped-orders.manager", {
      url: "/manager",
      templateProvider: () =>
        import(
          "app/routes/main/admin/mapped-orders/manager/template.html"
        ).then((m) => m.default),
      controllerAs: "vm",
    })
    .state("main.admin.settings.actionsManager", {
      url: "/actionsManager",
      controllerAs: "vm",
      templateProvider: () =>
        import(
          "app/routes/main/admin/settings/actions-manager/template.html"
        ).then((m) => m.default),
      data: {
        title: "Actions Manager",
        auth: {
          roles: [".*"],
          required: true,
        },
      },
    })
    .state("main.admin.booksAll", {
      url: "/booksAll?q",
      controllerAs: "vm",

      controller: require("app/routes/main/admin/books/controller").default,
      templateProvider: () =>
        import("app/routes/main/admin/books/rc-admin-books.html").then(
          (m) => m.default
        ),
      data: {
        title: "Book Search",
        auth: {
          roles: ["^Admin$", "^ResellerAdmin$", "^GroupAdmin$"],
          required: true,
        },
      },
    })
    .state("main.admin.transactionsAll", {
      url: "/transactionsAll?q",
      controllerAs: "vm",

      controller: require("app/routes/main/admin/transactions/controller")
        .default,
      templateProvider: () =>
        import(
          "app/routes/main/admin/transactions/rc-admin-transactions.html"
        ).then((m) => m.default),
      data: {
        title: "Transaction Search",
        auth: {
          roles: ["^Admin$", "^ResellerAdmin$", "^GroupAdmin$"],
          required: true,
        },
      },
    })
    .state("main.admin.logs", {
      url: "/logs",
      controllerAs: "vm",
      controller: (function() {
        const controller = () => {};
        controller.$inject = [];
      })(),
      template: `<logs-page></logs-page>`,
      data: {
        title: "Logs",
        auth: {
          roles: ["^Admin$"],
          required: true,
        },
      },
    });

  $urlRouterProvider.otherwise(function($injector) {
    $injector.get("RouteHandler").gotoDefault();
  });
}

angular.module(moduleName).config(routerConfig);
