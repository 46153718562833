/**
 * Created by danie on 6/28/2016.
 */

rcApplicationBuildCreator.$inject = [
  "ApplicationService",
  "UtilitiesService",
  "$element"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcApplicationBuildCreator", {
  controller: rcApplicationBuildCreator,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-application-build-creator/template.html").then(m => m.default),
  bindings: {
    applicationId: "<",
    onCreate: "<"
  }
});

/*@ngInject*/
function rcApplicationBuildCreator(
  ApplicationService,
  UtilitiesService,
  $element
) {
  var vm = this;

  var promiseWrapper = UtilitiesService.getPromiseWrapper("Application Build");

  vm.data = {};

  vm.fields = ApplicationService.getBuildCreatorFields();

  vm.submit = function(data) {
    if (vm.applicationId) {
      return UtilitiesService.inlineLoader(
        promiseWrapper(
          ApplicationService.addBuild(vm.applicationId, data),
          "Create"
        ).then(function(result) {
          console.log(result);
          if (vm.onCreate) {
            vm.onCreate(data);
          }
        }),
        $element
      );
    }
  };
}
