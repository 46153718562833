ctrl.$inject = ["$scope"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcBookTable", {
  templateUrl: () => import("app/components/rc-book-table/template.html").then(m => m.default),
  controller: ctrl,
  controllerAs: "vm",
  bindings: {
    selected: "=",
    data: "<"
  }
});

/*@ngInject*/
function ctrl($scope) {
  var vm = this;

  vm.fields = [
    {
      label: "ISBN",
      id: "isbn"
    },
    {
      label: "Title",
      id: "title"
    }
  ];
}
