const splithost = window.location.host.split(".");
export const isDev = process.env.NODE_ENV === "development";
export const isProd = process.env.NODE_ENV === "production";
export const resellerUtilsUrl = `https://reseller-utils.${splithost.length > 1
  ? splithost.slice(1).join(".")
  : window.location.host}`;

/* export const assertEnv = () => {
  if (!process.env.RESELLER_UTILS_URL)
    throw new Error("RESELLER_UTILS_URL is not defined");
};
 */
