import { composeReducer, scopeReducers, fromMap, createFactory } from "recrux";
import { makeApiCallAction } from "new/redux/reducers/shared";
import { combineEpics } from "redux-observable";
import { DRMBooks } from "@readcloud/api-client/build/v2";
import { concat } from "rxjs";
import { delay, map, mergeMap, debounceTime, mapTo } from "rxjs/operators";
import { resourceTypes } from "new/shared/data";
import { selectConfig } from "new/redux/reducers/config";

const transformBookSearchResults = ({ books = [], baseThumbnailUrl = "" }) =>
  books.map(({ rcs, ...other }) => {
    const latestThumbnail =
      rcs &&
      rcs.resources &&
      rcs.resources.length &&
      rcs.resources.find(({ type }) => type === resourceTypes.Thumbnail);
    return {
      ...other,
      thumbnailURL: latestThumbnail
        ? `${baseThumbnailUrl}/${latestThumbnail.fileName}.${latestThumbnail.fileExt}`
        : other.thumbnailURL,
      rcs
    };
  });

const namespace = "bookSearch";
const defaultState = {
  data: [],
  loading: false,
  error: null,
  initialMode: true, // Has the user searched for anything yet? if not, this is true,
  options: {
    mode: 1, // 0 = Table, 1 = List
    toolsVisible: false
  },
  selectedBook: null,
  searchText: "",
  loadingItemIndex: null,
  limit: 50,
  skip: 0,
  hasMore: true,
  advancedMode: false,
  advancedQuery: {},
  filter: {
    sort: {}
  },
  scrollTop: 0
};

const pageSize = 50;

export const setSearchText = createFactory({
  namespace,
  actionName: "setSearchText",
  reducer: (state, { payload }) => ({ ...state, searchText: payload })
});

export const selectBook = createFactory({
  namespace,
  actionName: "selectBook",
  reducer: (state, { payload }) => ({ ...state, selectedBook: payload })
});

export const unselectBook = createFactory({
  namespace,
  actionName: "unselectBook",
  reducer: (state, { payload }) => ({ ...state, selectedBook: null })
});

export const updateViewOptions = createFactory({
  namespace,
  actionName: "updateViewOptions",
  reducer: (state, { payload }) => ({
    ...state,
    options: { ...state.options, ...payload }
  })
});

export const updateFilter = createFactory({
  namespace,
  actionName: "updateFilter",
  reducer: (state, { payload }) => ({
    ...state,
    filter: {
      ...state.filter,
      ...payload
    }
  })
});

export const toggleViewTools = createFactory({
  namespace,
  actionName: "toggleViewTools",
  reducer: state => ({
    ...state,
    options: { ...state.options, toolsVisible: !state.options.toolsVisible }
  })
});
export const search = makeApiCallAction(namespace, "search");
//Upon search request, reset to some defaults
const extraSearchReducer = fromMap({
  [search.requestType]: state => ({
    ...state,
    hasMore: true,
    limit: pageSize,
    skip: 0,
    initialMode: false,
    selectedBook: null
  })
});

export const scrollToTop = createFactory({
  namespace,
  actionName: "scrollToTop",
  reducer: state => ({ ...state, scrollTop: 10 })
});

export const scrollToTopFinish = createFactory({
  namespace,
  actionName: "scrollToTopFinish",
  reducer: state => ({ ...state, scrollTop: 0 })
});

export const searchMore = makeApiCallAction(
  namespace,
  "searchMore",
  (state, { payload }) => ({
    ...state,
    loadingItemIndex: state.data.length - 1,
    error: null
  }),
  (state, { payload }) => ({
    ...state,
    data: [...state.data, ...payload],
    hasMore: payload.length >= state.limit,
    loadingItemIndex: null,
    error: null
  }),
  (state, { payload }) => ({
    ...state,
    loadingItemIndex: null,
    hasMore: false,
    error: payload
  })
);

const nextPage = createFactory({
  namespace,
  actionName: "nextPage",
  reducer: state => ({
    ...state,
    skip: state.skip + pageSize
  })
});

export const bookSearchReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    search.reducer,
    searchMore.reducer,
    extraSearchReducer,
    updateViewOptions.reducer,
    toggleViewTools.reducer,
    selectBook.reducer,
    unselectBook.reducer,
    setSearchText.reducer,
    nextPage.reducer,
    updateFilter.reducer,
    scrollToTop.reducer,
    scrollToTopFinish.reducer
  )
});

export const select = state => state[namespace];

const performSearch = async store => {
  const {
    limit,
    skip,
    filter: { sort },
    advancedMode,
    searchText,
    advancedQuery
  } = select(store.getState());
  const { baseThumbnailUrl } = selectConfig(store.getState());
  const results = await (!advancedMode
    ? DRMBooks.search(
        searchText,
        limit,
        skip,
        sort && Object.values(sort).length ? sort : undefined
      )
    : DRMBooks.advancedSearch(
        advancedQuery,
        limit,
        skip,
        sort && Object.values(sort).length ? sort : undefined
      ));
  return transformBookSearchResults({ books: results.data, baseThumbnailUrl });
};

export const bookSearchEpic = combineEpics(
  (action$, store) =>
    action$.ofType(search.type).pipe(
      mergeMap(() =>
        concat(
          [scrollToTop()],
          performSearch(store)
            .then(data => search.fulfill(data))
            .catch(search.error)
        )
      )
    ),
  (action$, store) =>
    action$.ofType(searchMore.type).pipe(debounceTime(100), mapTo(nextPage())),
  (action$, store) =>
    action$.ofType(nextPage.type).pipe(
      mergeMap(() =>
        performSearch(store)
          .then(data => searchMore.fulfill(data))
          .catch(searchMore.error)
      )
    ),
  action$ =>
    action$
      .ofType(scrollToTop.type)
      .pipe(delay(100), map(() => scrollToTopFinish()))
);
