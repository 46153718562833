RCApplicationSelector.$inject = ["ApplicationService"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcApplicationSelector", {
  controller: RCApplicationSelector,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-application-selector/template.html").then(m => m.default),
  bindings: {
    selected: "=",
    filter: "<"
  }
});

/* @ngInject */
function RCApplicationSelector(ApplicationService) {
  var vm = this;

  vm.get = ApplicationService.getList;

  vm.$onInit = function() {
    vm.get(vm.filter).then(function(result) {
      if (result.data) {
        vm.options = result.data;

        vm.selected = vm.options[0].id;
        console.log(vm.selected);
      }
    });
  };
}
