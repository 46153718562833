export const userRoles = {
  Admin: "Admin",
  GroupAdmin: "GroupAdmin",
  ResellerAdmin: "ResellerAdmin",
  SchoolAdmin: "SchoolAdmin",
  Teacher: "Teacher",
  Student: "Student"
};

export const resourceTypes = {
  Thumbnail: "Thumbnail"
};
