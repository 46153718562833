import React from "react";
import { Flex } from "new/shared";
import { withUserConfirm } from "new/utils";
import { RaisedButton, DatePicker as MUIDatePicker } from "material-ui";

import { defaultProps } from "recompose";
import { Small } from "glamorous";

export const FieldsContainer = defaultProps({ column: true })(
  Flex.withComponent("form")
);

export const FormFooter = ({
  dirty,
  onCancel,
  onSubmit,
  cancelConfirmMessage,
  cancelButtonProps,
  submitButtonProps,
  ...props
}) => (
  <Flex {...props}>
    {onCancel && (
      <RaisedButton
        onClick={withUserConfirm(onCancel, cancelConfirmMessage, () => dirty)}
        label="Cancel"
        {...cancelButtonProps}
      />
    )}
    <Flex flex />
    {onSubmit && (
      <RaisedButton
        onClick={onSubmit}
        primary
        label="Submit"
        {...submitButtonProps}
      />
    )}
  </Flex>
);

export const FormDatePicker = ({
  meta,
  input: { onBlur, ...inputProps },
  ...props
}) => (
  <Flex column>
    <MUIDatePicker
      {...inputProps}
      {...props}
      onChange={(e, value) => {
        onBlur(e);
        inputProps.onChange && inputProps.onChange(value);
        props.onChange && props.onChange(e, value);
      }}
    />
    {meta.error && meta.touched && <Small color="red">{meta.error}</Small>}
  </Flex>
);
