import React from "react";
import { Flex } from "new/shared";
import { compose, defaultProps } from "recompose";
import { withDialogState } from "new/shared";
import { IconButton, Dialog } from "material-ui";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import { onlyRenderWhen } from "new/shared";
import { connect } from "react-redux";
import { omitProps } from "new/shared/hocs";
import { selectConfig } from "new/redux/reducers/config";
import { Padding, Divider } from "new/shared";

import { ConfigForm } from "new/Config/ConfigForm";
const styles = {
  secretButtonIcon: {
    color: "white"
  }
};

const SecretButtonComponent = ({
  open,
  onRequestOpen,
  onRequestClose,
  ...props
}) => (
  <IconButton
    {...props}
    onClick={e => {
      props.onClick && props.onClick(e);
      onRequestOpen && onRequestOpen();
    }}
  >
    <SettingsIcon />
    <Dialog {...{ open, onRequestClose }}>
      <SecretPage />
    </Dialog>
  </IconButton>
);

export const SecretButton = compose(
  withDialogState,
  connect(
    state => ({
      visible: selectConfig(state).secret || selectConfig(state).persistButton
    }),
    null
  ),
  onlyRenderWhen(props => !!props.visible),
  defaultProps({ iconStyle: styles.secretButtonIcon }),
  omitProps(["visible", "dispatch"])
)(SecretButtonComponent);

export const SecretPageComponent = () => (
  <Flex column height="100%" overflowY="overlay">
    <Flex>
      <Padding />
      <h2>Secret Settings</h2>
    </Flex>
    <Divider />
    <Padding />
    <ConfigForm />
  </Flex>
);

export const SecretPage = compose()(SecretPageComponent);
