import { scopeReducers, composeReducer, createFactory } from "recrux";

import { uniqBy, prop } from "ramda";
//This will be refactored once a new router comes into play and we don't need angular's one, and should be much cleaner using react-router

const namespace = "history";
const defaultState = {
  recentClouds: [],
  recentUsers: [],
  recentInstitutions: []
};

const uniqueItems = uniqBy(prop("routeParam"));

export const visitedCloud = createFactory({
  namespace,
  actionName: "visitedCloud",
  reducer: (state, { payload }) => ({
    ...state,
    recentClouds: uniqBy(prop("routeParam"), [
      payload,
      ...state.recentClouds
    ]).slice(0, 5)
  })
});
export const visitedUser = createFactory({
  namespace,
  actionName: "visitedUser",
  reducer: (state, { payload }) => ({
    ...state,
    recentUsers: uniqBy(prop("routeParam"), [
      payload,
      ...state.recentUsers
    ]).slice(0, 5)
  })
});
export const visitedInstitution = createFactory({
  namespace,
  actionName: "visitedInstitution",
  reducer: (state, { payload }) => ({
    ...state,
    recentInstitutions: uniqBy(prop("routeParam"), [
      payload,
      ...state.recentInstitutions
    ]).slice(0, 5)
  })
});

export const backdoorForLegacy = createFactory({
  namespace,
  actionName: "backdoorForLegacy",
  reducer: (
    state,
    { payload: { recentUsers, recentInstitutions, recentClouds } }
  ) => ({
    ...state,
    recentUsers: uniqueItems(recentUsers),
    recentInstitutions: uniqueItems(recentInstitutions),
    recentClouds: uniqueItems(recentClouds)
  })
});

export const historyReducer = scopeReducers({
  [namespace]: composeReducer(
    (state = defaultState) => state,
    visitedCloud.reducer,
    visitedUser.reducer,
    visitedInstitution.reducer,
    backdoorForLegacy.reducer
  )
});
export const select = state => state[namespace];
