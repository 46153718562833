/**
 * Created by danie on 4/15/2016.
 */

RCInstitutionCreator.$inject = [
  "ApiService",
  "$scope",
  "NotificationService",
  "_",
  "InstitutionService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcInstitutionCreator", {
  controller: RCInstitutionCreator,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-institution-creator/rc-institution-creator.html").then(m => m.default),
  bindings: {
    onSuccess: "<"
  }
});

/* @ngInject */

function RCInstitutionCreator(
  ApiService,
  $scope,
  NotificationService,
  _,
  InstitutionService
) {
  var vm = this;

  vm.resellerInstitutionNameEdited = false;

  vm.resellers = [];

  vm.fields = [
    {
      label: "Name/ID (Domain Name)",
      field: "name",
      copyTo: "resellerInstitutionName"
    },
    {
      label: "Description (Name of school)",
      field: "description"
    },
    {
      label: "Reseller Institution Name",
      field: "resellerInstitutionName"
    },
    {
      label: "Reseller",
      field: "reseller",
      options: function() {
        return vm.resellers;
      }
    }
  ];

  vm.getResellers = function() {
    ApiService.Resellers.get().then(function(result) {
      vm.resellers = result.data;
    });
  };

  vm.setIfNotModified = function(fields, value, copyTo) {
    var field = _.find(fields, { field: copyTo });
    if (field && !$scope.institutionCreator[copyTo].$touched)
      field.value = value;
  };

  vm.getResellers();

  vm.validate = function() {
    var output = $scope.institutionCreator.$valid;
    $scope.institutionCreator.$setSubmitted();

    return output;
  };

  vm.submit = function() {
    var data = (function(arrayOfFields) {
      var output = {};
      arrayOfFields.forEach(function(v) {
        output[v.field] = v.value;
      });

      return output;
    })(vm.fields);
    var resellers = [{ "reseller": data.reseller,
                      "resellerInstitutionName": data.resellerInstitutionName
                    }];
    data.resellers = resellers;
    InstitutionService.registerInstitution(data).then(
      function() {
        if (vm.onSuccess) vm.onSuccess(data);
        NotificationService.success("Successfully created!");
      },
      function(reason) {
        try {
          NotificationService.error(reason);
          NotificationService.failed(
            reason.data.error.message || reason.data.error.errmsg
          );
        } catch (e) {
          NotificationService.failed(
            "Could not create institution! See console"
          );
        }
      }
    );
  };
}
