/**
 * Created by danie on 4/26/2016.
 */

RCInstitutionDetails.$inject = ["$state", "$mdDialog"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcInstitutionDetails", {
  controller: RCInstitutionDetails,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-institution-details/rc-institution-details.html").then(m => m.default),
  bindings: {
    data: "="
  }
});

/* @ngInject */

function RCInstitutionDetails($state, $mdDialog) {
  var vm = this;

  vm.fields = [
    {
      label: "Name",
      id: "name"
    },
    {
      label: "Description",
      id: "description"
    },
    {
      label: "Reseller Institution Name",
      id: "resellerInstitutionName"
    },
    {
      label: "Reseller",
      id: "reseller"
    },
    {
      label: "Created",
      id: "created"
    },
    {
      label: "Last Updated",
      id: "updated"
    }
  ];

  function hideAndGo(name, params) {
    $mdDialog.hide();
    $state.go(name, params);
  }

  vm.gotoClouds = function() {
    hideAndGo("admin.clouds");
  };

  vm.gotoUsers = function() {
    hideAndGo("admin.users", { institution: vm.data.id });
  };

  vm.edit = function() {
    hideAndGo("admin.institutions.edit", { id: vm.data.id });
  };
}
