/**
 * Created by danie on 4/5/2016.
 */

RCAdminSearchClouds.$inject = [
  "$element",
  "$mdDialog",
  "ExportService",
  "CloudService",
  "InstitutionService",
  "NotificationService",
  "UtilitiesService",
  "$sessionStorage",
  "_",
  "$scope",
  "DialogService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcAdminSearchClouds", {
  controller: RCAdminSearchClouds,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-admin-search-clouds/rc-admin-search-clouds.html").then(m => m.default),
  bindings: {
    selectable: "=?",
    where: "=?",
    selected: "=?",
    multiple: "=?",
    onSelect: "=?",
    rowActions: "<"
  }
});

/* @ngInject */

function RCAdminSearchClouds(
  $element,
  $mdDialog,
  ExportService,
  CloudService,
  InstitutionService,
  NotificationService,
  UtilitiesService,
  $sessionStorage,
  _,
  $scope,
  DialogService
) {
  var vm = this;

  vm.selected = vm.selected || [];

  vm.close = $mdDialog.cancel;

  vm.institutions = [];

  vm.searchQuery = {};

  vm.resetSearch = function() {
    vm.searchQuery.where = {};
    vm.searchQuery.limit = 50;
    vm.searchQuery.skip = 0;
    vm.searchQuery.page = 1;
  };

  vm.resetSearch();

  //Look at session storage for previous search query fields
  vm.searchQuery = $sessionStorage.searchCloudQuery = _.assign(
    {},
    vm.searchQuery,
    $sessionStorage.searchCloudQuery
  );

  //Watch for changes in the vm.where field, then merge it with search query
  //
  // Note: Don't want to automatically perform the search
  // as we use the 'where' param to make decisions (ie. if Institution is passed in don't LoadInstitutions just
  // load the passed in one)
  //
  $scope.$watch(
    "vm.where",
    function() {
      _.merge(vm.searchQuery.where, vm.where);

      // if(_.filter(vm.where, function(o){return !!o}).length > 0 && vm.isSearchQueryValid(vm.searchQuery.where)) {
      //   vm.performSearch(vm.searchQuery);
      // }
    },
    true
  );

  vm.loadInstitutions = function() {
    if (vm.where && vm.where.institution) {
      return vm.institutions.push(vm.where.institution);
    }

    return InstitutionService.getList().then(function(result) {
      vm.institutions = result.data.map(function(i) {
        return i.name;
      });
    });
  };

  vm.isSearchQueryValid = function(query) {
    for (var i in query) {
      if (query[i]) return true;
    }
    return false;
  };

  vm.performSearch = function() {
    vm.getSearchPromise(vm.searchQuery).then(function(data) {
      vm.searchResults = data;
    });
  };

  function sanitizeQuery(query) {
    if (!query || !query.where) return query;
    var output = _.assign({}, query);

    output.where = _.pickBy(query.where, function(i) {
      return !!i;
    });
    return output;
  }

  vm.getSearchPromise = function() {
    vm.isLoading = true;

    var query = {},
      where = {};
    var fields = {
      id: true,
      name: true,
      description: true,
      institution: true,
      institutions: true,
      resellers: true,
      type: true,
      books: true,
      members: true,
      admins: true,
      created: true,
      updated: true,
      deleted: true
    };

    query = sanitizeQuery(_.assign(query, vm.searchQuery));
    where = _.assign(
      query.where,
      UtilitiesService.regexifyWhereFields(
        _.omit(query.where, ["deleted", "institution", "type"])
      )
    );

    if (vm.includeDeleted) {
      where.deleted = vm.includeDeleted;
    }

    return vm
      .getTotal(where)
      .then(function(result) {
        if (result === 0) {
          return NotificationService.toastError("No results found.");
        } else {
          vm.total = result;

          return (vm.searchPromise = CloudService.get({
            fields: fields,
            where: where,
            limit: vm.searchQuery.limit,
            skip: vm.searchQuery.skip,
            order: vm.searchQuery.order
          }).then(function(result) {
            vm.isLoading = false;

            var clouds = result.data;

            if (!angular.isArray(clouds)) clouds = [clouds];

            clouds = clouds.map(function(i) {
              i.created = i.created
                ? new Date(i.created).toLocaleString()
                : undefined;
              i.updated = i.updated
                ? new Date(i.updated).toLocaleString()
                : undefined;
              return i;
            });

            return clouds;
          }));
        }
      })
      .catch(function() {})
      .finally(function() {});
  };

  vm.getTotal = function(where) {
    return CloudService.count(where).then(function(result) {
      return result;
    });
  };
  vm.onExport = function() {
    var query = {},
      where = {};
    var fields = {
      id: true,
      name: true,
      institution: true,
      description: true,
      type: true,
      members: true,
      admins: true,
      created: true,
      updated: true,
      deleted: true
    };

    query = sanitizeQuery(_.assign(query, vm.searchQuery));
    where = _.assign(
      query.where,
      UtilitiesService.regexifyWhereFields(
        _.omit(query.where, ["deleted", "institution", "type"])
      )
    );

    if (vm.includeDeleted) {
      where.deleted = vm.includeDeleted;
    }

    CloudService.get({
      fields: fields,
      where: where,
      limit: 99999,
      skip: 0,
      order: "institution ASC, type ASC, name ASC"
    }).then(function(result) {
      var clouds = result.data;
      if (!angular.isArray(clouds)) clouds = [clouds];
      clouds = clouds.map(function(i) {
        delete i.id;
        i.created = i.created
          ? new Date(i.created).toLocaleString()
          : undefined;
        i.updated = i.updated
          ? new Date(i.updated).toLocaleString()
          : undefined;
        return i;
      });
      ExportService.exportArrayToXLSX(clouds);
    });
  };

  vm.loadInstitutions();

  vm.showCloudDetails = function(e) {
    DialogService.CloudDetails(e.id);
  };

  var selected = [];

  vm.cloudSelectedChanged = function(document, isChecked) {
    if (isChecked) selected.push(document);
    else {
      var i = selected.indexOf(document);

      if (i > -1) {
        selected.splice(i, 1);
      }
    }

    vm.selectedChanged(selected);
  };

  vm.fields = [
    {
      label: "Name",
      id: "name"
    },
    {
      label: "Description",
      id: "description"
    },
    {
      label: "Type",
      id: "type"
    },
    {
      label: "Institution",
      id: "institution"
    },
    {
      label: "Deleted",
      id: "deleted"
    }
  ];
}
