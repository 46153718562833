RCUserTransactionManager.$inject = [
  "TransactionService",
  "UserService",
  "UtilitiesService",
  "$scope",
  "$element",
  "NotificationService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcUserTransactionManager", {
  controller: RCUserTransactionManager,
  controllerAs: "vm",
  templateUrl: () =>
    import("app/components/rc-user-transaction-manager/template.html").then(m => m.default),
  bindings: {
    userId: "=",
    userEmail: "="
  }
});

/* @ngInject */
function RCUserTransactionManager(
  TransactionService,
  UserService,
  UtilitiesService,
  $scope,
  $element,
  NotificationService
) {
  var vm = this;

  vm.fields = [
    {
      label: "ISBN",
      id: "isbn"
    },
    {
      label: "Title",
      id: "title"
    },
    {
      label: "Transaction Date",
      id: "date",
      parse: parseDate
    },
    {
      label: "Purchase Date",
      id: "purchaseDate",
      parse: parseDate
    },
    {
      label: "Expire Date",
      id: "bookExpireDate",
      parse: parseDate
    }
  ];

  function parseDate(value) {
    //console.log(value)
    if (!value || value == null) {
      return "";
    } else {
      return new Date(value).toLocaleString();
    }
  }

  vm.query = {
    order: "date DESC",
    where: {},
    limit: 50,
    skip: 0,
    page: 1
  };

  vm.get = function() {
    if (!vm.userId) return;
    vm.getTotal(vm.userId).then(function(result) {
      vm.total = result;
    });
    var promise = UserService.getTransactions(vm.userId, vm.query);
    UtilitiesService.inlineLoader(promise, $element);
    promise
      .then(function(result) {
        vm.data = result.data;
      })
      .catch(function(result) {
        NotificationService.toastError(
          "Could not get transactions: " + result.data.error.message
        );
      });
  };

  vm.getTotal = function(id) {
    return UserService.getTransactionsCount(id).then(function(result) {
      return result.data.count;
    });
  };

  $scope.$watch("vm.userId", function() {
    if (vm.userId) vm.get();
  });
}
