/**
 * Created by Daniel on 6/22/2016.
 */
RCAuthenticatedComponent.$inject = ["$scope", "RoleHandler"];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcAuthenticatedComponent", {
  templateUrl: () =>
    import("app/components/rc-authenticated-component/template.html").then(m => m.default),
  controller: RCAuthenticatedComponent,
  controllerAs: "vm",
  transclude: true,
  bindings: {
    roles: "<"
  }
});

/* @ngInject */
function RCAuthenticatedComponent($scope, RoleHandler) {
  var vm = this;
  vm.visible = false;

  $scope.$watch("vm.roles", function() {
    if (vm.roles) {
      if (RoleHandler.isAuthenticatedForPatterns(vm.roles)) {
        vm.visible = true;
      }
    } else {
      vm.visible = true;
    }
  });
}
