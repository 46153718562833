"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.updateViewState = function (currentDateStr, currentDateMs, bookId, req) {
  return index_1.getApiv14().put("/update/app_state", [{
    tags: {
      webComponent: "Reader",
      realm: "ViewState",
      bookId: bookId
    },
    state: req,
    date: currentDateStr,
    version: "3"
  }], {
    params: {
      currentDateMs: currentDateMs
    }
  });
}; // User preferences are pulled down when the application first loads in a get/all,
// there is no reason to create a get request currently.