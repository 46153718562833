/**
 * Created by danie on 4/18/2016.
 */

RCToolbar.$inject = [
  "$state",
  "$scope",
  "$mdSidenav",
  "UIService",
  "RouteService",
  "DialogService"
];
const angular = require("angular");
angular.module("readCloudWebConsole").component("rcToolbar", {
  controller: RCToolbar,
  controllerAs: "vm",
  template: require("app/components/rc-toolbar/rc-toolbar.html"),
  transclude: true
});

/* @ngInject */

function RCToolbar(
  $state,
  $scope,
  $mdSidenav,
  UIService,
  RouteService,
  DialogService
) {
  var vm = this;
  var sidebarName = "rc-sidebar";

  $scope.$state = $state;

  vm.isVisible = UIService.isToolbarVisible;

  vm.hasParentRoute = function() {
    return (
      !!$state.get("^").name &&
      !$state.get("^").abstract &&
      !($state.get("^").data && $state.get("^").data.abstract)
    );
  };

  vm.title = $state.current.data.title;

  vm.toggleRcSidebar = function() {
    $mdSidenav(sidebarName).toggle();
  };

  vm.openProfileMenu = function($mdOpenMenu, e) {
    $mdOpenMenu(e);
  };

  vm.sidebarVisible = UIService.isSidebarVisible;

  vm.getTitle = UIService.getRouteTitle;

  vm.hasPreviousRoute = function hasPreviousRoute() {
    return true;
  };

  vm.back = RouteService.back;
  vm.directUrl = DialogService.ShowDirectTemplateURL;
}
