/**
 * Created by danie on 6/28/2016.
 */
import angular from "angular";
RCApplicationBuildCreatorModalController.$inject = ["$mdDialog"];

angular
  .module("readCloudWebConsole")
  .controller("RCApplicationBuildCreatorModalController", RCApplicationBuildCreatorModalController);

/*@ngInject*/
function RCApplicationBuildCreatorModalController($mdDialog) {
  var vm = this;

  vm.hide = $mdDialog.hide;
}
