import { SidebarContainer } from "./shared";
import { ThemeProvider } from "glamorous";
import { ListTree, ListNode } from "./ListTree";
import { OnlyForRoles } from "new/shared/AuthenticationFilter";
import React from "react";
import { defaultProps } from "recompose";
import { RecentListNode } from "new/Sidebar/shared";
import { RoleSwitcher } from "new/shared/RoleSwitcher";
import { resellerUtilsUrl } from "env";

const AdminOnly = defaultProps({ roles: ["Admin"] })(OnlyForRoles);
const gotoLegacyUtils = () => window.open(resellerUtilsUrl, "_blank");
const gotoLogsPage = () => (window.location.hash = "!/main/admin/logs");

const BoldListNode = defaultProps({ boldLabel: true })(ListNode);

export const SidebarComponent = ({
  RouteService: {
    admin: {
      home,
      institutions,
      users,
      bookSearchAll,
      transactionSearchAll,
      clouds,
      institutionEdit,
      userDetails,
      cloudDetails
    },
    settings: {
      resellerOptions,
      applicationOptions,
      mappedOrdersManager,
      actionsManager
    },
    user: { me }
  },
  DialogService: { OnixGenerator, InstitutionCreator, UserCreator },
  theme,
  collapsed,
  recentInstitutions,
  recentClouds,
  recentUsers
}) => (
  <SidebarContainer collapsed={collapsed}>
    <ThemeProvider theme={theme}>
      <ListTree noPadding>
        <RoleSwitcher
          label="Home"
          icon="dashboard"
          onClick={me}
          Default={BoldListNode}
          Admin={props => <BoldListNode {...props} onClick={home} />}
          ResellerAdmin={props => <BoldListNode {...props} onClick={home} />}
          SchoolAdmin={props => <BoldListNode {...props} onClick={home} />}
          GroupAdmin={props => <BoldListNode {...props} onClick={home} />}
        />

        <OnlyForRoles roles={["Admin", "ResellerAdmin"]}>
          <BoldListNode label="Utilities" icon="build" nodeId="utilities">
            <ListTree isChild>
              <ListNode label="Onix Generator" onClick={OnixGenerator} />
              <ListNode label="Reseller Options" onClick={resellerOptions} />
              <AdminOnly>
                <ListNode
                  label="Application Options"
                  onClick={applicationOptions}
                />
              </AdminOnly>

              <ListNode label="Mapped Orders" onClick={mappedOrdersManager} />
              <ListNode label="Actions Manager" onClick={actionsManager} />
              <ListNode label="Bulk Import (Utils)" onClick={gotoLegacyUtils} />
            </ListTree>
          </BoldListNode>
        </OnlyForRoles>
        <OnlyForRoles
          roles={["Admin", "ResellerAdmin", "SchoolAdmin", "GroupAdmin"]}
        >
          <OnlyForRoles roles={["Admin", "ResellerAdmin"]}>
            <BoldListNode
              label="Institutions"
              icon="school"
              nodeId="institutions"
            >
              <ListTree isChild>
                <ListNode label="Search" icon="search" onClick={institutions} />
                <AdminOnly>
                  <ListNode
                    label="Create"
                    icon="create"
                    onClick={InstitutionCreator}
                  />
                </AdminOnly>

                {recentInstitutions && recentInstitutions.length ? (
                  <ListNode
                    label="Recent"
                    icon="history"
                    nodeId="recentInstitutions"
                  >
                    <ListTree>
                      {recentInstitutions.map(({ label, routeParam }) => (
                        <RecentListNode
                          label={label}
                          key={routeParam}
                          onClick={() => institutionEdit(routeParam, label)}
                        />
                      ))}
                    </ListTree>
                  </ListNode>
                ) : null}
              </ListTree>
            </BoldListNode>
          </OnlyForRoles>
          <BoldListNode label="Users" icon="people" nodeId="users">
            <ListTree isChild>
              <ListNode label="Search" icon="search" onClick={users} />
              <OnlyForRoles roles={["Admin", "ResellerAdmin"]}>
                <ListNode label="Add" icon="addition" onClick={UserCreator} />
              </OnlyForRoles>
              {recentUsers && recentUsers.length ? (
                <ListNode label="Recent" icon="history" nodeId="recentUsers">
                  <ListTree>
                    {recentUsers.map(({ label, routeParam }) => (
                      <RecentListNode
                        label={label}
                        key={routeParam}
                        onClick={() => userDetails(routeParam)}
                      />
                    ))}
                  </ListTree>
                </ListNode>
              ) : null}
            </ListTree>
          </BoldListNode>
          <BoldListNode label="Clouds" icon="cloud" nodeId="clouds">
            <ListTree isChild>
              <ListNode label="Search" icon="search" onClick={clouds} />
              {recentClouds && recentClouds.length ? (
                <ListNode label="Recent" icon="history" nodeId="recentClouds">
                  <ListTree>
                    {recentClouds.map(({ label, routeParam }) => (
                      <RecentListNode
                        label={label}
                        key={routeParam}
                        onClick={() => cloudDetails(routeParam)}
                      />
                    ))}
                  </ListTree>
                </ListNode>
              ) : null}
            </ListTree>
          </BoldListNode>
          <OnlyForRoles roles={["Admin", "ResellerAdmin"]}>
            <BoldListNode label="Books" icon="book" nodeId="books">
              <ListTree isChild>
                <ListNode
                  label="Search"
                  icon="search"
                  onClick={bookSearchAll}
                />
              </ListTree>
            </BoldListNode>
          </OnlyForRoles>

          <OnlyForRoles roles={["Admin", "ResellerAdmin"]}>
            <BoldListNode label="Transactions" icon="description" nodeId="transactions">
              <ListTree isChild>
                <ListNode
                  label="Search"
                  icon="search"
                  onClick={transactionSearchAll}
                />
              </ListTree>
            </BoldListNode>
          </OnlyForRoles>

          <OnlyForRoles roles={["Admin"]}>
            <BoldListNode label="Logs" onClick={gotoLogsPage} icon="list" />
          </OnlyForRoles>
        </OnlyForRoles>
      </ListTree>
    </ThemeProvider>
  </SidebarContainer>
);
