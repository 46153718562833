import React from "react";
import {
  withStateHandlers,
  compose,
  defaultProps,
  setPropTypes,
  withPropsOnChange,
  branch,
  renderComponent,
  renderNothing,
  withState,
  withProps
} from "recompose";
import { Div, H2 } from "glamorous";
import FontIcon from "material-ui/FontIcon";
import CircularProgress from "material-ui/CircularProgress";
import PropTypes from "prop-types";
import glamorous from "glamorous";
import { defaultStyle, omitProps } from "new/shared/hocs";
import { Field } from "redux-form";
import ReactJson from "react-json-view";
import { Divider as MUIDivider } from "material-ui";
import stringify from "json-stringify-safe";
import uuidv4 from "uuid/v4";
import { withContentRect } from "react-measure";

export const Divider = glamorous(MUIDivider)({
  flexShrink: 0
});

const LoadingOverlayContainer = defaultProps({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255,255,255,0.2)"
})(Div);

export const LoadingOverlay = ({ progressProps, ...props }) => (
  <LoadingOverlayContainer {...props}>
    <CircularProgress {...progressProps} />
  </LoadingOverlayContainer>
);

LoadingOverlay.propTypes = {
  progressProps: PropTypes.object
};

//Adds open, onRequestOpen, and onRequestClose to a component
export const withDialogState = withStateHandlers(
  { open: false },
  {
    onRequestOpen: ({ open }) => () => (!open ? { open: true } : undefined),
    onRequestClose: () => () => ({ open: false })
  }
);

export const WithOverlay = ({
  renderOverlay,
  children,
  overlayContainerProps,
  overlayContainerStyle,
  overlay,
  hidden,
  Component = Div,
  ...props
}) => (
  <Component {...props} style={{ position: "relative", ...props.style }}>
    {children}
    {!hidden ? (
      <Div
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        {...overlayContainerProps}
        style={overlayContainerStyle}
      >
        {overlay}
      </Div>
    ) : null}
  </Component>
);

export const WithLoader = compose(
  setPropTypes({
    loading: PropTypes.bool,
    loadingOverlayProps: PropTypes.object
  }),
  withPropsOnChange(["loading"], ({ loading }) => ({
    hidden: !loading
  })),
  withPropsOnChange(["loadingOverlayProps"], ({ loadingOverlayProps }) => ({
    overlay: <LoadingOverlay {...loadingOverlayProps} />
  })),
  omitProps(["loading"])
)(WithOverlay);

const withAutocompleteFix = compose(
  /*   renameProp("readOnly", "originalReadOnly"),
  withState("readOnly", "setReadOnly", true),
  lifecycle({
    componentDidMount: function() {
      const { setReadOnly, originalReadOnly } = this.props;
      setTimeout(
        () =>
          setReadOnly(
            originalReadOnly === null || originalReadOnly === undefined
              ? false
              : originalReadOnly
          ),
        200
      );
    }
  }),
  omitProps(["originalReadOnly", "setReadOnly"]) */
  defaultProps({
    autoComplete: uuidv4()
  })
);

export const FormField = compose(
  withAutocompleteFix,
  defaultStyle({ width: "auto", flexShrink: 0 })
)(Field);

//This really needs to be cleaned up
//Refactor to a glamorous Div component with default and mapped props
export const Flex = glamorous.div(
  ({
    column,
    centerBoth,
    flex,
    alignItems,
    justifyContent,
    flexBasis,
    flexShrink,
    flexWrap,
    maxWidth,
    maxHeight,
    height,
    width,
    padding,
    paddingRight,
    paddingLeft,
    paddingTop,
    paddingBottom,
    margin,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    boxSizing,
    boxShadow,
    overflow,
    overflowX,
    overflowY,
    color,
    fontSize,
    fontWeight,
    textTransform,
    opacity,
    backgroundColor,
    border,
    borderBottom,
    borderRight,
    borderLeft,
    borderTop,
    textAlign,
    borderRadius
  }) => ({
    display: "flex",
    flexDirection: column ? "column" : "row",
    alignItems,
    justifyContent,
    ...(centerBoth ? { alignItems: "center", justifyContent: "center" } : {}),
    flex: flex === true ? "1 0 auto" : flex,
    boxSizing: boxSizing || "border-box",
    flexShrink,
    flexBasis,
    flexWrap,
    maxWidth,
    maxHeight,
    height,
    width,
    padding,
    paddingRight,
    paddingLeft,
    paddingTop,
    paddingBottom,
    margin,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    overflow,
    overflowX,
    overflowY,
    color,
    fontSize,
    fontWeight,
    textTransform,
    opacity,
    boxShadow,
    backgroundColor,
    border,
    borderBottom,
    borderRight,
    borderLeft,
    borderTop,
    textAlign,
    borderRadius
  })
);
const paddingConst = 16;

export const Padding = ({ size = 1 }) => (
  <Div flexBasis={size * paddingConst} flexShrink={0} />
);

const APIErrorContainer = glamorous.div({
  display: "flex",
  flexDirection: "column"
});

export const APIError = ({ title, description, style }) => {
  return (
    <APIErrorContainer style={style}>
      <h2>{title || "Unknown error occurred"}</h2>
      <p>
        {description ||
          "Please try again later or contact techsupport@readcloud.com"}
      </p>
    </APIErrorContainer>
  );
};

export const safeObject = compose(
  obj => JSON.parse(obj),
  obj => stringify(obj),
  obj => (typeof obj === "object" ? obj : { data: obj })
);
const ErrorOverlay = ({ error }) => (
  <Flex column flex height="100%" width="100%" maxWidth={500}>
    <H2 color="#bb4a4a">
      {(error && error.title) || "An unexpected error occurred"}
    </H2>
    <Div maxHeight={400} overflow="overlay">
      {typeof error === "string" ? (
        error
      ) : (
        <ReactJson
          displayDataTypes={false}
          style={{ maxHeight: 600, overflowY: "overlay" }}
          src={safeObject(error)}
        />
      )}
    </Div>
    <Padding />
    <Divider />
    <Padding />
    <small>
      ReadCloud has been notified of this and will rectify the issue as soon as
      possible. Please refresh the page or try again later, or contact
      tech-support@readcloud.com if the matter is urgent.
    </small>
  </Flex>
);

export const withErrorOverlay = branch(
  props => props.error,
  renderComponent(ErrorOverlay)
);

export const APICallStateContainer = compose(
  setPropTypes({
    loading: PropTypes.bool,
    error: PropTypes.any
  }),
  withProps({
    Component: Flex,
    overlayContainerStyle: { zIndex: 9 }
  }),
  defaultStyle({
    minHeight: 100
  }),
  withErrorOverlay
)(WithLoader);

export const onlyRenderWhen = predicate =>
  branch(props => !predicate(props), renderNothing);

const ExpandableComponent = glamorous.div(
  ({ expanded, expandable = true, expandedHeight, height }) => ({
    transition: "all 300ms",
    overflowY: "hidden",
    ...(expanded
      ? { maxHeight: expandedHeight, height: expandedHeight }
      : { maxHeight: height }),
    ...(!expandable ? { maxHeight: "auto", height: "auto" } : {})
  })
);

export const Expandable = compose(
  setPropTypes({
    children: PropTypes.func.isRequired
  }),
  defaultProps({
    height: 50,
    expanded: false
  }),
  withContentRect("bounds")
)(
  ({
    measureRef,
    children,
    contentRect,
    measure,
    height,
    expanded,
    expandedHeight,
    ...other
  }) => (
    <ExpandableComponent
      expandedHeight={
        !expandedHeight
          ? (contentRect && contentRect.bounds && contentRect.bounds.height) ||
            50
          : expandedHeight
      }
      height={height}
      expanded={expanded}
      {...other}
    >
      {children ? children({ measureRef, expanded }) : null}
    </ExpandableComponent>
  )
);

export const ExpandIcon = compose(
  defaultProps({ children: "keyboard_arrow_down" })
)(
  glamorous(FontIcon, { filterProps: ["expanded"] })(
    "material-icons",
    ({ expanded }) => ({
      ...(expanded ? { transform: "rotate(180deg)" } : {})
    })
  )
);

export const withHoverState = compose(
  withState("hovered", "setHovered", false),
  withPropsOnChange(
    ["onMouseEnter", "onMouseLeave", "setHovered"],
    ({ onMouseEnter, onMouseLeave, setHovered }) => ({
      onMouseEnter: _ => {
        onMouseEnter && onMouseEnter(_);
        setHovered(true);
      },
      onMouseLeave: _ => {
        onMouseLeave && onMouseLeave(_);
        setHovered(false);
      }
    })
  ),
  omitProps(["setHovered"])
);

export const glamorousHoc = (...styleDefs) => Component =>
  glamorous(Component)(...styleDefs);

export const VerticalDivider = glamorous.div({
  width: 1,
  minWidth: 1,
  flexShrink: 0,
  height: "100%",
  borderRight: "solid 1px",
  borderColor: "#ccc"
});

export const FlexForm = Flex.withComponent("form");

export const PageHeader = ({
  noDivider,
  hProps,
  children,
  headingComponent = "h2",
  dividerProps,
  ...other
}) => (
  <Flex column justifyContent="center" flexShrink={0} {...other}>
    <Padding />
    {React.createElement(headingComponent, { ...hProps, children })}
    <Padding />
    <Divider {...dividerProps} />
  </Flex>
);
