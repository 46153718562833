"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.addBookmark = function (req) {
  return index_1.getApiv14().post("/add/bookmark", req);
};

exports.listBookmarks = function () {
  return index_1.getApiv14().get("/list/bookmark.json");
};

exports.deleteBookmark = function (id) {
  return index_1.getApiv14().delete("/delete/bookmark", {
    params: {
      id: id
    }
  });
};

exports.deltaBookmarks = function (millisec) {
  return index_1.getApiv14().get("/delta/bookmarks", {
    params: millisec
  });
};