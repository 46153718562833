"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.audit = function (req) {
  return index_1.getApiv14().post("/audit", req);
};