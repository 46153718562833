"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var index_1 = require("./index");

exports.addTag = function (tag) {
  return index_1.getNewapi().post("/add/tag", {
    params: {
      tag: tag
    }
  });
};

exports.putTag = function (tag) {
  return index_1.getNewapi().put("/update/tag", {
    data: tag,
    params: {
      tag: tag
    }
  });
};