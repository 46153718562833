//We shouldnt have to do this but there are problems with XLSX module.
import moduleName from "./moduleName";

import angular from "angular";
import queryString from "query-string";
import { setNewapiUrl, getNewapi } from "@readcloud/api-client/build/v2";
import { assertAuthentication } from "@readcloud/api-client/build/v2/auth";
import {
  setAccessToken,
  setRefreshToken,
  setClientId,
  getAccessToken
} from "@readcloud/api-client";
setPageTitle.$inject = ["$document", "CONFIG"];
bootstrapRouteEvents.$inject = [
  "$log",
  "$rootScope",
  "RouteHandler",
  "AuthenticationService",
  "AUTH_EVENTS"
];
anchorScrollSettings.$inject = ["$anchorScroll"];
renewJWT.$inject = ["AuthenticationService"];

angular
  .module(moduleName)
  .run(configureApiClient)
  .run(bootstrapRouteEvents)
  .run(anchorScrollSettings)
  .run(setPageTitle)
  .run(renewJWT);

async function configureApiClient(
  CONFIG,
  AuthenticationService,
  $localStorage,
  $rootScope,
  $stateParams,
  AUTH_EVENTS,
  NotificationService
) {
  const { accessToken } = queryString.parse(window.location.hash);

  if (accessToken) {
    try {
      await AuthenticationService.accessTokenOverride(accessToken);
    } catch (e) {
      console.log("HOY");
    }
  }

  const refreshToken = AuthenticationService.getRefreshToken();
  setNewapiUrl("/");
  setClientId("readCloudWebConsole");
  setAccessToken(AuthenticationService.getAccessToken());
  setRefreshToken(refreshToken);
  console.log("Refresh Token", refreshToken);
  /*   if (refreshToken)
    assertAuthentication().catch(() => {
      AuthenticationService.invalidRefreshToken();
    }); */

  getNewapi().interceptors.request.use(async config => {
    await (config.url !== "/token" && AuthenticationService.getRefreshToken()
      ? assertAuthentication().catch(() =>
          AuthenticationService.invalidRefreshToken()
        )
      : Promise.resolve());
    return config;
  });

  getNewapi().interceptors.response.use(response => {
    function updateJWT(token) {
      $rootScope.$emit(AUTH_EVENTS.receivedJWT, token);
    }

    function receivedRefreshToken(token) {
      $rootScope.$emit(AUTH_EVENTS.receivedRefreshToken, token);
    }

    if (response.data && response.data.access_token) {
      updateJWT(response.data.access_token);
    }

    if (response.data && response.data.refresh_token) {
      receivedRefreshToken(response.data.refresh_token);
    }

    return response;
  });
  if (getAccessToken()) {
    AuthenticationService.updateCurrentUserData().catch(() => {
      AuthenticationService.invalidAccessToken();
    });
  }
}
configureApiClient.$inject = [
  "CONFIG",
  "AuthenticationService",
  "$localStorage",
  "$rootScope",
  "$stateParams",
  "AUTH_EVENTS",
  "NotificationService"
];

/* @ngInject*/
function setPageTitle($document, CONFIG) {
  $document[0].title = CONFIG.title;
}

/* @ngInject */
function bootstrapRouteEvents(
  $log,
  $rootScope,
  RouteHandler,
  AuthenticationService,
  AUTH_EVENTS
) {
  //Bind the RouteHandler to ui-router events
  bindEventsToRouteHandler(RouteHandler, $rootScope);

  //Bind all fields in AUTH_EVENTS to functions in AuthenticationService
  bindEventsToObject(AuthenticationService, $rootScope, AUTH_EVENTS);
}

function bindEventsToRouteHandler(RouteHandler, $rootScope) {
  bindEventsToObject(RouteHandler, $rootScope, {
    $stateChangeStart: "stateChangeStart",
    $stateChangeSuccess: "stateChangeSuccess",
    $stateNotFound: "stateNotFound"
  });
}

function bindEventsToObject(target, $rootScope, eventBindings) {
  var temp = {};

  for (var i in eventBindings) {
    temp[i] = $rootScope.$on(i, target[eventBindings[i]]);
  }
}

/* @ngInject */
function anchorScrollSettings($anchorScroll) {
  $anchorScroll.yOffset = 200;
}

/* @ngInject */
function renewJWT(AuthenticationService) {
  //AuthenticationService.renewJWT();
}
