import { lifecycle } from "recompose";
import Ajv from "ajv";
import ajvErrors from "ajv-errors";
import validateRFAjv from "redux-form-with-ajv";
import { path } from "ramda";
import { SubmissionError } from "redux-form";
import FileSaver from "file-saver";
import json2csv from "json2csv";
export const ajv = new Ajv({
  allErrors: true,
  verbose: true,
  jsonPointers: true
});
ajvErrors(ajv);

/* 
  Validate a schema (using a custom ajv provider so that we can enter custom data within schemas) and return an error object that redux-form expects
*/
export const makeSchemaValidator = schema => validateRFAjv(schema, { ajv });

export const newError = (title, description) => ({ title, description });
export const dispatchResizeEvent = () =>
  window.dispatchEvent(new Event("resize"));
export const withResizeOnMount = lifecycle({
  componentDidMount: dispatchResizeEvent
});

export const serverErrorToSubmissionError = error => {
  console.dir(error);
  let outputError;
  try {
    const { response, message } = error;
    const messages = path(
      ["response", "data", "error", "details", "messages"],
      error
    );
    const responseMessage = path(
      ["response", "data", "error", "message"],
      error
    );
    if (messages && response && response.status === 422) outputError = messages;

    outputError = { ...outputError, _error: responseMessage || message };
  } catch (e) {
    outputError = { _error: e.message };
  }
  throw new SubmissionError(outputError);
};

/**
 * If shouldConfirm() is true, and window.confirm() is true, call the callback. If shouldConfirm() is false, call the callback
 * @param function callback 
 * @param string message 
 * @param function shouldConfirm 
 */
export const withUserConfirm = (
  callback,
  message = "Your progress will be lost if you cancel. Are you sure?",
  shouldConfirm = () => true
) => () =>
  shouldConfirm() ? (window.confirm(message) ? callback() : null) : callback();

export const isNullOrUndefined = a => a === undefined || a === null;

export const saveJsonAsCsv = (data, fileName, options) => {
  const blob = new Blob([json2csv({ ...options, data })], {
    type: "text/plain;charset=utf-8"
  });
  FileSaver.saveAs(blob, fileName.replace(/\.csv$/, "") + ".csv");
};

export const openInLocalReader = bookId =>
  window.open(`readcloud://rc/?bid=${bookId}`);

export const openInWebReader = ({
  fileName,
  fileExt,
  encMethod,
  encKeyAndIv,
  title,
  isbn
}) =>
  window.open(
    `https://readcloud-reader.surge.sh/?fileUrl=https://rcs.readcloud.com/rcs/bookbytes/${fileName}.${fileExt}${encMethod
      ? `&minor=${encMethod}`
      : ""}${isbn ? `&isbn=${isbn}` : ""}${encKeyAndIv
      ? `&longId=${encKeyAndIv}`
      : ""}${title ? `&title=${title}` : ""}`
  );

export const getLatestBookbytesResource = resources =>
  resources
    .filter(r => r.type === "Bookbytes")
    .sort((a, b) => new Date(b.date) - new Date(a.date))[0];

export const openLatestBookbytesInWebReader = book => {
  if (book && book.rcs && book.rcs.resources) {
    const latest = getLatestBookbytesResource(
      book && book.rcs && book.rcs.resources
    );

    if (latest) {
      openInWebReader({ ...latest, isbn: book.isbn, title: book.title });
    }
  }
};
